import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function RefreshReverseIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11.9986 3C7.46095 3.00069 3.63363 6.37951 3.07028 10.882C2.50693 15.3846 5.3838 19.6022 9.78138 20.7209C14.179 21.8395 18.7215 19.5092 20.3781 15.2848C22.0347 11.0604 20.2871 6.2634 16.3014 4.09454M16.3014 7.55365V3.57596H20.2791"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
