import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MedicalEmergencySymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8102 12L20.3872 14.018C20.8752 14.293 21.0422 14.915 20.7572 15.398L19.5942 17.367C19.3172 17.836 18.7162 17.997 18.2422 17.729L14.6302 15.691V20C14.6302 20.552 14.1822 21 13.6302 21H11.2682C10.7162 21 10.2682 20.552 10.2682 20V15.691L6.65619 17.729C6.18219 17.997 5.58119 17.836 5.30419 17.367L4.14119 15.398C3.85619 14.916 4.02319 14.294 4.51119 14.018L8.08619 12L4.50919 9.982C4.02119 9.707 3.85419 9.085 4.13919 8.602L5.30219 6.633C5.57919 6.164 6.18019 6.003 6.65419 6.271L10.2662 8.309V4C10.2662 3.448 10.7142 3 11.2662 3H13.6282C14.1802 3 14.6282 3.448 14.6282 4V8.309L18.2402 6.271C18.7142 6.003 19.3152 6.164 19.5922 6.633L20.7552 8.602C21.0402 9.084 20.8732 9.706 20.3852 9.982L16.8102 12Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
