import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PharmacySymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M4.00955 8.47683H7.61619C7.88394 8.47683 8.14073 8.37032 8.33006 8.18073C8.51938 7.99114 8.62575 7.734 8.62575 7.46588V4.01095C8.62575 3.74283 8.73211 3.48569 8.92144 3.2961C9.11077 3.10651 9.36755 3 9.6353 3H14.3348C14.6025 3 14.8593 3.10651 15.0486 3.2961C15.238 3.48569 15.3443 3.74283 15.3443 4.01095V7.46588C15.3443 7.734 15.4507 7.99114 15.64 8.18073C15.8294 8.37032 16.0861 8.47683 16.3539 8.47683H19.8873C20.1551 8.47683 20.4119 8.58334 20.6012 8.77293C20.7905 8.96252 20.8969 9.21966 20.8969 9.48778V14.4288C20.8969 14.6969 20.7905 14.9541 20.6012 15.1437C20.4119 15.3333 20.1551 15.4398 19.8873 15.4398H16.3539C16.0861 15.4398 15.8294 15.5463 15.64 15.7359C15.4507 15.9255 15.3443 16.1826 15.3443 16.4507V19.989C15.3443 20.2572 15.238 20.5143 15.0486 20.7039C14.8593 20.8935 14.6025 21 14.3348 21H9.63404C9.36629 21 9.1095 20.8935 8.92017 20.7039C8.73085 20.5143 8.62448 20.2572 8.62448 19.989V16.4507C8.62448 16.1826 8.51812 15.9255 8.32879 15.7359C8.13946 15.5463 7.88268 15.4398 7.61493 15.4398H4.00955C3.7418 15.4398 3.48502 15.3333 3.29569 15.1437C3.10636 14.9541 3 14.6969 3 14.4288V9.48778C3 9.21966 3.10636 8.96252 3.29569 8.77293C3.48502 8.58334 3.7418 8.47683 4.00955 8.47683Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
