import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HandDrawnCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M24 13.6744C23.3023 19.3953 18.9836 24 12.2791 24C6.27907 24 0 20.3789 0 13.6744C0 1.25581 6.8304 0 13.5349 0C20.2394 0 24 6.96994 24 13.6744Z"
          fill="currentColor"
          stroke="none"
        />
      </g>
    </SvgIcon>
  );
}
