import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TelephoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.25575 12.2443C7.98334 10.9719 7.02414 9.54721 6.38685 8.10188C6.252 7.79629 6.33139 7.43849 6.56738 7.2025L7.45807 6.3129C8.1878 5.58317 8.1878 4.5511 7.55051 3.91381L6.27375 2.63705C5.42439 1.78769 4.04758 1.78769 3.19822 2.63705L2.48915 3.34612C1.68329 4.15198 1.34724 5.31455 1.56475 6.46733C2.10199 9.30904 3.75286 12.4205 6.41621 15.0838C9.07957 17.7472 12.191 19.398 15.0327 19.9353C16.1855 20.1528 17.3481 19.8167 18.1539 19.0109L18.8619 18.3029C19.7113 17.4535 19.7113 16.0767 18.8619 15.2274L17.5862 13.9517C16.9489 13.3144 15.9158 13.3144 15.2796 13.9517L14.2975 14.9348C14.0615 15.1708 13.7037 15.2502 13.3981 15.1154C11.9528 14.477 10.5282 13.5167 9.25575 12.2443Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
