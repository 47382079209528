import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function VideoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.375 12.847L19.17 15.901C19.906 16.494 21 15.97 21 15.025V8.475C21 7.53 19.906 7.006 19.17 7.599L15.375 10.653M13.125 17.5H5.25C4.007 17.5 3 16.493 3 15.25V8.25C3 7.007 4.007 6 5.25 6H13.125C14.368 6 15.375 7.007 15.375 8.25V15.25C15.375 16.493 14.368 17.5 13.125 17.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
