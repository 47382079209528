import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BreathingRateIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          id="Vector"
          d="M13.94 3.99994V8.13994C13.94 9.33244 14.9075 10.2999 16.1 10.2999M11.06 3.99994V8.13994C11.06 9.33244 10.0925 10.2999 8.9 10.2999M16.1 12.9999C14.6654 12.9999 13.3892 12.3672 12.5 11.3781C11.6108 12.3672 10.3346 12.9999 8.9 12.9999M13.85 15.6963V16.9275C13.85 17.9499 14.4278 18.8841 15.3422 19.3413L16.2899 19.8147C18.6839 21.0117 21.5 19.272 21.5 16.5963V11.0658C21.5 10.2486 21.2219 9.45574 20.7107 8.81764L19.1078 6.81514C18.3086 5.81524 17.1035 5.60464 16.1 5.96284M11.15 15.6963V16.9275C11.15 17.9499 10.5722 18.8841 9.6578 19.3413L8.7101 19.8147C6.3161 21.0117 3.5 19.272 3.5 16.5963V11.0658C3.5 10.2486 3.7781 9.45574 4.2893 8.81764L5.8922 6.81514C6.6914 5.81524 7.8965 5.60464 8.9 5.96284"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
