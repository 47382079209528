import { SvgIcon, SvgIconProps } from "@mui/material";

export default function HealthScoreMeasurementColorsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1993_268789)">
        <circle cx="10.5" cy="17.5" r="7.5" fill="#E84F3E" />
        <circle
          cx="10.5"
          cy="17.5"
          r="8.54665"
          stroke="white"
          strokeWidth="2.09331"
        />
      </g>
      <g filter="url(#filter1_d_1993_268789)">
        <circle cx="17.5" cy="17.5" r="7.5" fill="#FFBE40" />
        <circle
          cx="17.5"
          cy="17.5"
          r="8.54665"
          stroke="white"
          strokeWidth="2.09331"
        />
      </g>
      <g filter="url(#filter2_d_1993_268789)">
        <circle cx="25.5" cy="17.5" r="7.5" fill="#00948D" />
        <circle
          cx="25.5"
          cy="17.5"
          r="8.54665"
          stroke="white"
          strokeWidth="2.09331"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1993_268789"
          x="0.208968"
          y="7.90668"
          width="20.5821"
          height="20.5822"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.69777" />
          <feGaussianBlur stdDeviation="0.348885" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1993_268789"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1993_268789"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1993_268789"
          x="7.20897"
          y="7.90668"
          width="20.5821"
          height="20.5822"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.69777" />
          <feGaussianBlur stdDeviation="0.348885" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1993_268789"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1993_268789"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1993_268789"
          x="15.209"
          y="7.90668"
          width="20.5821"
          height="20.5822"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.69777" />
          <feGaussianBlur stdDeviation="0.348885" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1993_268789"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1993_268789"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}
