import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ActiveNotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path
        d="M9.50031 8.3335V5.41683C9.50031 3.626 8.54364 2.12683 6.87531 1.73016V1.3335C6.87531 0.84933 6.47865 0.458496 5.99448 0.458496C5.51031 0.458496 5.12531 0.84933 5.12531 1.3335V1.73016C3.45115 2.12683 2.50031 3.62016 2.50031 5.41683V8.3335L1.74198 9.086C1.37448 9.4535 1.63115 10.0835 2.15031 10.0835H9.83281C10.352 10.0835 10.6145 9.4535 10.247 9.086L9.50031 8.3335ZM5.99448 11.8335C6.63615 11.8335 7.16115 11.3085 7.16115 10.6668H4.82781C4.82781 11.3085 5.34698 11.8335 5.99448 11.8335ZM2.94948 1.75933C3.19448 1.53766 3.20031 1.1585 2.96698 0.925163C2.74531 0.703496 2.38365 0.697663 2.15615 0.913496C1.15865 1.8235 0.470312 3.06016 0.248646 4.4485C0.196146 4.80433 0.470312 5.12516 0.831979 5.12516C1.11198 5.12516 1.35698 4.921 1.40365 4.641C1.57865 3.50933 2.13865 2.50016 2.94948 1.75933ZM9.85031 0.913496C9.61698 0.697663 9.25531 0.703496 9.03365 0.925163C8.80031 1.1585 8.81198 1.53183 9.05114 1.7535C9.85614 2.49433 10.422 3.5035 10.597 4.63516C10.6378 4.91516 10.8828 5.11933 11.1686 5.11933C11.5245 5.11933 11.8045 4.7985 11.7461 4.44266C11.5245 3.06016 10.842 1.82933 9.85031 0.913496Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
