import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function StethoscopeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M6.6 3.9H4.8C3.8055 3.9 3 4.7055 3 5.7V9.3C3 12.2826 5.4174 14.7 8.4 14.7M8.4 14.7C11.3826 14.7 13.8 12.2826 13.8 9.3V5.7C13.8 4.7055 12.9945 3.9 12 3.9H10.2M8.4 14.7V15.825C8.4 18.6834 10.7166 21 13.575 21C16.4334 21 18.75 18.6834 18.75 15.825V15.6M6.6 3V4.8M10.2 3V4.8M20.341 11.759C21.2197 12.6377 21.2197 14.0623 20.341 14.941C19.4623 15.8196 18.0377 15.8196 17.159 14.941C16.2804 14.0623 16.2804 12.6377 17.159 11.759C18.0377 10.8803 19.4623 10.8803 20.341 11.759Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
