import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function RisingSunIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 67 43">
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M50.0542 42.4587C49.6783 36.2221 46.2094 30.1324 40.7271 27.1578C35.2448 24.1831 27.8872 24.7473 23.2757 28.9532C21.8477 30.2561 20.7037 31.8454 19.7403 33.5221C18.6036 35.4998 17.6926 37.6669 17.5605 39.9454C17.5397 40.3079 17.5469 40.6999 17.7609 40.9931C18.0237 41.3557 18.5104 41.4531 18.951 41.5225C29.3315 43.1539 39.5872 43.3845 50.0542 42.4587Z"
          fill="currentColor"
        />
        <path
          d="M34.8519 22.3588C34.9636 22.3666 35.0898 22.3693 35.1717 22.2904C35.2536 22.2116 35.259 22.0806 35.2581 21.9646C35.24 18.6732 35.7312 19.2909 35.7131 16.0013C35.7063 14.7519 35.5774 12.7153 35.4417 11.4741C35.2441 9.69944 35.1441 7.99007 34.3982 8.00004C33.6785 8.01132 32.9631 8.11286 32.2686 8.30231C32.1516 8.33608 32.0515 8.41246 31.9879 8.51645C31.9243 8.62043 31.9018 8.74449 31.9248 8.86425C33.024 14.9821 31.8629 14.9925 32.4636 22.0249C32.5305 22.7962 34.1015 22.3072 34.8519 22.3588Z"
          fill="currentColor"
        />
        <path
          d="M27.1037 23.9269C27.214 23.907 27.3371 23.8784 27.3972 23.7819C27.4574 23.6854 27.4303 23.5553 27.4013 23.4447C26.5781 20.2585 27.2059 20.7384 26.3827 17.5535C26.0701 16.344 25.4472 14.4008 25.0094 13.2316C24.3834 11.5594 23.8681 9.92703 23.1476 10.1201C22.4528 10.3077 21.7842 10.5817 21.1573 10.9358C21.0528 10.9978 20.9751 11.0966 20.9394 11.2129C20.9038 11.3293 20.9128 11.4547 20.9646 11.5648C23.5284 17.2295 22.4049 17.5213 24.7086 24.1911C24.9592 24.9212 26.3637 24.0611 27.1037 23.9269Z"
          fill="currentColor"
        />
        <path
          d="M21.184 27.3476C21.2655 27.2697 21.3523 27.1786 21.3496 27.0648C21.3469 26.9511 21.2537 26.8586 21.1687 26.7811C18.7306 24.5751 19.5185 24.6295 17.0805 22.4234C16.155 21.586 14.5673 20.3071 13.5591 19.5721C12.118 18.5216 10.7908 17.4421 10.2955 18.0014C9.81854 18.5415 9.41099 19.1393 9.08236 19.781C9.02927 19.8903 9.01876 20.0155 9.05289 20.1321C9.08702 20.2488 9.16332 20.3485 9.26691 20.4118C14.5176 23.7254 13.7414 24.5891 19.329 28.8889C19.941 29.3597 20.6403 27.8679 21.184 27.3476Z"
          fill="currentColor"
        />
        <path
          d="M16.8758 32.4186C16.9211 32.3162 16.9686 32.1988 16.9211 32.0946C16.8736 31.9904 16.7551 31.9396 16.6465 31.8998C13.5611 30.7741 14.3116 30.5262 11.2244 29.4019C10.0529 28.9741 8.10331 28.3872 6.89197 28.0877C5.1618 27.6571 3.52753 27.1577 3.27784 27.8611C3.03859 28.5414 2.88526 29.249 2.82144 29.9674C2.8132 30.0886 2.85035 30.2085 2.92563 30.3037C3.00091 30.3989 3.10891 30.4625 3.22854 30.4822C9.33863 31.5748 8.94374 32.6688 15.7346 34.5467C16.4791 34.7516 16.5673 33.1056 16.8758 32.4186Z"
          fill="currentColor"
        />
        <path
          d="M15.0915 38.8047C15.0992 38.6928 15.1023 38.5664 15.0236 38.4843C14.9449 38.4023 14.8142 38.3969 14.6984 38.3978C11.4131 38.4159 12.0297 37.9238 8.74618 37.9419C7.4991 37.9487 5.46632 38.0778 4.22738 38.2138C2.4556 38.4118 0.749858 38.512 0.759809 39.2593C0.771061 39.9803 0.872418 40.697 1.06151 41.3928C1.09587 41.5095 1.17244 41.6092 1.27627 41.6723C1.38009 41.7354 1.50368 41.7574 1.62286 41.7341C7.72934 40.6328 7.73974 41.7961 14.759 41.1948C15.5284 41.13 15.0404 39.5566 15.0915 38.8047Z"
          fill="currentColor"
        />
        <path
          d="M50.1245 32.4186C50.0793 32.3162 50.034 32.1988 50.0793 32.0946C50.1245 31.9904 50.2453 31.9396 50.3539 31.8998C53.441 30.7741 52.6906 30.5275 55.7778 29.4019C56.9498 28.9741 58.8988 28.3872 60.1106 28.0877C61.8404 27.6571 63.4746 27.1577 63.7243 27.8611C63.9637 28.5414 64.1172 29.249 64.1812 29.9674C64.1894 30.0886 64.1523 30.2085 64.077 30.3037C64.0017 30.3989 63.8937 30.4625 63.7741 30.4822C57.664 31.5748 58.0589 32.6688 51.268 34.5467C50.5212 34.7516 50.4335 33.1056 50.1245 32.4186Z"
          fill="currentColor"
        />
        <path
          d="M51.9086 38.8047C51.9009 38.6928 51.8977 38.5664 51.9769 38.4843C52.0561 38.4023 52.1859 38.3969 52.3017 38.3978C55.587 38.4159 54.9704 37.9238 58.2539 37.9419C59.501 37.9487 61.5342 38.0778 62.7732 38.2138C64.5445 38.4118 66.2502 38.512 66.2403 39.2593C66.229 39.9803 66.1277 40.697 65.9386 41.3928C65.9043 41.5095 65.8278 41.6091 65.7241 41.6722C65.6203 41.7354 65.4968 41.7574 65.3777 41.7341C59.2712 40.6328 59.2603 41.7961 52.2411 41.1948C51.4721 41.13 51.9597 39.5566 51.9086 38.8047Z"
          fill="currentColor"
        />
        <path
          d="M45.0465 27.3476C44.9651 27.2697 44.8782 27.1786 44.8809 27.0648C44.8836 26.9511 44.9768 26.8586 45.0619 26.7811C47.4999 24.5751 46.712 24.6295 49.15 22.4234C50.0755 21.586 51.6632 20.3071 52.6714 19.5721C54.1125 18.5216 55.4397 17.4421 55.935 18.0014C56.4121 18.5415 56.8198 19.1393 57.1486 19.781C57.2017 19.8903 57.2122 20.0155 57.1781 20.1321C57.1439 20.2488 57.0676 20.3485 56.964 20.4118C51.712 23.7254 52.4887 24.5905 46.901 28.8889C46.2895 29.3597 45.5902 27.8679 45.0465 27.3476Z"
          fill="currentColor"
        />
        <path
          d="M40.0012 23.5866C39.8908 23.5667 39.7682 23.5386 39.7081 23.4421C39.6479 23.3455 39.6746 23.2155 39.7035 23.1049C40.5268 19.9186 39.8989 20.3985 40.7226 17.2136C41.0347 16.0041 41.6576 14.0609 42.0955 12.8913C42.7219 11.2195 43.2372 9.58718 43.9573 9.78024C44.652 9.96791 45.3207 10.2419 45.9475 10.5959C46.052 10.6578 46.1297 10.7566 46.1653 10.8729C46.2008 10.9892 46.1917 11.1146 46.1398 11.2245C43.5759 16.8892 44.6995 17.1815 42.3963 23.8513C42.1448 24.5813 40.7412 23.7212 40.0012 23.5866Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
