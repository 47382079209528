import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CheckInCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M17 10L12 15L9 12M13 21C8.029 21 4 16.971 4 12C4 7.029 8.029 3 13 3C17.971 3 22 7.029 22 12C22 16.971 17.971 21 13 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
