import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MaleSexIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9336 10.9305C5.17839 12.1753 7.19661 12.1753 8.4414 10.9305C9.61993 9.75197 9.68267 7.88019 8.62964 6.62785C8.61515 6.616 8.60112 6.60332 8.5876 6.5898C8.55799 6.56019 8.53237 6.52809 8.51074 6.49418C8.48807 6.47008 8.46496 6.44625 8.4414 6.42269C7.19661 5.1779 5.17839 5.1779 3.9336 6.42269C2.6888 7.66749 2.6888 9.6857 3.9336 10.9305ZM9.2369 11.726C10.7317 10.2312 10.8997 7.91203 9.74103 6.23187L12.1729 3.80005L12.1729 5.56705C12.1729 5.87771 12.4247 6.12955 12.7354 6.12955C13.046 6.12955 13.2979 5.87771 13.2979 5.56705L13.2979 2.44205C13.2979 2.13139 13.046 1.87955 12.7354 1.87955L9.61035 1.87955C9.29969 1.87955 9.04785 2.13139 9.04785 2.44205C9.04785 2.75271 9.29969 3.00455 9.61035 3.00455L11.3774 3.00455L8.98641 5.3955C7.29207 3.94697 4.74109 4.0242 3.1381 5.6272C1.45397 7.31133 1.45397 10.0419 3.1381 11.726C4.82224 13.4101 7.55276 13.4101 9.2369 11.726Z"
        fill="#494D55"
      />
    </SvgIcon>
  );
}
