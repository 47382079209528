import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BellIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.9 26.25H13.1M23 11.25C23 7.1085 19.6415 3.75 15.5 3.75C11.3585 3.75 8 7.1085 8 11.25V15.006C8 15.492 7.7255 15.9345 7.292 16.152L6.5375 16.5285C5.5955 17.001 5 17.964 5 19.017C5 20.553 6.245 21.798 7.781 21.798H23.219C24.755 21.798 26 20.553 26 19.017C26 17.964 25.4045 17.001 24.4625 16.53L23.708 16.1535C23.2745 15.9345 23 15.492 23 15.006V11.25Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
