import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0116 6.68309C12.6935 5.88539 14.6691 4 16.4654 4C19.6077 4 21.414 6.59926 20.9189 9.69575C19.9295 15.8864 12.0116 19.8353 12.0116 19.8353C12.0116 19.8353 4.09349 15.3816 3.10425 9.69575C2.52404 6.35891 4.41574 4 7.55805 4C9.35436 4 11.3297 5.88539 12.0116 6.68309Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
