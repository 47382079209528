import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MapMarkerOnCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.79411 14.5456C5.42716 14.9889 3 16.2253 3 17.6842C3 19.5155 6.81695 21 11.5263 21C16.2357 21 20.0526 19.5155 20.0526 17.6842C20.0526 16.2253 17.6255 14.9889 14.2585 14.5456M17.2105 8.526C17.2105 5.47453 14.6659 3 11.5263 3C8.38674 3 5.8421 5.47453 5.8421 8.526C5.8421 12.6707 11.5263 17.2105 11.5263 17.2105C11.5263 17.2105 17.2105 12.6707 17.2105 8.526ZM12.8661 7.34443C13.606 8.08437 13.606 9.28405 12.8661 10.024C12.1261 10.7639 10.9265 10.7639 10.1865 10.024C9.44658 9.28406 9.44658 8.08437 10.1865 7.34443C10.9265 6.60449 12.1261 6.60449 12.8661 7.34443Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
