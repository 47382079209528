import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ECGInHeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M7.55556 11.5556H9.53067L11.0124 9.33333L12.9876 13.7778L14.4693 11.5556H16.4444M12 5.71111C12.6889 4.90489 13.8809 4 15.696 4C18.8702 4 21 6.97956 21 9.75467C21 15.5556 13.7778 20 12 20C10.2222 20 3 15.5556 3 9.75467C3 6.97956 5.12978 4 8.304 4C10.1191 4 11.3111 4.90489 12 5.71111Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
