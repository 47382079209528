import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PassportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M6 6.62006C6 6.24567 6.2322 5.90997 6.58229 5.77767L13.7822 3.0588C14.3708 2.8365 14.9999 3.2721 14.9999 3.90119V6.62006H6ZM6 6.62006L16.7999 6.61996C17.7944 6.61996 18.5999 7.42545 18.5999 8.41994V19.2C18.5999 20.19 17.7899 21 16.7999 21H7.79998C6.80549 21 6 20.1945 6 19.2V6.62006ZM9.59996 16.5001H14.9999M13.5727 10.7273C14.2757 11.4303 14.2757 12.57 13.5727 13.2729C12.8698 13.9758 11.7301 13.9758 11.0272 13.2729C10.3242 12.57 10.3242 11.4303 11.0272 10.7273C11.7301 10.0244 12.8698 10.0244 13.5727 10.7273Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
