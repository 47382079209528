import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SeriesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3.00003 11.5263V10.5789C3.00003 9.53251 3.84833 8.68421 4.89477 8.68421H18.1579C19.2044 8.68421 20.0527 9.53251 20.0527 10.5789V18.1579C20.0527 19.2043 19.2044 20.0526 18.1579 20.0526H13.4211M5.36846 5.8421H17.6842M7.2632 3H16.7369M6.97804 15.2267V17.022H8.7733M11.5263 16.7368C11.5263 19.0913 9.61766 21 7.26319 21C4.90871 21 3.00003 19.0913 3.00003 16.7368C3.00003 14.3824 4.90871 12.4737 7.26319 12.4737C8.39385 12.4737 9.4782 12.9228 10.2777 13.7223C11.0772 14.5218 11.5263 15.6062 11.5263 16.7368Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
