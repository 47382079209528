import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartWithMyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.53199 18.2077C6.34131 17.0423 3.00003 14.1328 3.00003 10.5879C3.00003 8.37542 4.67701 6 7.17639 6C8.6056 6 9.54418 6.72141 10.0866 7.36417C10.629 6.72141 11.5676 6 12.9968 6C15.4962 6 17.1732 8.37542 17.1732 10.5879C17.1732 10.8334 17.1572 11.0759 17.1266 11.3149M11.6458 18.3307V14.0787L13.7718 16.9607L15.8977 14.0787V18.3307M18.4489 14.0787L19.7244 15.8504M19.7244 15.8504L21 14.0787M19.7244 15.8504V18.3307"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
