import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FiletypeGIFIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.14 12.25H17.26M17.698 10.004H15.138V14.5M12.45 10V14.5M9.75 10H8.25C7.007 10 6 11.007 6 12.25C6 13.493 7.007 14.5 8.25 14.5H9.75V12.25H8.5M16 21H8C5.239 21 3 18.761 3 16V8C3 5.239 5.239 3 8 3H16C18.761 3 21 5.239 21 8V16C21 18.761 18.761 21 16 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
