import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MegaphoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 13.2745L12.694 17.4405C12.919 18.7935 11.876 20.0245 10.505 20.0245C9.42 20.0245 8.494 19.2405 8.316 18.1695L7.5 13.2745M13.8571 13.1197V6.36969M14.25 13.2745H6.375C4.511 13.2745 3 11.7635 3 9.89954C3 8.03555 4.511 6.52455 6.375 6.52455H14.25L19.251 3.19057C19.999 2.69257 21 3.22857 21 4.12757V15.6726C21 16.5716 19.999 17.1066 19.251 16.6086L14.25 13.2745Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
