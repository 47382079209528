import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartShieldFingerprintIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 133 150">
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M64.3726 29.4336C74.898 39.5748 93.7912 43.8388 101.922 44.7031C104.15 94.9482 77.8174 116.025 64.3726 120.282C30.5685 108.681 25.1906 65.0623 26.7271 44.7031C46.395 42.3214 60.0191 33.5311 64.3726 29.4336Z"
          fill="currentColor"
        />
        <path
          d="M76.6483 65.3357C72.8422 61.439 67.5998 66.4295 66.0586 68.6171C62.2303 63.0984 56.5129 63.5459 54.5739 65.3357C49.2768 70.2253 61.3354 83.5819 66.0586 86.0678C69.7874 82.9356 82.9127 71.7493 76.6483 65.3357Z"
          fill="white"
        />
        <path
          d="M95.521 98.9805C95.05 99.1877 94.1308 100.054 94.2212 101.863C94.3342 104.123 94.0517 117.29 80.376 119.381C79.3964 119.569 77.4374 119.483 77.4374 117.629C77.4374 117.586 77.4382 117.544 77.4397 117.504C77.5303 115.149 81.3633 116.054 83.5473 115.169C85.8589 114.231 87.9012 112.72 89.1917 110.17C91.5652 105.479 89.9829 101.184 90.9436 98.9805C91.9042 96.7766 93.7126 95.5898 95.521 95.5898C99.2035 95.5898 101.425 97.6657 102.637 99.8876C107.004 107.893 101.425 117.629 103.965 118.613C106.504 119.597 103.561 124.953 99.5209 119.825C98.1708 118.111 102.637 110.17 101.425 105.947C99.7544 100.125 96.6138 98.6096 95.521 98.9805Z"
          fill="white"
        />
        <path
          d="M87.6095 98.7529C88.5518 94.5298 91.4857 92.9091 93.754 92.4502C94.6986 92.2591 95.5211 91.4961 95.5211 90.5323C95.5211 89.6792 94.8758 88.9475 94.0236 88.9872C88.8225 89.2291 83.8896 93.5912 83.9928 99.8831C84.1058 106.777 82.3539 108.416 79.9805 109.094C79.6222 109.197 79.3053 109.297 79.026 109.393C77.774 109.823 76.8575 111.255 77.8345 112.148C78.2035 112.485 78.6564 112.598 78.9633 112.598C88.4006 111.468 86.0837 105.591 87.6095 98.7529Z"
          fill="white"
        />
        <path
          d="M111.422 96.4966C112.458 97.2662 115.752 99.5317 114.555 96.2674C110.887 86.2707 100.622 78.7914 90.4549 81.9551C80.1926 85.1483 75.256 94.7153 76.7592 104.236C77.1668 106.817 80.0584 104.898 80.3759 104.236C80.6206 103.725 81.2801 103.416 81.2801 96.2674C81.2801 88.2165 99.2063 75.7865 110.927 95.9295C111.053 96.146 111.22 96.3473 111.422 96.4966Z"
          fill="white"
        />
        <path
          d="M108.999 98.5553C105.392 90.5926 99.1012 88.9328 95.4202 88.9161C94.6966 88.9129 94.1538 89.5273 94.1538 90.2508V90.595C94.1538 91.5188 95.0299 92.1917 95.9536 92.1758C100.623 92.0956 105.025 96.1151 107.069 101.329C107.369 102.095 108.183 103.314 108.992 103.467C111.044 103.854 109.86 100.458 108.999 98.5553Z"
          fill="white"
        />
        <path
          d="M98.377 109.799C97.2139 117.563 93.3117 120.572 91.0672 121.85C87.5384 124.032 84.5498 121.216 87.7666 119.628C94.5051 116.302 93.8653 113.968 95.6832 105.888C95.6951 105.835 95.7073 105.783 95.7197 105.732C96.4714 102.635 98.8491 106.647 98.377 109.799Z"
          fill="white"
        />
        <path
          d="M105.869 84.7383C108.091 87.1621 118.191 92.6158 116.777 111.199C115.543 115.42 113.545 112.815 113.545 109.987C113.545 100.328 112.03 98.2715 109.707 93.6258C108.237 90.6849 105.869 84.7383 105.869 84.7383Z"
          fill="white"
        />
        <path
          d="M107.398 101.645C109.418 115.986 105.624 117.993 102.844 118.53C100.344 119.353 102.551 121.238 103.451 121.971C110.722 120.356 112.246 112.149 110.428 103.059C110.013 98.9902 106.976 98.6509 107.398 101.645Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}
