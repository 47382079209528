import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DiabetesIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="50" height="63" viewBox="0 0 50 63" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6128 20.0699C16.4752 20.0699 20.2561 16.0484 20.865 11.0356C20.865 8.60425 20.2975 6.13154 19.215 4.06995H42.285C46.065 4.06995 49.135 7.13995 49.135 10.9199V42.8299C49.135 49.5699 43.665 55.0399 36.925 55.0399H33.565C33.595 54.8099 33.595 54.5799 33.565 54.3399L32.915 49.4599C32.725 48.0599 31.535 47.0099 30.115 47.0099H25.095C23.675 47.0099 22.485 48.0599 22.295 49.4599L21.645 54.3399C21.615 54.5799 21.615 54.8099 21.645 55.0399H19.075C12.335 55.0399 6.86499 49.5699 6.86499 42.8299V19.5276C8.07517 19.8869 9.35093 20.0699 10.6128 20.0699Z"
        fill="#FFEED9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.585 19.6927C17.74 18.5817 20.3663 15.1411 20.865 11.0356C20.865 10.9071 20.8634 10.7786 20.8602 10.65H39.215C40.9878 10.65 42.425 12.0872 42.425 13.86V29.04C42.425 30.8129 40.9878 32.25 39.215 32.25H16.795C15.0221 32.25 13.585 30.8129 13.585 29.04V19.6927Z"
        fill="white"
      />
      <path
        d="M28.565 49.5H27.445C25.8986 49.5 24.645 50.7536 24.645 52.3V60.13C24.645 61.6764 25.8986 62.93 27.445 62.93H28.565C30.1114 62.93 31.365 61.6764 31.365 60.13V52.3C31.365 50.7536 30.1114 49.5 28.565 49.5Z"
        fill="#FFBE40"
      />
      <path
        d="M32.525 21.3599L28.645 13.8099C28.375 13.2899 27.635 13.2899 27.365 13.8099L23.485 21.3599C22.525 23.2199 22.535 25.4699 23.635 27.2499C24.455 28.5799 25.805 29.6999 28.005 29.4599C30.215 29.6999 31.565 28.5899 32.375 27.2499C33.465 25.4699 33.485 23.2199 32.525 21.3599Z"
        fill="#FFBE40"
      />
      <path
        d="M30.7851 23.0799C30.9551 23.2699 31.0751 23.4899 31.1551 23.7299C31.2851 24.1099 31.3751 24.5199 31.3551 24.9199C31.3051 25.9099 30.5451 26.8099 29.5951 27.1099C28.7451 27.3799 27.8051 27.1999 27.0951 26.6999C27.0651 26.6799 27.0351 26.6499 27.0151 26.6099C26.9951 26.5799 26.9851 26.5399 26.9751 26.4999C26.9751 26.4599 26.9751 26.4199 26.9851 26.3799C26.9951 26.3399 27.0151 26.2999 27.0451 26.2699C27.1051 26.1999 27.1651 26.1299 27.2251 26.0599C27.2451 26.0299 27.2851 26.0099 27.3151 25.9999C27.3551 25.9999 27.3851 25.9999 27.4251 25.9999C27.5851 26.0699 27.7651 26.1299 27.9351 26.1799C28.4951 26.3199 29.1351 26.2399 29.5851 25.8599C30.0951 25.4299 30.2751 24.6899 30.1251 24.0499C30.0951 23.9199 30.0451 23.7399 29.9851 23.5699C29.9751 23.5299 29.9751 23.4799 29.9851 23.4399C29.9951 23.3999 30.0251 23.3599 30.0551 23.3299C30.1751 23.2299 30.3051 23.1399 30.4351 23.0399C30.4951 22.9999 30.5551 22.9499 30.6251 22.9599C30.6851 22.9699 30.7351 23.0099 30.7751 23.0599L30.7851 23.0799Z"
        fill="#FFEED9"
      />
      <rect
        x="13.7051"
        y="36.0699"
        width="8"
        height="8"
        rx="2"
        fill="#FFBE40"
      />
      <rect
        x="24.7051"
        y="36.0699"
        width="8"
        height="8"
        rx="2"
        fill="#FFBE40"
      />
      <rect
        x="35.7051"
        y="36.0699"
        width="8"
        height="8"
        rx="2"
        fill="#FFBE40"
      />
      <path
        d="M17.865 9.75639C17.3721 13.8144 14.3114 17.0699 9.5656 17.0699C5.31273 17.0699 0.86499 14.5022 0.86499 9.75639C0.86499 0.964081 5.70247 0.0699463 10.4483 0.0699463C15.194 0.0699463 17.865 5.01061 17.865 9.75639Z"
        fill="#FFBE40"
      />
      <path
        d="M7.42738 10.265C7.44778 10.7838 7.49879 11.2785 7.68243 11.7249C8.0497 12.6056 8.69244 13.0158 9.51881 12.9072C9.91668 12.8469 10.3044 12.7625 10.6002 12.4005C10.9675 11.9662 10.9981 11.2061 10.6512 10.7355C10.3554 10.3253 9.94728 10.1202 9.5392 9.91509C8.97809 9.64966 8.41699 9.40835 7.86609 9.13086C7.33558 8.86542 6.85607 8.50347 6.5092 7.93641C5.55022 6.38 6.08072 4.22034 7.59061 3.48437C8.33536 3.12241 9.11073 3.05002 9.90649 3.07415C10.4982 3.09828 11.0899 3.1948 11.6306 3.48437C12.0489 3.7136 12.406 4.03936 12.5284 4.60643C12.61 4.99251 12.5692 5.36653 12.355 5.68022C12.2019 5.90946 11.9979 6.06631 11.7632 6.12664C11.4776 6.21109 11.1715 6.23522 10.9063 6.28348C10.8246 5.88533 10.7736 5.53544 10.6818 5.18555C10.539 4.61849 10.1819 4.31686 9.70243 4.20828C9.36577 4.13589 9.0291 4.17208 8.71284 4.31686C7.83547 4.69088 7.87627 5.88533 8.41697 6.38C8.81485 6.74196 9.28416 6.95913 9.74325 7.1763C10.2534 7.41761 10.7737 7.64684 11.2736 7.91228C11.7122 8.14152 12.0897 8.49141 12.3957 8.94988C12.8242 9.6014 12.9365 10.3494 12.8242 11.1337C12.7528 11.6645 12.6406 12.1833 12.3447 12.6298C11.9265 13.2571 11.3756 13.6312 10.743 13.8363C9.92687 14.1138 9.0903 14.1138 8.25374 13.9931C7.68242 13.9207 7.12134 13.7639 6.62144 13.3899C6.05013 12.9555 5.76445 12.1833 5.89708 11.3991C5.96849 10.9768 6.18274 10.6752 6.5092 10.4822C6.79486 10.3132 7.10093 10.277 7.4172 10.265H7.42738Z"
        fill="#FBFDFC"
      />
    </SvgIcon>
  );
}
