import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BloodPressureCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M17.71 21.6973C18.5163 21.6973 19.17 21.0437 19.17 20.2373C19.17 19.431 18.5163 18.7773 17.71 18.7773C16.9037 18.7773 16.25 19.431 16.25 20.2373C16.25 21.0437 16.9037 21.6973 17.71 21.6973Z"
        fill="currentColor"
      />
      <path
        d="M12.2305 20.4471C12.2305 17.3171 14.7605 14.7871 17.8905 14.7871C21.0205 14.7871 23.5505 17.3171 23.5505 20.4471"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7109 20.237L20.2909 18.457"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.8906 15.0967V16.2467"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.332 20.0869H13.482"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.1836 20.0869H23.3336"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.682 28.767L24.452 22.647C24.582 22.527 24.712 22.407 24.832 22.277C28.082 18.837 29.212 15.997 29.402 13.827C29.692 10.497 27.252 7.43701 23.932 7.03701C19.652 6.52701 17.682 11.457 17.682 11.457C17.682 11.457 15.772 6.52701 11.492 7.03701C8.17195 7.43701 5.74196 10.487 6.02196 13.827C6.21196 16.007 7.34196 18.847 10.592 22.277C10.712 22.407 10.842 22.527 10.972 22.647L17.682 28.767Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
