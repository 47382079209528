import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MapMarkerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.87307 9.955C9.87307 11.06 10.7681 11.955 11.8731 11.955C12.9781 11.955 13.8731 11.06 13.8731 9.955V9.918C13.8731 8.813 12.9781 7.918 11.8731 7.918C10.7681 7.918 9.87307 8.813 9.87307 9.918M5.00006 10.045V9.873C5.00006 6.077 8.07706 3 11.8731 3C15.6691 3 18.7461 6.077 18.7461 9.873V10.045C18.7461 13.506 14.3641 18.716 12.5981 20.676C12.2091 21.108 11.5371 21.108 11.1481 20.676C9.38206 18.716 5.00006 13.506 5.00006 10.045Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
