import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Pattern1(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 307 243">
      <path
        d="M207.569 87.3159C172.723 89.4287 138.704 108.913 122.082 139.74C105.461 170.567 108.617 211.907 132.116 237.828C139.391 245.856 148.27 252.288 157.639 257.702C168.692 264.092 180.797 269.209 193.529 269.951C195.553 270.068 197.737 270.031 199.383 268.829C201.398 267.35 201.949 264.603 202.337 262.139C211.445 203.794 212.741 146.144 207.569 87.3159Z"
        fill="currentColor"
      />
      <path
        d="M317.977 87.3159C283.13 89.4287 249.106 108.913 232.49 139.74C215.873 170.567 219.024 211.907 242.523 237.833C249.798 245.861 258.677 252.293 268.047 257.706C279.1 264.096 291.205 269.214 303.932 269.956C305.961 270.073 308.14 270.036 309.79 268.834C311.805 267.355 312.357 264.608 312.745 262.143C321.862 203.799 323.148 146.149 317.977 87.3206"
        fill="currentColor"
      />
      <path
        d="M90.3492 106.373C69.8283 107.213 52.4447 111.626 34.5935 121.819C16.7424 132.012 2.33242 149.839 0.251813 170.356C-2.08596 193.648 12.1136 216.602 31.9986 228.823C51.8836 241.044 99.3262 264.505 99.5553 241.091C99.9013 209.137 101.472 193.132 99.6068 169.257C97.9563 148.172 97.8534 106.387 90.2931 106.537"
        fill="currentColor"
      />
      <path
        d="M179.245 62.2074C200.855 62.0853 236.09 59.7941 257.565 57.3246C288.264 53.7893 317.828 52.0052 317.655 38.6808C317.444 22.3422 315.957 14.2668 313.83 6.25719C313.278 4.19377 311.967 2.41703 310.163 1.28459C308.359 0.152156 306.194 -0.251921 304.105 0.153701C198.242 19.7975 130.442 8.13518 8.78918 18.8726C-4.54074 20.0511 2.51459 42.4884 1.62625 55.8973C1.49066 57.8974 1.44391 60.1463 2.80917 61.6111C4.17441 63.0759 6.43271 63.1698 8.4198 63.1604L179.245 62.2074Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
