import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BulletListIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.40035 19.0065H21M8.40035 11.806H21M8.40035 5H21M3.89999 19.0065H3M3.89999 11.806H3M3.89999 5H3"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
