import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ECGInCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M18 12H16.309C16.12 12 15.946 12.107 15.862 12.276L14.447 15.105C14.263 15.474 13.737 15.474 13.553 15.105L10.43 8.861C10.249 8.499 9.735 8.491 9.543 8.847L7.984 11.737C7.896 11.899 7.727 12 7.544 12H3M3.523 9C4.76 5.507 8.083 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21C8.083 21 4.76 18.493 3.523 15"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
