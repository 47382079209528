import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ShoeMovingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.91193 7.34334H12.0012M12.0012 7.34334L13.6294 6.35122C13.8895 6.19276 14.2076 6.16147 14.4936 6.26622C14.7796 6.37096 15.0023 6.60032 15.0986 6.88928L17.4018 13.7991L20.6422 18.1196C21.0514 18.6651 21.1172 19.395 20.8122 20.0049C20.5073 20.6147 19.8839 21 19.2021 21H15.2716C14.1601 21.0007 13.0986 20.5383 12.3422 19.7238L3.80018 10.5297C3.24544 9.93141 2.9583 9.13298 3.0049 8.31839C3.05151 7.50381 3.42785 6.74334 4.04721 6.2122L7.47063 3.27884C7.81434 2.98441 8.29798 2.91695 8.70914 3.10609C9.1203 3.29523 9.38378 3.70637 9.38386 4.15895V7.34334H12.0012ZM3.00009 17.4996H5.00033M8.0007 21H3.00009M20.5492 17.9996H15.5246C14.6921 18.0019 13.8965 17.656 13.3303 17.0455L3.65517 6.62625M13.0013 12.999L16.9068 12.3229M12.0012 10.4987L16.0657 9.79564"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
