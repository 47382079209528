import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PillBottleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.889 8H8.111C6.945 8 6 7.055 6 5.889V5.111C6 3.945 6.945 3 8.111 3H15.889C17.055 3 18 3.945 18 5.111V5.889C18 7.055 17.055 8 15.889 8Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.833 21H8.167C6.418 21 5 19.582 5 17.833V11.166C5 9.418 6.418 8 8.167 8H15.834C17.582 8 19 9.418 19 11.167V17.834C19 19.582 17.582 21 15.833 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12.5562V16.7782"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.111 14.667H9.88904"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0002 5.29588V5.8061"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.25021 5.29588V5.8061"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7502 5.29588V5.8061"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
