import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EyeOpenIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.11775 12.467C2.96075 12.176 2.96075 11.823 3.11775 11.532C5.00975 8.033 8.50475 5 11.9997 5C15.4947 5 18.9897 8.033 20.8817 11.533C21.0387 11.824 21.0387 12.177 20.8817 12.468C18.9897 15.967 15.4947 19 11.9997 19C8.50475 19 5.00975 15.967 3.11775 12.467Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1211 9.87868C15.2926 11.0502 15.2926 12.9497 14.1211 14.1213C12.9495 15.2929 11.05 15.2929 9.87841 14.1213C8.70684 12.9497 8.70684 11.0502 9.87841 9.87868C11.05 8.70711 12.9495 8.70711 14.1211 9.87868Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
