import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HospitalSymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.003 15V9M15.003 15V9M14.993 12H9.013M21.005 12C21.005 16.97 16.976 21 12.001 21C7.03 21 3 16.971 3 12C3 7.029 7.03 3 12.001 3C16.976 3 21.005 7.03 21.005 12Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
