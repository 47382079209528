import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DumbbellIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8721 7.62968L16.3692 10.1268L10.1258 16.3702L7.62876 13.8731L13.8721 7.62968Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.88327 11.3754C4.2279 11.0308 4.78666 11.0308 5.13129 11.3754L12.6237 18.8678C12.9684 19.2125 12.9684 19.7712 12.6237 20.1159L11.9989 20.7406C11.6543 21.0853 11.0956 21.0853 10.7509 20.7406L3.25847 13.2482C2.91384 12.9036 2.91384 12.3448 3.25847 12.0002L3.88327 11.3754Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.88343 15.1219C4.22806 14.7773 4.78682 14.7773 5.13145 15.1219L8.87827 18.8687C9.2229 19.2133 9.2229 19.7721 8.87827 20.1167L8.25348 20.7415C7.90884 21.0862 7.35009 21.0862 7.00545 20.7415L3.25863 16.9947C2.914 16.6501 2.914 16.0913 3.25864 15.7467L3.88343 15.1219Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1151 12.6253C19.7705 12.9699 19.2117 12.9699 18.8671 12.6253L11.3747 5.13284C11.03 4.78821 11.03 4.22945 11.3747 3.88482L11.9995 3.26002C12.3441 2.91539 12.9028 2.91539 13.2475 3.26002L20.7399 10.7525C21.0846 11.0971 21.0846 11.6559 20.7399 12.0005L20.1151 12.6253Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1154 8.87811C19.7708 9.22274 19.212 9.22274 18.8674 8.87811L15.1206 5.13129C14.776 4.78666 14.776 4.2279 15.1206 3.88327L15.7454 3.25847C16.09 2.91384 16.6488 2.91384 16.9934 3.25847L20.7402 7.00529C21.0849 7.34993 21.0849 7.90868 20.7402 8.25332L20.1154 8.87811Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
