import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PageWithPencilIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.68422 20.0527H4.89474C3.8483 20.0527 3 19.2044 3 18.1579V4.89474C3 3.8483 3.8483 3 4.89474 3H16.2632C17.3096 3 18.1579 3.8483 18.1579 4.89474V7.73685"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5264 20.0527L14.0123 19.7458C14.1872 19.7242 14.3499 19.6448 14.4746 19.5203L20.498 13.4969C21.1651 12.8326 21.1677 11.7533 20.5036 11.0859L20.498 11.0811V11.0811C19.8337 10.414 18.7544 10.4114 18.0869 11.0755V11.0811L12.1185 17.0496C11.9971 17.1702 11.9185 17.3272 11.8949 17.4967L11.5264 20.0527Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.73685 16.263C6.95202 16.263 6.3158 15.6268 6.3158 14.842C6.3158 14.0571 6.95202 13.4209 7.73685 13.4209C8.52168 13.4209 9.15791 14.0571 9.15791 14.842C9.15791 15.6268 8.52168 16.263 7.73685 16.263Z"
          fill="currentColor"
        />
        <path
          d="M6.3158 6.7896H13.8948"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.3158 10.1055H10.1053"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
