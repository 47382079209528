import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PageWithWritingIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.875 12.125C11.5656 12.125 12.125 11.5656 12.125 10.875V7.125C12.125 6.78 11.845 6.5 11.5 6.5H9.625M10.875 12.125C10.1844 12.125 9.625 11.5656 9.625 10.875V6.5M10.875 12.125L2.125 12.125C1.43437 12.125 0.875 11.5656 0.875 10.875L0.875 2.125C0.875 1.78 1.155 1.5 1.5 1.5L9 1.5C9.345 1.5 9.625 1.78 9.625 2.125V6.5M3.375 4H7.125M3.375 6.41876H7.125M3.375 9.0375H5.25"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
