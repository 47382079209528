import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BloodBagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M20.2001 11.1V17.4C20.2001 19.3881 18.5882 21 16.6001 21H13.6004C11.6123 21 10.0004 19.3881 10.0004 17.4V16.4469M15.6731 6.825C14.1062 7.3416 11.5673 7.3416 10.0013 6.825C8.43533 6.3084 5.89643 6.3084 4.32953 6.825M8.00153 10.4979H12.002M8.00153 12.9981H12.002M4.73183 11.1873L4.04063 14.6028C3.76343 15.9753 4.94603 17.2011 6.32843 16.9716L9.02033 16.5261C9.67013 16.4181 10.3325 16.4181 10.9823 16.5261L13.6589 16.9698C15.0485 17.2002 16.2383 15.9681 15.9593 14.5884L15.2717 11.1882C15.1133 10.4034 15.1133 9.5952 15.2717 8.8104L15.9593 5.4102C16.2383 4.0305 15.0485 2.7984 13.6589 3.0288L10.9823 3.4725C10.3325 3.5805 9.67013 3.5805 9.02033 3.4725L6.34373 3.0279C4.95413 2.7975 3.76523 4.0296 4.04423 5.4093L4.73183 8.8095C4.89023 9.5943 4.89023 10.4034 4.73183 11.1873Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
