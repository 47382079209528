import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DropletIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6265 3.7201L17.9394 9.49121C19.5675 11.6721 19.827 14.5845 18.6101 17.0188C17.3932 19.4531 14.9078 20.9935 12.1863 21V21C9.46432 20.9944 6.9781 19.4545 5.76056 17.02C4.54303 14.5854 4.80229 11.6724 6.43064 9.49121L10.7461 3.7201C11.0861 3.26679 11.6197 3 12.1863 3C12.753 3 13.2865 3.26679 13.6265 3.7201Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
