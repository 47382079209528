import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatHeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 60 47" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.89858 6.5498e-05C0.877636 -0.00149074 0.201737 -0.00252104 0.201737 3.43636V17.754V29.399C0.0842061 31.2444 0.166477 34.9351 1.43581 34.9351H23.8204C25.6717 34.9351 27.5845 34.8957 29.5039 34.8376C29.4818 34.5296 29.4706 34.2161 29.4706 33.8971C29.4706 18.1844 38.1128 16.5954 46.5957 16.5954C46.7389 16.5954 46.881 16.598 47.022 16.6029C47.0303 14.7868 47.0523 13.1259 47.0964 11.836V4.58178C47.2139 3.05456 47.0259 0.000132424 45.3335 0.000132424C44.891 0.000132424 44.4726 0.0541768 43.8713 0.131851C41.5972 0.425589 36.707 1.05726 18.0076 0.381943C17.3565 0.35843 15.7223 0.32763 13.6818 0.289176C9.96016 0.219038 4.88729 0.123435 1.96469 0.000132424C1.9425 0.00013245 1.92046 9.88535e-05 1.89858 6.5498e-05Z"
        fill="#D1DBF2"
      />
      <path
        d="M59.8367 35.2644C59.0463 41.7453 54.1538 46.9617 46.5587 46.9617C39.7616 46.9617 32.6484 42.8596 32.6484 35.2644C32.6484 21.1961 40.3862 19.7734 47.9814 19.7734C55.5765 19.7734 59.8367 27.6693 59.8367 35.2644Z"
        fill="currentColor"
      />
      <path
        d="M23.8194 28.4467C21.3336 41.2146 12.4262 43.6533 8.2832 43.2766C13.0288 36.2712 11.7671 29.4353 10.543 26.893C12.3509 26.5541 20.1472 27.7876 23.8194 28.4467Z"
        fill="#D1DBF2"
      />
      <path
        d="M51.9037 28.2938C49.9196 26.2625 47.1868 28.864 46.3833 30.0043C44.3877 27.1275 41.4073 27.3608 40.3965 28.2938C37.6351 30.8427 43.9212 37.8055 46.3833 39.1013C48.3271 37.4685 55.1693 31.6371 51.9037 28.2938Z"
        fill="white"
      />
    </SvgIcon>
  );
}
