import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function GestureOneFingerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13.3737 12V8.85C13.3737 8.1048 12.7689 7.5 12.0237 7.5C11.2785 7.5 10.6737 8.1048 10.6737 8.85M13.3737 9.75C13.3737 9.0048 13.9785 8.4 14.7237 8.4C15.4689 8.4 16.0737 9.0048 16.0737 9.75V12.9M7.97373 10.2H7.77123C7.03593 10.2 6.33123 10.4916 5.81193 11.0118C5.19003 11.6337 4.90023 12.513 5.03073 13.3824L5.48343 16.401C5.88033 19.0443 8.15103 21 10.824 21H13.3737C16.3563 21 18.7737 18.5826 18.7737 15.6V10.65C18.7737 9.9048 18.1689 9.3 17.4237 9.3C16.6785 9.3 16.0737 9.9048 16.0737 10.65M7.97373 12V4.35C7.97373 3.6048 8.57853 3 9.32373 3C10.0689 3 10.6737 3.6048 10.6737 4.35V12"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
