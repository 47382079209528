import { SvgIcon, SvgIconProps } from "@mui/material";

export default function HealthScoreIndicatorIconsSet(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="27"
      viewBox="0 0 36 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_920_221026)">
        <path
          d="M12.613 3.5C8.40019 3.5 4.58862 6.6971 4.58862 11.6417C4.58862 14.7991 7.04608 18.5125 11.951 22.7918C12.3321 23.1195 12.9039 23.1195 13.285 22.7918C18.1799 18.5125 20.6373 14.7991 20.6373 11.6417C20.6373 6.6971 16.8258 3.5 12.613 3.5Z"
          fill="#E84F3E"
        />
        <path
          d="M11.2629 23.5805L11.2629 23.5805L11.2687 23.5855C12.0422 24.2504 13.1938 24.2504 13.9673 23.5855L13.9673 23.5855L13.9739 23.5798C16.4625 21.4042 18.3743 19.3361 19.6703 17.3725C20.9646 15.4114 21.684 13.4938 21.684 11.6417C21.684 6.07717 17.361 2.45335 12.613 2.45335C7.86492 2.45335 3.54197 6.07717 3.54197 11.6417C3.54197 13.4939 4.26138 15.4116 5.55718 17.373C6.85456 19.3367 8.76916 21.4048 11.2629 23.5805Z"
          stroke="white"
          strokeWidth="2.09331"
        />
      </g>
      <g filter="url(#filter1_d_920_221026)">
        <path
          d="M19.0251 3.5C14.8123 3.5 11.0007 6.6971 11.0007 11.6417C11.0007 14.7991 13.4582 18.5125 18.3631 22.7918C18.7442 23.1195 19.316 23.1195 19.6971 22.7918C24.592 18.5125 27.0494 14.7991 27.0494 11.6417C27.0494 6.6971 23.2379 3.5 19.0251 3.5Z"
          fill="#FFBE40"
        />
        <path
          d="M17.675 23.5805L17.675 23.5805L17.6808 23.5855C18.4543 24.2504 19.6059 24.2504 20.3794 23.5855L20.3794 23.5855L20.386 23.5798C22.8746 21.4042 24.7865 19.3361 26.0824 17.3725C27.3767 15.4114 28.0961 13.4938 28.0961 11.6417C28.0961 6.07717 23.7731 2.45335 19.0251 2.45335C14.277 2.45335 9.95408 6.07717 9.95408 11.6417C9.95408 13.4939 10.6735 15.4116 11.9693 17.373C13.2667 19.3367 15.1813 21.4048 17.675 23.5805Z"
          stroke="white"
          strokeWidth="2.09331"
        />
      </g>
      <rect
        x="15.8855"
        y="8.62891"
        width="1.38011"
        height="6.50623"
        rx="0.690055"
        fill="white"
      />
      <rect
        x="20.5769"
        y="8.62891"
        width="1.38011"
        height="6.50623"
        rx="0.690055"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.577 11.8674C16.5827 11.5082 16.8962 11.2213 17.2773 11.2266C18.4996 11.2439 19.2202 11.2414 20.5712 11.2266C20.9523 11.2224 21.2648 11.5103 21.2692 11.8696C21.2737 12.229 20.9683 12.5236 20.5872 12.5278C19.2287 12.5426 18.4951 12.5452 17.2566 12.5277C16.8756 12.5224 16.5713 12.2267 16.577 11.8674Z"
        fill="white"
      />
      <rect
        x="12.3972"
        y="4.19922"
        width="13.2576"
        height="13.2576"
        rx="6.62881"
        fill="#FFBE40"
      />
      <path
        d="M18.7346 7.36218C18.8247 7.34836 18.9157 7.34069 19.0068 7.33923C19.1737 7.33923 19.1964 7.71218 19.2404 8.10173C19.2713 8.37412 19.2999 8.82127 19.3013 9.09513C19.3055 9.8176 19.3097 10.5401 19.3139 11.2625C19.3155 11.2751 19.3146 11.288 19.3112 11.3002C19.3078 11.3124 19.302 11.3239 19.2941 11.3338C19.2839 11.3412 19.2723 11.3463 19.2601 11.3489C19.2479 11.3515 19.2352 11.3515 19.223 11.3489C19.0552 11.3377 18.7037 11.4466 18.6887 11.2757C18.5605 9.80295 18.6887 8.9521 18.4856 7.70974C18.4789 7.67022 18.48 7.62975 18.489 7.59069C18.4979 7.55162 18.5144 7.51473 18.5376 7.48217C18.5608 7.4496 18.5901 7.42201 18.6239 7.40099C18.6578 7.37998 18.6954 7.36595 18.7346 7.35974V7.36218ZM21.619 11.8644C21.601 12.1363 21.5295 12.4018 21.4086 12.6455C21.2186 13.0301 20.9846 13.4085 20.6664 13.7014C19.8975 14.4082 18.6708 14.5029 17.7564 14.003C16.9397 13.5569 16.3913 12.6933 16.2385 11.7673C16.2319 11.7242 16.2343 11.6802 16.2454 11.6381C16.2566 11.596 16.2763 11.5566 16.3033 11.5226C16.3303 11.4886 16.364 11.4606 16.4023 11.4405C16.4406 11.4203 16.4827 11.4084 16.5257 11.4056C16.6196 11.3984 16.7134 11.3919 16.8072 11.386C16.8464 11.3836 16.8854 11.394 16.9184 11.4157C16.9513 11.4375 16.9764 11.4693 16.99 11.5066C17.056 11.6883 17.1368 11.8642 17.2317 12.0324C17.5339 12.5693 18.063 13.0028 18.6713 13.0653C19.3623 13.1361 20.0436 12.7084 20.4062 12.1105C20.4826 11.984 20.5745 11.8176 20.6649 11.6404C20.6861 11.5984 20.7197 11.5641 20.761 11.5421C20.8023 11.5202 20.8493 11.5117 20.8956 11.5178C21.0587 11.5393 21.222 11.5631 21.3854 11.5891C21.4589 11.6008 21.5402 11.6174 21.5842 11.678C21.6163 11.7335 21.6286 11.7984 21.619 11.862V11.8644Z"
        fill="#212121"
      />
      <path
        d="M21.8055 8.50998C21.7663 8.76551 21.6028 9.00033 21.3732 9.13071C21.1436 9.2611 20.8527 9.28425 20.6042 9.192C20.6042 9.192 20.6046 9.19266 20.6042 9.19256C20.5098 9.14488 20.5174 9.13021 20.46 9.05796C20.3761 8.9523 20.3189 8.82735 20.2875 8.69748C20.2604 8.58466 20.2542 8.46809 20.2523 8.35223C20.2505 8.24185 20.2532 8.12848 20.2952 8.02587C20.3214 7.96164 20.3625 7.90401 20.4079 7.8507C20.5993 7.6266 20.9113 7.47179 21.1997 7.55115C21.3774 7.60004 21.5193 7.72809 21.6462 7.85852C21.6997 7.91356 21.753 7.97149 21.782 8.04181C21.8084 8.10604 21.8128 8.17661 21.8152 8.24576C21.8183 8.33395 21.8187 8.42265 21.8055 8.50998Z"
        fill="#212121"
      />
      <g filter="url(#filter2_d_920_221026)">
        <path
          d="M25.0251 3.5C20.8123 3.5 17.0007 6.6971 17.0007 11.6417C17.0007 14.7991 19.4582 18.5125 24.3631 22.7918C24.7442 23.1195 25.316 23.1195 25.6971 22.7918C30.592 18.5125 33.0494 14.7991 33.0494 11.6417C33.0494 6.6971 29.2379 3.5 25.0251 3.5Z"
          fill="#00948D"
        />
        <path
          d="M23.675 23.5805L23.675 23.5805L23.6808 23.5855C24.4543 24.2504 25.6059 24.2504 26.3794 23.5855L26.3794 23.5855L26.386 23.5798C28.8746 21.4042 30.7865 19.3361 32.0824 17.3725C33.3767 15.4114 34.0961 13.4938 34.0961 11.6417C34.0961 6.07717 29.7731 2.45335 25.0251 2.45335C20.277 2.45335 15.9541 6.07717 15.9541 11.6417C15.9541 13.4939 16.6735 15.4116 17.9693 17.373C19.2667 19.3367 21.1813 21.4048 23.675 23.5805Z"
          stroke="white"
          strokeWidth="2.09331"
        />
      </g>
      <rect
        x="21.8855"
        y="8.62891"
        width="1.38011"
        height="6.50623"
        rx="0.690055"
        fill="white"
      />
      <rect
        x="26.5769"
        y="8.62891"
        width="1.38011"
        height="6.50623"
        rx="0.690055"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.577 11.8674C22.5827 11.5082 22.8962 11.2213 23.2773 11.2266C24.4996 11.2439 25.2202 11.2414 26.5712 11.2266C26.9523 11.2224 27.2648 11.5103 27.2692 11.8696C27.2737 12.229 26.9683 12.5236 26.5872 12.5278C25.2287 12.5426 24.4951 12.5452 23.2566 12.5277C22.8756 12.5224 22.5713 12.2267 22.577 11.8674Z"
        fill="white"
      />
      <rect
        x="18.3972"
        y="4.19922"
        width="13.2576"
        height="13.2576"
        rx="6.62881"
        fill="#00948D"
      />
      <path
        d="M24.7346 7.36218C24.8247 7.34836 24.9157 7.34069 25.0068 7.33923C25.1737 7.33923 25.1964 7.71218 25.2404 8.10173C25.2713 8.37412 25.2999 8.82127 25.3013 9.09513C25.3055 9.8176 25.3097 10.5401 25.3139 11.2625C25.3155 11.2751 25.3146 11.288 25.3112 11.3002C25.3078 11.3124 25.302 11.3239 25.2941 11.3338C25.2839 11.3412 25.2723 11.3463 25.2601 11.3489C25.2479 11.3515 25.2352 11.3515 25.223 11.3489C25.0552 11.3377 24.7037 11.4466 24.6887 11.2757C24.5605 9.80295 24.6887 8.9521 24.4856 7.70974C24.4789 7.67022 24.48 7.62975 24.489 7.59069C24.4979 7.55162 24.5144 7.51473 24.5376 7.48217C24.5608 7.4496 24.5901 7.42201 24.6239 7.40099C24.6578 7.37998 24.6954 7.36595 24.7346 7.35974V7.36218ZM27.619 11.8644C27.601 12.1363 27.5295 12.4018 27.4086 12.6455C27.2186 13.0301 26.9846 13.4085 26.6664 13.7014C25.8975 14.4082 24.6708 14.5029 23.7564 14.003C22.9397 13.5569 22.3913 12.6933 22.2385 11.7673C22.2319 11.7242 22.2343 11.6802 22.2454 11.6381C22.2566 11.596 22.2763 11.5566 22.3033 11.5226C22.3303 11.4886 22.364 11.4606 22.4023 11.4405C22.4406 11.4203 22.4827 11.4084 22.5257 11.4056C22.6196 11.3984 22.7134 11.3919 22.8072 11.386C22.8464 11.3836 22.8854 11.394 22.9184 11.4157C22.9513 11.4375 22.9764 11.4693 22.99 11.5066C23.056 11.6883 23.1368 11.8642 23.2317 12.0324C23.5339 12.5693 24.063 13.0028 24.6713 13.0653C25.3623 13.1361 26.0436 12.7084 26.4062 12.1105C26.4826 11.984 26.5745 11.8176 26.6649 11.6404C26.6861 11.5984 26.7197 11.5641 26.761 11.5421C26.8023 11.5202 26.8493 11.5117 26.8956 11.5178C27.0587 11.5393 27.222 11.5631 27.3854 11.5891C27.4589 11.6008 27.5402 11.6174 27.5842 11.678C27.6163 11.7335 27.6286 11.7984 27.619 11.862V11.8644Z"
        fill="#212121"
      />
      <path
        d="M27.8055 8.50998C27.7663 8.76551 27.6028 9.00033 27.3732 9.13071C27.1436 9.2611 26.8527 9.28425 26.6042 9.192C26.6042 9.192 26.6046 9.19266 26.6042 9.19256C26.5098 9.14488 26.5174 9.13021 26.46 9.05796C26.3761 8.9523 26.3189 8.82735 26.2875 8.69748C26.2604 8.58466 26.2542 8.46809 26.2523 8.35223C26.2505 8.24185 26.2532 8.12848 26.2952 8.02587C26.3214 7.96164 26.3625 7.90401 26.4079 7.8507C26.5993 7.6266 26.9113 7.47179 27.1997 7.55115C27.3774 7.60004 27.5193 7.72809 27.6462 7.85852C27.6997 7.91356 27.753 7.97149 27.782 8.04181C27.8084 8.10604 27.8128 8.17661 27.8152 8.24576C27.8183 8.33395 27.8187 8.42265 27.8055 8.50998Z"
        fill="#212121"
      />
      <defs>
        <filter
          id="filter0_d_920_221026"
          x="1.79759"
          y="1.40674"
          width="21.6309"
          height="25.1197"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.69777" />
          <feGaussianBlur stdDeviation="0.348885" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_920_221026"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_920_221026"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_920_221026"
          x="8.2097"
          y="1.40674"
          width="21.6309"
          height="25.1197"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.69777" />
          <feGaussianBlur stdDeviation="0.348885" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_920_221026"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_920_221026"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_920_221026"
          x="14.2097"
          y="1.40674"
          width="21.6309"
          height="25.1197"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.69777" />
          <feGaussianBlur stdDeviation="0.348885" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_920_221026"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_920_221026"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}
