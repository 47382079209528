import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BrainIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11.9939 3.88159V20.1174M14.9925 15.9982C14.9925 14.8942 15.8876 13.9991 16.9917 13.9991M8.99518 8.00177C8.99518 9.10585 8.10015 10.0009 6.99607 10.0009M8.99518 15.9982C8.99518 14.8942 8.10015 13.9991 6.99607 13.9991M14.9925 8.00177C14.9925 9.10585 15.8876 10.0009 16.9917 10.0009M12.6226 3.66069C12.2559 3.95735 11.7318 3.95735 11.3651 3.66069C10.5638 3.02117 9.49119 2.83541 8.52142 3.16819C7.55165 3.50096 6.81912 4.30614 6.57925 5.30296C6.49443 5.6908 6.19077 5.99329 5.8026 6.07662C4.66146 6.30823 3.71035 7.09241 3.26545 8.16847C2.82055 9.24452 2.94018 10.4714 3.58458 11.4412C3.81677 11.7812 3.81677 12.2288 3.58458 12.5687C2.94018 13.5386 2.82055 14.7655 3.26545 15.8415C3.71035 16.9176 4.66146 17.7018 5.8026 17.9334C6.19077 18.0167 6.49443 18.3192 6.57925 18.707C6.82043 19.703 7.55349 20.5068 8.52306 20.8385C9.49263 21.1702 10.5645 20.9838 11.3651 20.3443C11.7318 20.0477 12.2559 20.0477 12.6226 20.3443C13.4247 20.9838 14.498 21.169 15.4679 20.8351C16.4379 20.5012 17.1699 19.6947 17.4085 18.697C17.4933 18.3092 17.797 18.0067 18.1851 17.9234C19.3264 17.6916 20.2776 16.9072 20.7223 15.8309C21.167 14.7546 21.047 13.5275 20.4021 12.5578C20.1709 12.2182 20.1709 11.7718 20.4021 11.4323C21.047 10.4625 21.167 9.23545 20.7223 8.15914C20.2776 7.08282 19.3264 6.29836 18.1851 6.06662C17.797 5.9833 17.4933 5.68081 17.4085 5.29296C17.1673 4.29701 16.4342 3.49317 15.4647 3.16147C14.4951 2.82978 13.4233 3.01616 12.6226 3.65569V3.66069Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
