import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function WhatsAppIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.79801 14.1612C11.8092 16.1734 14.3565 17.455 15.8723 15.9464L16.2397 15.579C16.73 15.0897 16.6614 14.2791 16.0924 13.8841C15.7341 13.6343 15.3493 13.3662 14.9235 13.0673C14.4835 12.7582 13.8796 12.8063 13.4978 13.185L13.0823 13.5975C12.5675 13.271 12.0579 12.8522 11.585 12.3793L11.583 12.3773C11.1101 11.9044 10.6913 11.3947 10.3648 10.8799L10.7773 10.4644C11.157 10.0826 11.2041 9.47874 10.895 9.03865C10.5951 8.61288 10.327 8.22805 10.0783 7.86983C9.68321 7.3018 8.87261 7.23322 8.38338 7.72245L8.01595 8.08988C6.50734 9.60566 7.78887 12.1522 9.80002 14.1644M18.3113 5.61602C16.6267 3.93035 14.3863 3.00102 11.9995 3C7.0796 3 3.07676 7.0008 3.07574 11.9186C3.07369 13.4835 3.48411 15.0218 4.26605 16.3779L3 21L7.73054 19.7595C9.03957 20.4719 10.5052 20.8455 11.9954 20.8455H11.9995C16.9173 20.8455 20.9202 16.8436 20.9222 11.9258C20.9232 9.54313 19.996 7.30272 18.3113 5.61602Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
