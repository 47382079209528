import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M6 21V3.92999M20.5 14.02V4M6 14C6 14 6.875 13.273 9.5 13.273C12.125 13.273 13.875 15 16.5 15C19.125 15 20 14.023 20 14.023M6 3.932C6 3.932 6.875 3 9.5 3C12.125 3 13.875 4.727 16.5 4.727C19.125 4.727 20 4 20 4"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
