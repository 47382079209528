import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PillsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M10.2 10.2H3M14.142 13.242L17.958 17.058M15.841 3.65901C16.7197 4.53769 16.7197 5.96231 15.841 6.84099C14.9623 7.71967 13.5377 7.71967 12.659 6.84099C11.7804 5.96231 11.7804 4.53769 12.659 3.65901C13.5377 2.78033 14.9623 2.78033 15.841 3.65901ZM9.14559 7.65442C10.5515 9.06031 10.5515 11.3397 9.14559 12.7456C7.7397 14.1515 5.4603 14.1515 4.05442 12.7456C2.64853 11.3397 2.64853 9.0603 4.05442 7.65442C5.46031 6.24853 7.73971 6.24853 9.14559 7.65442ZM20.2035 14.8395L15.7395 19.3035C14.6775 20.3655 12.9567 20.3655 11.8947 19.3035C10.8327 18.2415 10.8327 16.5207 11.8947 15.4587L16.3587 10.9947C17.4207 9.9327 19.1415 9.9327 20.2035 10.9947C21.2655 12.0567 21.2655 13.7784 20.2035 14.8395Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
