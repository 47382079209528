import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MapDirectionSymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M20.9091 3L4 11.1818L12.5909 12L13.2727 21L20.9091 3Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
