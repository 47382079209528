import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlasterFourEdgesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 5.41026L9.27897 3.46969C8.28189 2.75845 6.91635 2.87234 6.04991 3.73878L3.73419 6.0545C2.86663 6.92206 2.75497 8.28983 3.46845 9.28691L5.41013 12.0001"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.41013 12L3.46845 14.7132C2.75497 15.7114 2.86663 17.0781 3.73419 17.9456L6.04991 20.2613C6.91635 21.1278 8.28189 21.2417 9.27897 20.5304L12 18.5899"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 18.5899L14.7132 20.5316C15.7114 21.245 17.0781 21.1334 17.9456 20.2658L20.2613 17.9501C21.1278 17.0836 21.2417 15.7181 20.5304 14.721L18.5899 12L20.5293 9.27897C21.2406 8.28189 21.1267 6.91635 20.2602 6.04991L17.9445 3.73419C17.0781 2.86663 15.7103 2.75497 14.7132 3.46845L12 5.41013"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.65032 15.4055C8.6425 15.4055 8.6358 15.4044 8.6291 15.401C8.6224 15.3977 8.61682 15.3943 8.61124 15.3888C8.60007 15.3787 8.59449 15.3642 8.59449 15.3497C8.59449 15.3419 8.5956 15.3352 8.59895 15.3285C8.6023 15.3218 8.60565 15.3162 8.61124 15.3106C8.61682 15.305 8.6224 15.3017 8.6291 15.2983C8.6492 15.2894 8.67488 15.295 8.69051 15.3106C8.69609 15.3162 8.69944 15.3218 8.70279 15.3285C8.70503 15.3352 8.70614 15.3419 8.70614 15.3497C8.70614 15.3642 8.70056 15.3787 8.68939 15.3888C8.68381 15.3943 8.67823 15.3977 8.67153 15.401C8.66483 15.4044 8.65813 15.4055 8.65032 15.4055V15.4055Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3497 8.70629C15.3351 8.70629 15.3206 8.7007 15.3106 8.68954C15.2994 8.67949 15.2938 8.66497 15.2938 8.65046C15.2938 8.64264 15.2949 8.63594 15.2983 8.62924C15.3016 8.62254 15.305 8.61696 15.3106 8.61138C15.3262 8.59575 15.3508 8.59016 15.372 8.5991C15.3787 8.60245 15.3843 8.6058 15.3899 8.61138C15.3954 8.61696 15.3988 8.62254 15.4021 8.62924C15.4044 8.63594 15.4055 8.64264 15.4055 8.65046C15.4055 8.66497 15.3999 8.67949 15.3887 8.68954C15.3832 8.69512 15.3776 8.69847 15.3709 8.70182C15.3642 8.70517 15.3575 8.70629 15.3497 8.70629V8.70629Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0395 11.9607C12.0613 11.9825 12.0613 12.0178 12.0395 12.0396C12.0177 12.0614 11.9824 12.0614 11.9606 12.0396C11.9388 12.0178 11.9388 11.9825 11.9606 11.9607C11.9824 11.9389 12.0177 11.9389 12.0395 11.9607"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.68979 11.9607C8.71159 11.9825 8.71159 12.0178 8.68979 12.0396C8.66799 12.0614 8.63264 12.0614 8.61084 12.0396C8.58904 12.0178 8.58904 11.9825 8.61084 11.9607C8.63264 11.9389 8.66799 11.9389 8.68979 11.9607"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3891 11.9607C15.4109 11.9825 15.4109 12.0178 15.3891 12.0396C15.3673 12.0614 15.332 12.0614 15.3102 12.0396C15.2884 12.0178 15.2884 11.9825 15.3102 11.9607C15.332 11.9389 15.3673 11.9389 15.3891 11.9607"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0395 15.3103C12.0613 15.3321 12.0613 15.3675 12.0395 15.3893C12.0177 15.4111 11.9824 15.4111 11.9606 15.3893C11.9388 15.3675 11.9388 15.3321 11.9606 15.3103C11.9824 15.2885 12.0177 15.2885 12.0395 15.3103"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0395 8.61108C12.0613 8.63289 12.0613 8.66823 12.0395 8.69004C12.0177 8.71184 11.9824 8.71184 11.9606 8.69004C11.9388 8.66823 11.9388 8.63289 11.9606 8.61108C11.9824 8.58928 12.0177 8.58928 12.0395 8.61108"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.68979 15.3103C8.71159 15.3321 8.71159 15.3675 8.68979 15.3893C8.66799 15.4111 8.63264 15.4111 8.61084 15.3893C8.58904 15.3675 8.58904 15.3321 8.61084 15.3103C8.63264 15.2885 8.66799 15.2885 8.68979 15.3103"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3891 8.61108C15.4109 8.63289 15.4109 8.66823 15.3891 8.69004C15.3673 8.71184 15.332 8.71184 15.3102 8.69004C15.2884 8.66823 15.2884 8.63289 15.3102 8.61108C15.332 8.58928 15.3673 8.58928 15.3891 8.61108"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
