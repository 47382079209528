import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlusInCircleSolidIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21ZM13 8.00003C13 7.44775 12.5522 7.00003 12 7.00003C11.4477 7.00003 11 7.44775 11 8.00003V11H8.00003C7.44775 11 7.00003 11.4477 7.00003 12C7.00003 12.5522 7.44775 13 8.00003 13H11V16C11 16.5523 11.4477 17 12 17C12.5522 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5522 17 12C17 11.4477 16.5523 11 16 11H13V8.00003Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
