import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FingerprintSymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.73234 20.299C7.48145 18.5138 6.74557 16.3419 6.74557 13.9968C6.74557 10.9599 9.20612 8.49842 12.2412 8.49842C15.2764 8.49842 17.7369 10.9599 17.7369 13.9968M18.0348 19.4806C17.9358 19.4861 17.8386 19.4953 17.7388 19.4953C14.7036 19.4953 12.2431 17.0338 12.2431 13.9968M13.3849 21C11.0499 19.5438 9.49479 16.9522 9.49479 13.9968C9.49479 12.4784 10.7255 11.2476 12.2431 11.2476C13.7606 11.2476 14.9914 12.4784 14.9914 13.9968C14.9914 15.5153 16.2221 16.7461 17.7397 16.7461C19.2572 16.7461 20.488 15.5153 20.488 13.9968C20.488 9.4414 16.7967 5.74921 12.244 5.74921C7.69131 5.74921 4.00003 9.4414 4.00003 13.9968C4.00003 15.0122 4.11366 16.001 4.3226 16.9541M19.9152 6.47684C18.0412 4.34803 15.3011 3 12.2422 3C9.18321 3 6.44316 4.34803 4.56911 6.47684"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
