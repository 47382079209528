import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LoudspeakerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 11.2745L10.694 15.4405C10.919 16.7935 9.876 18.0245 8.505 18.0245C7.42 18.0245 6.494 17.2405 6.316 16.1695L5.5 11.2745M11.8571 11.1197V4.36969M12.25 11.2745H4.375C2.511 11.2745 1 9.76354 1 7.89954C1 6.03555 2.511 4.52455 4.375 4.52455H12.25L17.251 1.19057C17.999 0.692566 19 1.22857 19 2.12757V13.6726C19 14.5716 17.999 15.1066 17.251 14.6086L12.25 11.2745Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
