import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3528 21V16.05H14.5881V21H20.9409V11.9091C20.9409 11.3479 20.7175 10.809 20.3205 10.4119L13.219 3.3105C12.805 2.8965 12.1348 2.8965 11.7219 3.3105L4.62046 10.4119C4.22341 10.809 4 11.3479 4 11.9091V21H10.3528Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
