import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CompassIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.63625 5.63625V5.63625C9.15125 2.12125 14.8493 2.12125 18.3643 5.63625V5.63625C21.8793 9.15125 21.8793 14.8493 18.3643 18.3643V18.3643C14.8493 21.8793 9.15125 21.8793 5.63625 18.3643V18.3643C2.12125 14.8493 2.12125 9.15125 5.63625 5.63625Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.90427 15.507L9.04027 9.82503C9.12027 9.42903 9.42927 9.12003 9.82527 9.04103L15.5073 7.90503C15.8573 7.83503 16.1653 8.14303 16.0953 8.49303L14.9593 14.175C14.8803 14.571 14.5703 14.88 14.1753 14.959L8.49327 16.095C8.14327 16.166 7.83427 15.857 7.90427 15.507Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9992 11.7505C11.8612 11.7505 11.7492 11.8625 11.7502 12.0005C11.7502 12.1385 11.8622 12.2505 12.0002 12.2505C12.1382 12.2505 12.2502 12.1385 12.2502 12.0005C12.2502 11.8625 12.1382 11.7505 11.9992 11.7505"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
