import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SmilingInverseFaceInRoundedSquareIcon(
  props: SvgIconProps
) {
  return (
    <SvgIcon
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.68918 7.91667V8.75M12.0225 7.91667V8.75M12.7117 17.5H8.00002C5.23835 17.5 3.00002 15.2617 3.00002 12.5V7.5C3.00002 4.73833 5.23835 2.5 8.00002 2.5H12.7108C15.4725 2.5 17.7108 4.73833 17.7108 7.5V12.5C17.7117 15.2617 15.4725 17.5 12.7117 17.5Z"
        stroke="#4E7FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2717 12.3434C13.2717 12.3434 12.1783 11.2501 10.355 11.2501C8.53165 11.2501 7.43832 12.3434 7.43832 12.3434"
        stroke="#4E7FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
