import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CardForwardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3 8H21M9.5 12H7M11 18H3M11 18L9 16M11 18L9 20M3 14V7C3 5.343 4.343 4 6 4H18C19.657 4 21 5.343 21 7V15C21 16.657 19.657 18 18 18H15"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
