import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DNAIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M7 3C7 12 17.8 12 17.8 21M17.8 3C17.8 12 7 12 7 21M7.04 3.9H17.76M8.14 7.5H16.66M17.76 20.1H7.04M16.6 16.5H8.14"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
