import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DotsVerticalIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11.997 17.502C11.7219 17.502 11.4968 17.727 11.4998 18.0022C11.4998 18.2773 11.7249 18.5024 12 18.5024C12.2751 18.5024 12.5002 18.2773 12.5002 18.0022C12.5002 17.727 12.2751 17.502 11.997 17.502"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.997 11.4995C11.7219 11.4995 11.4968 11.7246 11.4998 11.9997C11.4998 12.2748 11.7249 12.4999 12 12.4999C12.2751 12.4999 12.5002 12.2748 12.5002 11.9997C12.5002 11.7246 12.2751 11.4995 11.997 11.4995"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.997 5.49707C11.7219 5.49707 11.4968 5.72216 11.4998 5.99728C11.4998 6.27239 11.7249 6.49749 12 6.49749C12.2751 6.49749 12.5002 6.27239 12.5002 5.99728C12.5002 5.72216 12.2751 5.49707 11.997 5.49707"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
