import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BulletFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 16C10.5668 16 9 14.4332 9 12.5C9 10.5668 10.5668 9 12.5 9C14.4332 9 16 10.5668 16 12.5C16 14.4332 14.4332 16 12.5 16Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
