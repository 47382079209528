import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function KeyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.2095 9.18548C15.2095 8.9663 15.0316 8.78947 14.8124 8.78947C14.5932 8.79053 14.4153 8.96841 14.4153 9.18759C14.4153 9.40677 14.5932 9.58466 14.8124 9.5836C15.0316 9.5836 15.2095 9.40572 15.2095 9.18654M3 17.5005L9.05974 11.427C8.20631 9.20983 8.66373 6.60296 10.4479 4.81459C12.862 2.39514 16.7766 2.39514 19.1897 4.81459C21.6038 7.23403 21.6038 11.157 19.1897 13.5775C17.3886 15.3829 14.7542 15.8371 12.5285 14.9498L6.49205 21H3V17.5005Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
