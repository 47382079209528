import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlayInRoundedSquareIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.941 9.05801L14.823 11.354C15.314 11.644 15.314 12.355 14.823 12.645L10.941 14.941C10.441 15.237 9.80899 14.876 9.80899 14.295V9.70401C9.80899 9.12301 10.441 8.76201 10.941 9.05801Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
