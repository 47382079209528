import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function WheatInCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 21V7M15.5 8.667C13.567 8.667 12 10.234 12 12.167C12 10.234 10.433 8.667 8.5 8.667M15.5 12.9342C13.567 12.9342 12 14.5012 12 16.4342C12 14.5012 10.433 12.9342 8.5 12.9342M15.5 7.5V14.5C15.5 16.433 13.933 18 12 18C10.067 18 8.5 16.433 8.5 14.5V7.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
