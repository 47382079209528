import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HandDrawnCircleOutlineIcon(props: SvgIconProps) {
  const strokeWidth = props.strokeWidth || 1;
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M22.9999 13.6133C22.3355 18.8489 18.411 23 12.2791 23C9.46457 23 6.61345 22.1475 4.49031 20.552C2.39024 18.9737 1 16.6747 1 13.6744C1 7.63947 2.65336 4.5931 4.84451 2.98672C7.10678 1.32821 10.193 1 13.5349 1C16.4922 1 18.8257 2.52145 20.4582 4.89045C22.0936 7.26373 22.9885 10.451 22.9999 13.6133Z"
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
        />
      </g>
    </SvgIcon>
  );
}
