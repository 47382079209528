import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DoctorBookingConfirmationIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="122"
      height="138"
      viewBox="0 0 122 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M120.73 59.3825C117.697 38.9904 105.026 20.2514 87.2377 9.84668C69.4489 -0.558031 46.9102 -2.4056 27.6637 4.9563C27.6637 4.9563 27.6961 4.90361 27.6637 4.91172C20.3506 8.71624 20.9377 9.88718 16.4916 15.6527C9.9883 24.0843 5.55831 34.0555 3.12871 44.4197C1.02709 53.4225 0.541176 62.7251 0.399449 71.9711C0.261771 80.7794 0.468272 89.8269 3.72395 98.0153C5.74862 103.141 8.93546 107.739 12.4543 111.994C27.279 129.878 51.4576 142.231 73.7979 135.898C87.5657 131.997 98.5597 121.778 108.387 111.37C112.534 106.978 116.664 102.359 118.916 96.7431C120.94 91.6177 121.301 85.9859 121.487 80.4675C121.734 73.4338 121.766 66.3554 120.73 59.3825Z"
        fill="#D1DBF2"
      />
      <path
        d="M25.0461 47.7202C25.0471 47.8067 25.0481 47.8905 25.0481 47.9708L25.0481 95.5116C25.0481 99.4808 27.4106 99.355 30.7288 99.1782C31.4655 99.139 32.2494 99.0972 33.0649 99.0972C34.8176 99.0972 37.4145 99.0662 40.2978 98.9839L58.9861 98.691C66.8252 98.691 72.4586 98.8425 76.8454 98.9604C79.6424 99.0356 81.9326 99.0972 83.9646 99.0972C90.9424 99.0972 90.8899 98.0993 90.7457 95.3629C90.7229 94.9307 90.6979 94.4552 90.6979 93.9333C90.6979 92.3401 90.6223 90.0907 90.534 87.4653C90.4104 83.7864 90.262 79.3693 90.262 74.9853C90.262 73.1625 90.3845 70.1208 90.4997 67.2631C90.6017 64.7313 90.6979 62.3439 90.6979 61.0765L90.6979 47.9708C90.6979 43.8338 87.9413 44.0781 84.0676 44.4214L83.9646 44.4306L62.4985 44.4306C57.0523 44.4306 49.0191 44.2241 42.1258 44.0469C36.9456 43.9138 32.4092 43.7972 30.0981 43.7972C25.0007 43.7972 25.0284 46.195 25.0461 47.7202Z"
        fill="white"
      />
      <path
        d="M97.6053 89.0749C97.7271 91.3874 97.7835 92.4584 93.5863 92.7347C93.5699 92.3968 93.556 92.0345 93.556 91.6467C93.556 90.0535 93.4804 87.8041 93.3922 85.1787C93.2685 81.4998 93.1201 77.0827 93.1201 72.6987C93.1201 70.8759 93.2427 67.8342 93.3578 64.9765C93.4598 62.4449 93.556 60.0573 93.556 58.7899L93.556 45.6842C93.556 41.5472 90.7994 41.7915 86.9257 42.1348L86.8227 42.144L65.3566 42.144C59.9106 42.144 51.8777 41.9375 44.9845 41.7604C39.8043 41.6272 35.2674 41.5106 32.9562 41.5106C32.5791 41.5106 32.23 41.5237 31.9069 41.5487L31.9057 41.4322C31.888 39.907 31.8602 37.5092 36.9576 37.5092C39.2688 37.5092 43.8052 37.6258 48.9853 37.7589C55.8785 37.9361 63.912 38.1425 69.3581 38.1425L90.8241 38.1425L90.9271 38.1334C94.8008 37.7901 97.5574 37.5458 97.5574 41.6828L97.5574 54.7884C97.5574 56.0559 97.4612 58.4431 97.3592 60.9747C97.2441 63.8325 97.1215 66.8745 97.1215 68.6973C97.1215 73.0813 97.27 77.4984 97.3936 81.1773C97.4818 83.8027 97.5574 86.0521 97.5574 87.6453C97.5574 88.1672 97.5825 88.6427 97.6053 89.0749Z"
        fill="white"
      />
      <path
        d="M34.1105 58.0927C34.1105 56.7642 36.1455 56.8637 36.4445 56.8637C37.7232 56.9134 38.6242 56.8239 39.1423 56.8637C39.79 56.9134 45.2262 56.7642 45.824 56.7642C46.3023 56.7642 47.1711 56.8637 47.1711 58.3748L47.1711 59.8492C47.1213 61.1928 47.1711 67.5443 47.1711 68.3887C47.1711 69.8317 45.5746 69.6532 45.3252 69.6532C43.6857 69.6532 42.7791 69.8317 40.785 69.8317H35.4467C34.1105 69.8317 34.0773 68.4776 34.1105 67.9966L34.1105 61.5261V58.0927Z"
        fill="#D1DBF2"
      />
      <path
        d="M34.1105 74.79C34.1105 73.4614 36.1455 73.5609 36.4445 73.5609C37.7232 73.6107 38.6242 73.5211 39.1423 73.5609C39.79 73.6107 45.2262 73.4614 45.824 73.4614C46.3023 73.4614 47.1711 73.5609 47.1711 75.072L47.1711 76.5465C47.1213 77.89 47.1711 84.2415 47.1711 85.086C47.1711 86.529 45.5746 86.3505 45.3252 86.3505C43.6857 86.3505 42.7791 86.529 40.785 86.529H35.4467C34.1105 86.529 34.0773 85.1748 34.1105 84.6938L34.1105 78.2234V74.79Z"
        fill="#D1DBF2"
      />
      <path
        d="M50.8097 58.0927C50.8097 56.7642 52.8448 56.8637 53.1437 56.8637C54.4224 56.9134 55.3234 56.8239 55.8416 56.8637C56.4892 56.9134 61.9254 56.7642 62.5233 56.7642C63.0015 56.7642 63.8703 56.8637 63.8703 58.3748L63.8703 59.8492C63.8205 61.1928 63.8703 67.5443 63.8703 68.3887C63.8703 69.8317 62.2738 69.6532 62.0244 69.6532C60.3849 69.6532 59.4783 69.8317 57.4842 69.8317H52.1459C50.8097 69.8317 50.7765 68.4776 50.8097 67.9966L50.8097 61.5261V58.0927Z"
        fill="#D1DBF2"
      />
      <path
        d="M50.8097 74.79C50.8097 73.4614 52.8448 73.5609 53.1437 73.5609C54.4224 73.6107 55.3234 73.5211 55.8416 73.5609C56.4892 73.6107 61.9254 73.4614 62.5233 73.4614C63.0015 73.4614 63.8703 73.5609 63.8703 75.072L63.8703 76.5465C63.8205 77.89 63.8703 84.2415 63.8703 85.086C63.8703 86.529 62.2738 86.3505 62.0244 86.3505C60.3849 86.3505 59.4783 86.529 57.4842 86.529H52.1459C50.8097 86.529 50.7765 85.1748 50.8097 84.6938L50.8097 78.2234V74.79Z"
        fill="#D1DBF2"
      />
      <path
        d="M67.506 58.0927C67.506 56.7642 69.541 56.8637 69.84 56.8637C71.1187 56.9134 72.0197 56.8239 72.5378 56.8637C73.1855 56.9134 78.6217 56.7642 79.2195 56.7642C79.6978 56.7642 80.5666 56.8637 80.5666 58.3748L80.5666 59.8492C80.5168 61.1928 80.5666 67.5443 80.5666 68.3887C80.5666 69.8317 78.9701 69.6532 78.7207 69.6532C77.0812 69.6532 76.1746 69.8317 74.1805 69.8317H68.8422C67.506 69.8317 67.4728 68.4776 67.506 67.9966L67.506 61.5261V58.0927Z"
        fill="#D1DBF2"
      />
      <path
        d="M67.506 74.79C67.506 73.4614 69.541 73.5609 69.84 73.5609C71.1187 73.6107 72.0197 73.5211 72.5378 73.5609C73.1855 73.6107 78.6217 73.4614 79.2195 73.4614C79.6978 73.4614 80.5666 73.5609 80.5666 75.072L80.5666 76.5465C80.5168 77.89 80.5666 84.2415 80.5666 85.086C80.5666 86.529 78.9701 86.3505 78.7207 86.3505C77.0812 86.3505 76.1746 86.529 74.1805 86.529H68.8422C67.506 86.529 67.4728 85.1748 67.506 84.6938L67.506 78.2234V74.79Z"
        fill="#D1DBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.8068 60.8747C77.0745 61.0329 77.512 61.4427 77.1201 61.8162C76.7392 62.1792 76.2323 62.7849 75.9257 63.1512C75.8381 63.256 75.7668 63.3412 75.7195 63.3956C75.5068 63.64 73.1282 66.3026 72.6988 66.7612C72.6581 66.8385 72.5236 66.9248 72.2711 66.8124C72.259 66.807 72.2472 66.8007 72.2356 66.7937C72.197 66.7829 72.1691 66.7577 72.1374 66.7251C71.8116 66.3899 71.3119 65.8244 70.988 65.4579C70.8766 65.3318 70.786 65.2293 70.7304 65.1678C70.6678 65.0984 70.5871 64.9999 70.4921 64.8839C70.2579 64.5978 69.9365 64.2051 69.5819 63.8795C69.1832 63.5133 69.613 63.0955 69.8777 62.9323C69.9629 62.8734 70.4487 62.6583 70.5218 62.7161C70.9072 62.9611 71.7717 64.0116 71.9052 64.1808C71.9634 64.2544 72.219 64.561 72.5012 64.8993C72.7967 64.5316 73.0742 64.1862 73.1344 64.1069C73.2647 63.9353 75.778 60.9226 76.1587 60.6705C76.2307 60.6114 76.7204 60.8174 76.8068 60.8747Z"
        fill="#4E7FFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1975 64.9642C84.1975 59.2857 81.0123 53.3823 75.3338 53.3823C69.6553 53.3823 63.8701 54.446 63.8701 64.9642C63.8701 70.6427 69.1883 73.7097 74.2702 73.7097C79.9487 73.7097 83.6066 69.8097 84.1975 64.9642ZM74.2702 71.8188C78.8665 71.8188 81.7796 68.7746 82.306 64.8479C82.2845 62.3691 81.5746 59.9113 80.338 58.1167C79.1076 56.3311 77.4263 55.2732 75.3338 55.2732C72.514 55.2732 70.2107 55.5661 68.5905 56.7539C67.1048 57.8432 65.761 60.0348 65.761 64.9642C65.761 67.1378 66.7511 68.7904 68.3005 69.9547C69.8934 71.1519 72.08 71.8188 74.2702 71.8188Z"
        fill="#4E7FFF"
      />
    </SvgIcon>
  );
}
