import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatCrossIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="123"
      height="138"
      viewBox="0 0 123 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M121.23 58.8825C118.197 38.4904 105.526 19.7514 87.7377 9.34668C69.9489 -1.05803 47.4102 -2.9056 28.1637 4.4563C28.1637 4.4563 28.1961 4.40361 28.1637 4.41172C20.8506 8.21624 21.4377 9.38718 16.9916 15.1527C10.4883 23.5843 6.05831 33.5555 3.62871 43.9197C1.52709 52.9225 1.04118 62.2251 0.899449 71.4711C0.761771 80.2794 0.968272 89.3269 4.22395 97.5153C6.24862 102.641 9.43546 107.239 12.9543 111.494C27.779 129.378 51.9576 141.731 74.2979 135.398C88.0657 131.497 99.0597 121.278 108.887 110.87C113.034 106.478 117.164 101.859 119.416 96.2431C121.44 91.1177 121.801 85.4859 121.987 79.9675C122.234 72.9338 122.266 65.8554 121.23 58.8825Z"
        fill="#D1DBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3314 44.4846C25.3314 39.9478 26.2249 39.9492 27.5746 39.9513L27.662 39.9513C30.6171 40.0758 35.2369 40.151 39.4476 40.2196C43.7605 40.2898 47.6444 40.3531 48.8708 40.4551C50.9934 40.6315 66.4995 40.3135 76.5567 40.1072C80.8552 40.0191 84.1583 39.9513 84.9957 39.9513C87.2331 39.9513 87.4817 43.9809 87.3263 45.9957V55.566C87.2714 57.17 87.2423 59.2088 87.23 61.4442C85.9656 61.0546 84.6091 60.8446 83.1638 60.8446C72.8246 60.8446 62.2912 62.7812 62.2912 81.9324C62.2912 83.3621 62.4764 84.701 62.82 85.9467C60.7032 86.0034 58.6009 86.0397 56.5553 86.0397H48.5736C45.1382 92.8171 39.1343 94.2361 36.0165 93.9533C37.8921 91.1903 38.7639 88.4555 39.059 86.0397H26.9628C25.2848 86.0397 25.176 81.1707 25.3314 78.7361V44.4846Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9765 98.2631C92.3152 98.2631 98.3308 91.8493 99.3026 83.8807C99.3026 74.542 94.0644 64.8336 84.7257 64.8336C75.3871 64.8336 65.873 66.5828 65.873 83.8807C65.873 93.2193 74.6191 98.2631 82.9765 98.2631ZM88.7729 76.4856C89.4301 75.8592 88.6964 75.1719 88.2474 74.9066C88.1026 74.8104 87.2812 74.4649 87.1605 74.5642C86.8325 74.7813 84.5627 77.2182 82.4026 79.5766C80.2425 77.2182 77.9727 74.7813 77.6446 74.5642C77.5239 74.4649 76.7026 74.8104 76.5578 74.9066C76.1088 75.1719 75.375 75.8592 76.0322 76.4856C76.5962 77.0231 79.1082 79.6614 80.7434 81.3956C79.4096 82.864 78.3797 84.0123 78.3032 84.113C78.2446 84.1903 78.0549 84.4141 77.8062 84.7076L77.8061 84.7077C77.1282 85.5077 76.0118 86.8251 75.9158 87.101C75.7932 87.4531 75.7927 87.4446 75.7927 87.4446C75.7873 87.9408 76.3438 88.4229 76.8554 88.6507C77.2789 88.8392 77.5045 88.6945 77.5727 88.5648C78.2146 87.8793 81.4533 84.2575 82.4026 83.1876C83.3519 84.2575 86.5905 87.8793 87.2324 88.5648C87.3007 88.6945 87.5262 88.8392 87.9497 88.6507C88.4614 88.4229 89.0179 87.9408 89.0125 87.4446C89.0125 87.4446 89.0119 87.4531 88.8894 87.101C88.7934 86.8251 87.677 85.5077 86.9991 84.7077L86.9988 84.7074C86.7502 84.414 86.5606 84.1902 86.5019 84.113C86.4254 84.0123 85.3956 82.864 84.0618 81.3956C85.697 79.6614 88.209 77.0231 88.7729 76.4856Z"
        fill="#4E7FFF"
      />
    </SvgIcon>
  );
}
