import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PulseIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 10H14.309C14.12 10 13.946 10.107 13.862 10.276L12.447 13.105C12.263 13.474 11.737 13.474 11.553 13.105L8.43 6.861C8.249 6.499 7.735 6.491 7.543 6.847L5.984 9.737C5.896 9.899 5.727 10 5.544 10H1M1.523 7C2.76 3.507 6.083 1 10 1C14.971 1 19 5.029 19 10C19 14.971 14.971 19 10 19C6.083 19 2.76 16.493 1.523 13"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
