import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function WarningCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M18.0016 18.8576V16.333"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0009 20.8865C17.9078 20.8865 17.8321 20.9621 17.8328 21.0553C17.8328 21.1484 17.9084 21.224 18.0016 21.224C18.0947 21.224 18.1703 21.1484 18.1703 21.0553C18.1703 20.9621 18.0947 20.8865 18.0009 20.8865"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3698 12.7954L24.5399 21.8435C25.1407 22.8952 24.3813 24.2041 23.1703 24.2041H12.8301C11.6184 24.2041 10.859 22.8952 11.4605 21.8435L16.6305 12.7954C17.236 11.7349 18.7643 11.7349 19.3698 12.7954Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
