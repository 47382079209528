import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FiveStarRatingIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="64"
      height="11"
      viewBox="0 0 64 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41115 0.9198L6.9758 4.04528L10.4756 4.5496L7.94338 6.98093L8.54099 10.4157L5.41115 8.79322L2.28131 10.4157L2.87891 6.98093L0.34668 4.5496L3.84596 4.04528L5.41115 0.9198Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.633059"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7051 0.9198L20.2697 4.04528L23.7696 4.5496L21.2373 6.98093L21.8349 10.4157L18.7051 8.79322L15.5753 10.4157L16.1729 6.98093L13.6406 4.5496L17.1399 4.04528L18.7051 0.9198Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.633059"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 0.9198L33.5647 4.04528L37.0645 4.5496L34.5323 6.98093L35.1299 10.4157L32 8.79322L28.8702 10.4157L29.4678 6.98093L26.9355 4.5496L30.4348 4.04528L32 0.9198Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.633059"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.294 0.9198L46.8586 4.04528L50.3584 4.5496L47.8262 6.98093L48.4238 10.4157L45.294 8.79322L42.1641 10.4157L42.7617 6.98093L40.2295 4.5496L43.7288 4.04528L45.294 0.9198Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.633059"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5879 0.9198L60.1526 4.04528L63.6524 4.5496L61.1201 6.98093L61.7177 10.4157L58.5879 8.79322L55.4581 10.4157L56.0557 6.98093L53.5234 4.5496L57.0227 4.04528L58.5879 0.9198Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.633059"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
