import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EnvelopeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3.119 6.03002L10.813 11.533C11.508 12.03 12.442 12.031 13.138 11.535L20.876 6.01602M4.636 5H19.363C20.267 5 21 5.733 21 6.637V17.273C21 18.177 20.267 18.909 19.364 18.909H4.636C3.733 18.91 3 18.177 3 17.273V6.637C3 5.733 3.733 5 4.636 5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
