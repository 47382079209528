import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LifebuoyRingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M18.8943 6.16636L15.3588 9.70189M9.70191 15.3587L6.16638 18.8943M17.6569 19.0711L14.1213 15.5355M8.46447 9.87869L4.92894 6.34315M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM14.8284 14.8284C13.2663 16.3905 10.7337 16.3905 9.17157 14.8284C7.60948 13.2663 7.60948 10.7337 9.17157 9.17159C10.7337 7.60949 13.2663 7.60949 14.8284 9.17159C16.3905 10.7337 16.3905 13.2663 14.8284 14.8284Z"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
}
