import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EyeInfectionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M17.3668 25.2375C12.2557 25.2375 7.45896 22.5047 3.86019 17.5408C3.76535 17.4085 3.76535 17.2288 3.86019 17.0966C7.45896 12.1327 12.2557 9.3999 17.3668 9.3999C22.478 9.3999 27.2747 12.1327 30.8734 17.0966C30.9683 17.2288 30.9683 17.4085 30.8734 17.5408C27.2747 22.5047 22.478 25.2375 17.3668 25.2375ZM4.63634 17.3187C8.0704 21.9407 12.5801 24.4813 17.3668 24.4813C22.1535 24.4813 26.6632 21.9407 30.0973 17.3187C26.6632 12.6967 22.1535 10.1561 17.3668 10.1561C12.5801 10.1561 8.0704 12.6967 4.63634 17.3187Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M7.65104 13.8025C7.53873 13.8025 7.42642 13.7526 7.35155 13.6553L5.70191 11.5165C5.57463 11.3518 5.60459 11.1122 5.7718 10.9849C5.93651 10.8576 6.1761 10.8876 6.30338 11.0548L7.95302 13.1936C8.0803 13.3583 8.05034 13.5979 7.88313 13.7252C7.81325 13.7776 7.73339 13.805 7.65104 13.805V13.8025Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M12.3222 11.0549C12.1725 11.0549 12.0302 10.9651 11.9703 10.8153L10.9696 8.30718C10.8922 8.11251 10.987 7.89289 11.1817 7.81553C11.3764 7.73816 11.596 7.833 11.6733 8.02766L12.6741 10.5358C12.7515 10.7305 12.6567 10.9501 12.462 11.0275C12.4171 11.0449 12.3697 11.0549 12.3222 11.0549Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M22.5336 11.0549C22.4862 11.0549 22.4387 11.0474 22.3938 11.0275C22.1992 10.9501 22.1043 10.7305 22.1817 10.5358L23.1824 8.02766C23.2598 7.833 23.4794 7.73816 23.6741 7.81553C23.8688 7.89289 23.9636 8.11251 23.8862 8.30718L22.8855 10.8153C22.8256 10.9626 22.6833 11.0549 22.5336 11.0549Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M17.378 9.95652C17.1709 9.95652 17.0012 9.78931 16.9987 9.58216L16.9737 6.88184C16.9737 6.6722 17.1409 6.5025 17.348 6.5H17.3505C17.5577 6.5 17.7274 6.66721 17.7299 6.87435L17.7548 9.57468C17.7548 9.78431 17.5876 9.95402 17.3805 9.95652H17.378Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M27.0545 13.8023C26.9747 13.8023 26.8923 13.7774 26.8224 13.7225C26.6577 13.5952 26.6253 13.3581 26.7526 13.1909L28.4022 11.0521C28.5295 10.8874 28.7666 10.8549 28.9338 10.9822C29.0985 11.1095 29.1309 11.3466 29.0037 11.5138L27.354 13.6526C27.2792 13.7499 27.1668 13.7998 27.0545 13.7998V13.8023Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M28.7025 24.104C28.5902 24.104 28.4778 24.0541 28.403 23.9568L26.7533 21.818C26.626 21.6533 26.656 21.4137 26.8232 21.2864C26.9879 21.1591 27.2275 21.1891 27.3548 21.3563L29.0044 23.4951C29.1317 23.6598 29.1018 23.8994 28.9346 24.0267C28.8647 24.0791 28.7848 24.1065 28.7025 24.1065V24.104Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M23.3792 27.2207C23.2294 27.2207 23.0872 27.1308 23.0273 26.9811L22.0265 24.4729C21.9492 24.2783 22.044 24.0587 22.2387 23.9813C22.4333 23.9039 22.6529 23.9988 22.7303 24.1934L23.7311 26.7016C23.8084 26.8963 23.7136 27.1159 23.5189 27.1932C23.474 27.2107 23.4266 27.2207 23.3792 27.2207Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M11.1697 27.2207C11.1223 27.2207 11.0748 27.2132 11.0299 27.1932C10.8352 27.1159 10.7404 26.8963 10.8178 26.7016L11.8185 24.1934C11.8959 23.9988 12.1155 23.9039 12.3102 23.9813C12.5048 24.0587 12.5997 24.2783 12.5223 24.4729L11.5216 26.9811C11.4617 27.1284 11.3194 27.2207 11.1697 27.2207Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M17.351 28.5124C17.1439 28.5124 16.9742 28.3452 16.9717 28.1381L16.9467 25.4377C16.9467 25.2281 17.1139 25.0584 17.3211 25.0559C17.5232 25.0584 17.7004 25.2231 17.7029 25.4303L17.7279 28.1306C17.7279 28.3402 17.5606 28.5099 17.3535 28.5124H17.351Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M6.00314 24.1043C5.92328 24.1043 5.84092 24.0794 5.77105 24.0245C5.60633 23.8972 5.57388 23.6601 5.70116 23.4929L7.3508 21.3541C7.47808 21.1894 7.71517 21.1569 7.88238 21.2842C8.0471 21.4115 8.07955 21.6486 7.95227 21.8158L6.30263 23.9546C6.22776 24.0519 6.11545 24.1018 6.00314 24.1018V24.1043Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M17.3638 23.6336C13.8823 23.6336 11.0473 20.801 11.0473 17.3171C11.0473 13.8331 13.8799 11.0005 17.3638 11.0005C20.8478 11.0005 23.6804 13.8331 23.6804 17.3171C23.6804 20.801 20.8478 23.6336 17.3638 23.6336ZM17.3638 11.7517C14.2941 11.7517 11.796 14.2499 11.796 17.3195C11.796 20.3892 14.2941 22.8874 17.3638 22.8874C20.4335 22.8874 22.9317 20.3892 22.9317 17.3195C22.9317 14.2499 20.4335 11.7517 17.3638 11.7517Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M17.3626 13.5044C16.9333 13.5044 16.5191 13.5793 16.1347 13.7115C16.5216 14.0884 16.7636 14.6125 16.7636 15.194C16.7636 16.337 15.8377 17.2629 14.6947 17.2629C14.2779 17.2629 13.8911 17.1381 13.5667 16.9285C13.5542 17.0582 13.5467 17.188 13.5467 17.3203C13.5467 19.4266 15.2537 21.1362 17.3626 21.1362C19.4714 21.1362 21.1785 19.4291 21.1785 17.3203C21.1785 15.2114 19.4714 13.5044 17.3626 13.5044Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
