import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MagnifyingGlassIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21 21L16.5562 16.5562M18.8828 10.9414C18.8828 15.3273 15.3273 18.8828 10.9414 18.8828C6.55548 18.8828 3 15.3273 3 10.9414C3 6.55548 6.55548 3 10.9414 3C15.3273 3 18.8828 6.55548 18.8828 10.9414Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
