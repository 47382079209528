import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LungsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13.44 4V8.14C13.44 9.3325 14.4075 10.3 15.6 10.3M10.56 4V8.14C10.56 9.3325 9.5925 10.3 8.4 10.3M15.6 13C14.1654 13 12.8892 12.3673 12 11.3782C11.1108 12.3673 9.8346 13 8.4 13M13.35 15.6964V16.9276C13.35 17.95 13.9278 18.8842 14.8422 19.3414L15.7899 19.8148C18.1839 21.0118 21 19.2721 21 16.5964V11.0659C21 10.2487 20.7219 9.4558 20.2107 8.8177L18.6078 6.8152C17.8086 5.8153 16.6035 5.6047 15.6 5.9629M10.65 15.6964V16.9276C10.65 17.95 10.0722 18.8842 9.1578 19.3414L8.2101 19.8148C5.8161 21.0118 3 19.2721 3 16.5964V11.0659C3 10.2487 3.2781 9.4558 3.7893 8.8177L5.3922 6.8152C6.1914 5.8153 7.3965 5.6047 8.4 5.9629"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
