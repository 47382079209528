import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatBubbleWithTimerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="122"
      height="138"
      viewBox="0 0 122 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M120.844 59.3243C117.805 38.8932 105.111 20.1183 87.2877 9.69368C69.4649 -0.730955 46.8831 -2.58206 27.5997 4.79393C27.5997 4.79393 27.6322 4.74114 27.5997 4.74926C20.2726 8.56107 20.8609 9.73425 16.4062 15.5108C9.8905 23.9585 5.45203 33.9488 3.01778 44.3328C0.912142 53.3529 0.425294 62.6733 0.283296 71.937C0.145355 80.7622 0.352251 89.8269 3.61416 98.031C5.64271 103.166 8.83564 107.774 12.3613 112.036C27.2143 129.954 51.4392 142.332 73.8222 135.987C87.6164 132.078 98.6314 121.84 108.478 111.411C112.632 107.01 116.771 102.383 119.026 96.7564C121.055 91.6212 121.416 85.9786 121.603 80.4496C121.85 73.4024 121.883 66.3106 120.844 59.3243Z"
        fill="#D1DBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6933 37.8604C22.3177 37.8583 21.4071 37.857 21.4071 42.4904V61.7816V77.4718C21.2487 79.9582 21.3596 84.931 23.0698 84.931H53.2302C55.7254 84.931 58.3035 84.8779 60.8905 84.7995C60.8609 84.3848 60.8457 83.9626 60.8457 83.5331C60.8457 62.3622 72.49 60.2213 83.9196 60.2213C84.1116 60.2213 84.3023 60.2247 84.4914 60.2313C84.5025 57.784 84.5322 55.546 84.5917 53.8079V44.0337C84.75 41.976 84.4967 37.8605 82.2163 37.8605C81.6202 37.8605 81.0565 37.9333 80.2462 38.038C77.1822 38.4338 70.5932 39.2849 45.3982 38.375C44.521 38.3433 42.319 38.3018 39.5698 38.25C34.5553 38.1555 27.7202 38.0267 23.7824 37.8605C23.7525 37.8605 23.7228 37.8605 23.6933 37.8604Z"
        fill="currentColor"
      />
      <path
        d="M53.2284 76.1898C49.8791 93.3929 37.8775 96.6787 32.2954 96.1713C38.6895 86.7324 36.9895 77.5219 35.3402 74.0965C37.776 73.6398 48.2806 75.3017 53.2284 76.1898Z"
        fill="currentColor"
      />
      <path
        d="M102.23 85.6798C101.165 94.412 94.5735 101.44 84.34 101.44C75.1818 101.44 65.5977 95.9132 65.5977 85.6798C65.5977 66.7244 76.0233 64.8076 86.2568 64.8076C96.4903 64.8076 102.23 75.4463 102.23 85.6798Z"
        fill="white"
      />
      <path
        d="M87.1546 84.0463C86.998 85.331 86.0281 86.3651 84.5226 86.3651C83.1752 86.3651 81.7651 85.5519 81.7651 84.0463C81.7651 81.2576 83.299 80.9756 84.8046 80.9756C86.3101 80.9756 87.1546 82.5408 87.1546 84.0463Z"
        fill="currentColor"
      />
      <path
        d="M97.9545 91.7815C97.7948 92.1256 97.6119 92.4583 97.4069 92.7775C97.0226 93.3553 95.6756 92.5729 94.2219 91.8259C93.2049 91.3042 93.5048 91.6492 92.5507 91.0219C92.5507 91.0219 90.033 89.3684 87.5152 87.7148C87.4677 87.6912 87.4254 87.6584 87.3907 87.6184C87.3561 87.5783 87.3298 87.5318 87.3135 87.4816C87.3114 87.4294 87.3202 87.3774 87.3394 87.3291C87.3585 87.2807 87.3876 87.2369 87.4247 87.2004C87.8502 86.6451 88.2822 85.1761 88.9098 85.5187C92.3999 87.1967 92.5408 87.9515 97.3216 90.1164C97.4742 90.1845 97.612 90.2819 97.7271 90.4031C97.8421 90.5242 97.9321 90.6666 97.9918 90.822C98.0514 90.9775 98.0796 91.1428 98.0747 91.3085C98.0697 91.4742 98.0317 91.6369 97.9629 91.7871L97.9545 91.7815Z"
        fill="currentColor"
      />
      <path
        d="M92.221 69.5595C92.5513 69.7395 92.869 69.9418 93.1717 70.1649C93.7191 70.5821 92.8589 71.8654 92.0269 73.2565C91.4457 74.2297 90.4186 75.7715 89.7369 76.6757C87.9399 79.0621 89.2913 77.3617 87.4943 79.7481C87.4679 79.7936 87.4328 79.8334 87.3909 79.8652C87.349 79.8969 87.3013 79.92 87.2506 79.9329C87.1988 79.9316 87.148 79.9196 87.1013 79.8976C87.0546 79.8755 87.0131 79.844 86.9794 79.8049C86.457 79.3484 85.0307 78.8272 85.4097 78.2278C88.6806 73.0641 88.0852 74.6732 90.5327 70.0798C90.6098 69.933 90.715 69.8028 90.8422 69.6967C90.9694 69.5906 91.1161 69.5106 91.2738 69.4614C91.4314 69.4123 91.5968 69.395 91.7605 69.4104C91.9242 69.4259 92.0827 69.4738 92.2271 69.5515L92.221 69.5595Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
