import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SmilingFaceInRoundedSquareIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.82703 9.5V10.5M13.827 9.5V10.5M8.32703 14.188C8.32703 14.188 9.63903 15.5 11.827 15.5C14.015 15.5 15.327 14.188 15.327 14.188M14.654 21H9.00003C5.68603 21 3.00003 18.314 3.00003 15V9C3.00003 5.686 5.68603 3 9.00003 3H14.653C17.967 3 20.653 5.686 20.653 9V15C20.654 18.314 17.967 21 14.654 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
