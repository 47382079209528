import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CheckInShieldIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.4876 9.89535L11.6584 13.7245L9.36086 11.427M20.3379 10.9747C20.3379 15.6116 16.8528 19.9472 12.1689 21C7.48507 19.9472 4 15.6116 4 10.9747V7.33235C4 6.5032 4.50137 5.75574 5.26925 5.44226L10.3748 3.35305C11.5246 2.88232 12.8133 2.88232 13.963 3.35305L19.0686 5.44226C19.8365 5.75676 20.3379 6.5032 20.3379 7.33235V10.9747Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
