import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CheckInBadgeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.7989 10.601L11.298 14.1019L9.19908 12.002M9.48207 19.7408C9.77706 19.7018 10.0751 19.7818 10.31 19.9628L11.393 20.7938C11.751 21.0687 12.249 21.0687 12.606 20.7938L13.73 19.9308C13.9399 19.7698 14.2049 19.6988 14.4669 19.7338L15.8729 19.9188C16.3199 19.9778 16.7509 19.7288 16.9239 19.3118L17.4649 18.0038C17.5658 17.7588 17.7598 17.5648 18.0048 17.4638L19.3128 16.9229C19.7298 16.7509 19.9788 16.3189 19.9198 15.8719L19.7418 14.5169C19.7028 14.2219 19.7828 13.9239 19.9638 13.689L20.7948 12.606C21.0698 12.248 21.0698 11.75 20.7948 11.393L19.9318 10.269C19.7708 10.0591 19.6998 9.79406 19.7348 9.53207L19.9198 8.12611C19.9788 7.67912 19.7298 7.24813 19.3128 7.07514L18.0048 6.53415C17.7598 6.43315 17.5658 6.23916 17.4649 5.99417L16.9239 4.6862C16.7519 4.26921 16.3199 4.02022 15.8729 4.07922L14.4669 4.26421C14.2049 4.30021 13.9399 4.22922 13.731 4.06922L12.607 3.20624C12.249 2.93125 11.751 2.93125 11.394 3.20624L10.27 4.06922C10.0601 4.22922 9.79506 4.30021 9.53307 4.26621L8.12711 4.08122C7.68012 4.02222 7.24913 4.27121 7.07614 4.6882L6.53615 5.99617C6.43416 6.24016 6.24016 6.43415 5.99617 6.53615L4.6882 7.07614C4.27121 7.24913 4.02222 7.68012 4.08122 8.12711L4.26622 9.53307C4.30021 9.79506 4.22922 10.0601 4.06922 10.269L3.20624 11.393C2.93125 11.751 2.93125 12.249 3.20624 12.606L4.06922 13.73C4.23022 13.9399 4.30121 14.2049 4.26622 14.4669L4.08122 15.8729C4.02222 16.3199 4.27121 16.7509 4.6882 16.9239L5.99617 17.4648C6.24116 17.5658 6.43516 17.7598 6.53615 18.0048L7.07714 19.3128C7.24913 19.7298 7.68112 19.9788 8.12811 19.9198L9.48207 19.7408Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
