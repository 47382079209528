import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BackPainIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M20.3509 14.5575C20.1937 14.5575 20.0465 14.4552 19.9941 14.2979C19.9317 14.1008 20.039 13.8911 20.2361 13.8262L21.5738 13.397L21.0347 12.0992C20.9948 12.0019 20.9973 11.8946 21.0397 11.7973C21.0847 11.7024 21.1645 11.63 21.2644 11.5976L22.9889 11.0461C23.186 10.9837 23.3957 11.091 23.4605 11.2881C23.5229 11.4853 23.4156 11.6949 23.2185 11.7598L21.8808 12.1891L22.4199 13.4868C22.4598 13.5842 22.4573 13.6915 22.4149 13.7888C22.3699 13.8836 22.2901 13.956 22.1902 13.9885L20.4633 14.5425C20.4258 14.555 20.3859 14.56 20.3484 14.56L20.3509 14.5575Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M7.12002 28.9963C6.92286 28.9963 6.75814 28.8441 6.74567 28.6444L5.6875 10.8877C5.6875 10.8652 5.6875 10.8453 5.6875 10.8228C6.06684 7.48357 9.10659 7.55844 10.03 7.66077C10.6839 7.73314 11.2778 7.66326 11.7944 7.45612C13.6188 6.72239 13.9931 4.88308 14.0081 4.80571C14.048 4.60356 14.2427 4.46879 14.4448 4.50872C14.647 4.54616 14.7818 4.74082 14.7443 4.94547C14.7269 5.0403 14.2901 7.26395 12.0739 8.15242C11.44 8.40697 10.7238 8.49183 9.94764 8.40697C8.7597 8.2747 6.73069 8.42195 6.4362 10.8777L7.49187 28.602C7.50435 28.8092 7.34714 28.9863 7.14 28.9988C7.13252 28.9988 7.12501 28.9988 7.11752 28.9988L7.12002 28.9963Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M10.8905 28.9959C10.8706 28.9959 10.8531 28.9959 10.8331 28.991C10.6285 28.9585 10.4887 28.7688 10.5212 28.5642L11.0527 25.1551C11.1401 24.6011 11.1451 24.037 11.0702 23.4805L9.84984 14.3663C9.82239 14.1616 9.96711 13.972 10.1718 13.9445C10.3764 13.9171 10.5661 14.0618 10.5935 14.2664L11.8139 23.3807C11.8988 24.0071 11.8913 24.6435 11.794 25.2699L11.2624 28.679C11.2324 28.8637 11.0752 28.9959 10.893 28.9959H10.8905Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M27.8788 28.9964C27.8788 28.9964 27.8638 28.9964 27.8563 28.9964C27.6492 28.984 27.492 28.8068 27.5044 28.5996L28.5601 10.8753C28.2656 8.41956 26.2366 8.27231 25.0487 8.40458C24.2725 8.49193 23.5563 8.40458 22.9224 8.15002C20.7087 7.26156 20.2694 5.03541 20.252 4.94307C20.2145 4.74092 20.3493 4.54377 20.5515 4.50633C20.7561 4.4689 20.9508 4.60117 20.9882 4.80581C21.0032 4.88567 21.3775 6.72499 23.2019 7.45622C23.721 7.66336 24.3149 7.73324 24.9663 7.66087C25.8897 7.55855 28.9295 7.48368 29.3063 10.8229C29.3063 10.8454 29.3088 10.8653 29.3063 10.8878L28.2481 28.6445C28.2357 28.8442 28.0709 28.9964 27.8738 28.9964H27.8788Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M24.1093 28.9964C23.9272 28.9964 23.7699 28.8641 23.74 28.6795L23.2084 25.2704C23.1111 24.644 23.1036 24.0101 23.1884 23.3811L24.4088 14.2669C24.4363 14.0623 24.6259 13.92 24.8306 13.945C25.0352 13.9724 25.18 14.1596 25.1525 14.3667L23.9321 23.481C23.8573 24.0375 23.8648 24.6015 23.9496 25.1556L24.4812 28.5647C24.5136 28.7693 24.3739 28.9615 24.1692 28.9914C24.1493 28.9939 24.1293 28.9964 24.1118 28.9964H24.1093Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6214 25.1905C17.4143 25.1905 17.2471 25.0233 17.2471 24.8162V11.1449C17.2471 10.9377 17.4143 10.7705 17.6214 10.7705C17.8285 10.7705 17.9958 10.9377 17.9958 11.1449V24.8162C17.9958 25.0233 17.8285 25.1905 17.6214 25.1905Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
