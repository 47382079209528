import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartEmotionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3 12H4.386M19.686 12H21M5.637 18.363L6.618 17.382M17.436 6.564L18.363 5.637M12 21V19.614M12 4.314V3M18.363 18.363L17.382 17.382M6.564 6.564L5.637 5.637M12.3159 9.8949L12 10.2306L11.6841 9.8949C10.9911 9.1587 9.8418 9.0984 9.075 9.7572C8.292 10.4304 8.1984 11.5977 8.8428 12.4059C9.7644 13.5615 10.821 14.7018 12 15.6C13.2087 14.6802 14.2878 13.5048 15.2256 12.3195C15.834 11.55 15.7098 10.4331 14.9655 9.7932L14.9313 9.7635C14.1591 9.0984 13.0089 9.1587 12.3159 9.8949Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
