import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BookIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12.0001 16.864C9.97207 14.835 6.91505 14.462 4.50804 15.745C3.82504 16.109 3.00003 15.66 3.00003 14.886V5.79392C3.00003 5.15792 3.29403 4.54591 3.81203 4.17691C6.29705 2.4049 9.77007 2.6339 12.0001 4.86392M12.0001 16.864C14.0281 14.835 17.0851 14.462 19.4921 15.745C20.1751 16.11 21.0001 15.66 21.0001 14.886V5.79392C21.0001 5.15792 20.7061 4.54591 20.1881 4.17691C17.7031 2.4049 14.2301 2.6339 12.0001 4.86392M12.0001 16.864V4.86392M4.50804 19.881C6.91506 18.598 9.97207 18.971 12.0001 21C14.0281 18.971 17.0851 18.598 19.4921 19.881"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
