import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function InformationSymbolFilledCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="10.5"
        cy="10.3821"
        rx="9"
        ry="9"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 13.8825H11.8103"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6588 13.8821V9.63208H9.50879"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6004 6.6296C10.6004 6.76773 10.4884 6.87971 10.3503 6.87971C10.2122 6.87971 10.1002 6.76773 10.1002 6.6296C10.1002 6.49147 10.2122 6.3795 10.3503 6.3795"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3503 6.37955C10.4884 6.37955 10.6004 6.49153 10.6004 6.62965"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
