import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SpinnerIcon(props: SvgIconProps) {
  const strokeWidth = props.strokeWidth || 2;
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path
          d="M21 12C21 16.9706 16.9706 21 12 21"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 12C3 7.02944 7.02944 3 12 3"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
