import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function UserGroupIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21 17.4545V16.5578C21 15.4279 20.0845 14.5124 18.9545 14.5124H18.2992M3 17.4545V16.5578C3 15.4279 3.91555 14.5124 5.04545 14.5124H5.70082M16.3683 17.4545V16.1446C16.3683 14.5631 15.0862 13.281 13.5046 13.281H10.4946C8.91301 13.281 7.63092 14.5631 7.63092 16.1446V17.4545M19.1939 9.70105C19.8402 10.3474 19.8402 11.3954 19.1939 12.0418C18.5475 12.6882 17.4995 12.6882 16.8531 12.0418C16.2067 11.3954 16.2067 10.3474 16.8531 9.70105C17.4995 9.05466 18.5475 9.05466 19.1939 9.70105ZM13.8021 6.74648C14.7974 7.74178 14.7974 9.35549 13.8021 10.3508C12.8068 11.3461 11.1931 11.3461 10.1978 10.3508C9.20251 9.35549 9.20251 7.74179 10.1978 6.74648C11.1931 5.75117 12.8068 5.75117 13.8021 6.74648ZM7.14694 9.70105C7.79333 10.3474 7.79333 11.3954 7.14694 12.0418C6.50055 12.6882 5.45255 12.6882 4.80616 12.0418C4.15978 11.3954 4.15978 10.3474 4.80616 9.70105C5.45255 9.05466 6.50055 9.05466 7.14694 9.70105Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
