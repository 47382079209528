import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PaperAeroplaneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M20.8385 4.56612L8.61656 11.2281M8.62061 11.227L10.4109 19.283C10.6344 20.2869 11.9678 20.5032 12.4965 19.6217L20.8355 5.7226C21.2914 4.96441 20.7455 4 19.861 4H4.14022C3.12627 4 2.61879 5.22523 3.33553 5.94197L8.62061 11.227Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
