import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MagnifyingGlassCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M24.75 24.75L21.4172 21.4172M23.1621 17.206C23.1621 20.4955 20.4955 23.1621 17.206 23.1621C13.9166 23.1621 11.25 20.4955 11.25 17.206C11.25 13.9166 13.9166 11.25 17.206 11.25C20.4955 11.25 23.1621 13.9166 23.1621 17.206Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
