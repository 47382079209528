import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.904 10.678H18.697C19.969 10.678 21 9.647 21 8.375V8.375C21 7.103 19.969 6.072 18.697 6.072H17.929V5.304C17.929 4.031 16.897 3 15.625 3V3C14.353 3 13.322 4.031 13.322 5.303V7.096C13.322 7.361 13.217 7.616 13.029 7.803L7.803 13.029C7.615 13.217 7.361 13.322 7.096 13.322H5.303C4.031 13.322 3 14.353 3 15.625V15.625C3 16.897 4.031 17.928 5.303 17.928H6.071V18.696C6.071 19.969 7.103 21 8.375 21V21C9.647 21 10.678 19.969 10.678 18.697V16.904C10.678 16.639 10.783 16.384 10.971 16.197L16.197 10.971C16.385 10.783 16.639 10.678 16.904 10.678Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
