import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CircleWithQuestionMark(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="25" height="25" viewBox="0 0 25 25" fill="none">
      <ellipse
        cx="12.25"
        cy="12.25"
        rx="11.25"
        ry="11.25"
        stroke="#494D55"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9993 9.5079C10.9993 8.67498 11.6745 7.99976 12.5074 7.99976C13.3403 7.99976 14.0156 8.67498 14.0156 9.5079C14.0156 10.3408 13.3403 11.016 12.5074 11.016C12.3941 11.016 12.2852 11.0349 12.1836 11.0696C11.7901 11.2041 11.5071 11.5771 11.5071 12.0162V13.8077C11.5071 14.36 11.9548 14.8077 12.5071 14.8077C13.0594 14.8077 13.5071 14.36 13.5071 13.8077V12.8716C14.9576 12.4411 16.0156 11.0981 16.0156 9.5079C16.0156 7.57041 14.4449 5.99976 12.5074 5.99976C10.5699 5.99976 8.99927 7.57041 8.99927 9.5079C8.99927 9.87974 9.05747 10.2399 9.16598 10.5787C9.33444 11.1047 9.89738 11.3945 10.4234 11.2261C10.9493 11.0576 11.2391 10.4947 11.0707 9.96869C11.0246 9.8247 10.9993 9.67022 10.9993 9.5079ZM12.6628 15.3964C12.6266 15.3924 12.5899 15.3904 12.5526 15.3904C11.9014 15.3904 11.3734 15.9184 11.3734 16.5696C11.3734 17.2209 11.9014 17.7489 12.5526 17.7489C13.1832 17.7489 13.6982 17.2539 13.7303 16.6313C13.7315 16.6112 13.7321 16.591 13.7321 16.5706C13.7321 15.9564 13.2625 15.4519 12.6628 15.3964Z"
        fill="#494D55"
      />
    </SvgIcon>
  );
}
