import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HandWithHeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M6.91896 19.7221V13.8201C6.91896 13.2771 6.47827 12.8364 5.93528 12.8364H3.98367C3.44069 12.8364 3 13.2771 3 13.8201V19.7221C3 20.2651 3.44069 20.7058 3.98367 20.7058H5.93528C6.47827 20.7058 6.91896 20.2651 6.91896 19.7221ZM6.91896 19.7221H8.39545C8.71613 19.7221 9.03582 19.7615 9.34568 19.8402L11.9416 20.4914C12.5052 20.633 13.0925 20.6468 13.6611 20.5327L16.5324 19.9562C17.2918 19.8038 17.9882 19.429 18.5352 18.8801L20.5655 16.841C21.1448 16.2596 21.1448 15.3163 20.5655 14.7339C20.0431 14.2096 19.2168 14.1506 18.6257 14.5952L16.2589 16.3776C15.9196 16.6334 15.5074 16.7711 15.0834 16.7711H12.7974H14.2522C15.0716 16.7711 15.7366 16.1042 15.7366 15.2808V14.9828C15.7366 14.2991 15.2733 13.703 14.6123 13.5378L12.3665 12.9741C12.0016 12.8827 11.6268 12.8364 11.2511 12.8364C10.3431 12.8364 9.45487 13.1059 8.69941 13.6125L6.91896 14.8038M15.0854 3.64989L14.7421 4.0168L14.3978 3.64989C13.6433 2.84524 12.3921 2.77934 11.558 3.49939C10.7051 4.23517 10.6038 5.511 11.3052 6.39434C12.3085 7.65738 13.4584 8.90467 14.7421 9.8854C16.0583 8.88008 17.2328 7.5954 18.2538 6.29991C18.9168 5.45886 18.7801 4.23813 17.9715 3.53873L17.9341 3.50627C17.0921 2.77934 15.8399 2.84524 15.0854 3.64989Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
