import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlasterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.35887 19.6419V19.6419C2.54704 17.8301 2.54704 14.893 4.35887 13.0822L13.0822 4.35887C14.894 2.54704 17.8311 2.54704 19.6419 4.35887V4.35887C21.4537 6.17069 21.4537 9.10771 19.6419 10.9185L10.9185 19.6419C9.10671 21.4527 6.16968 21.4527 4.35887 19.6419Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4598 10.459L13.4846 13.4837"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3148 6.63438C17.2866 6.63438 17.2644 6.65656 17.2644 6.68479C17.2644 6.71302 17.2866 6.7352 17.3148 6.7352C17.343 6.7352 17.3652 6.71302 17.3652 6.68479C17.3652 6.65656 17.343 6.63438 17.3148 6.63438"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4989 6.59824C14.4706 6.59824 14.4485 6.62042 14.4485 6.64866C14.4485 6.67689 14.4706 6.69907 14.4989 6.69907C14.5271 6.69907 14.5493 6.67689 14.5493 6.64866C14.5493 6.62042 14.5271 6.59824 14.4989 6.59824"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3512 9.45029C17.3229 9.45029 17.3008 9.47248 17.3008 9.50071C17.3008 9.52894 17.3229 9.55112 17.3512 9.55112C17.3794 9.55112 17.4016 9.52894 17.4016 9.50071C17.4016 9.47248 17.3784 9.45029 17.3512 9.45029"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.50193 17.2999C9.4737 17.2999 9.45151 17.3221 9.45151 17.3503C9.45151 17.3785 9.4737 17.4007 9.50193 17.4007C9.53016 17.4007 9.55234 17.3785 9.55234 17.3503C9.55234 17.3221 9.53016 17.2999 9.50193 17.2999"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.68589 17.2633C6.65766 17.2633 6.63547 17.2855 6.63547 17.3137C6.63547 17.3419 6.65766 17.3641 6.68589 17.3641C6.71412 17.3641 6.7363 17.3419 6.7363 17.3137C6.7363 17.2855 6.71412 17.2633 6.68589 17.2633"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.64963 14.4474C6.6214 14.4474 6.59922 14.4695 6.59922 14.4978C6.59922 14.526 6.6214 14.5482 6.64963 14.5482C6.67786 14.5482 6.70004 14.526 6.70004 14.4978C6.70004 14.4695 6.67786 14.4474 6.64963 14.4474"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
