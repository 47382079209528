import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CigaretteLitCancelIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M7 14V18M21 14V18M16 5C16 8 18 8 18 11M9 11L13 21M15 18H18V14H13M19 4C19 7 21 7 21 10M11.8 18H3V14H10.2"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
