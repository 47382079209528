import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BloodDropIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M18.1371 4.01C19.1971 5.07 20.2871 6.09 21.2971 7.19C23.5071 9.6 25.4071 12.23 26.7371 15.24C27.4671 16.89 27.9571 18.6 27.9271 20.43C27.8271 25.19 24.1571 29.33 19.4471 29.98C13.9771 30.74 9.05712 27.16 8.12712 21.74C7.82712 19.97 8.07712 18.26 8.67712 16.58C9.88712 13.2 11.8871 10.3 14.2471 7.63C15.3371 6.4 16.5171 5.27 17.8071 4.25C17.8971 4.18 17.9871 4.12 18.1471 4L18.1371 4.01Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M17.957 12.1299V23.3299"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1875 18.5303L18.0475 24.1803L21.6175 18.5303"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
