import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function StopwatchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M11.5 9V13M9.50004 3H13.5M19.5 7L18.5 6M11.5 6C15.6422 6 19 9.35787 19 13.5C19 17.6421 15.6422 21 11.5 21C7.3579 21 4.00004 17.6421 4.00004 13.5C4.00004 9.35787 7.3579 6 11.5 6Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
