import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeadphonesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M5.62694 12.2001H4.80072C3.6469 12.2001 2.79098 13.2712 3.04479 14.3962L3.6541 17.0962C3.8395 17.917 4.56852 18.5003 5.41003 18.5003H6.48645C6.77536 18.5003 6.98866 18.2329 6.92566 17.9512L5.76284 12.8022C4.86012 8.80347 7.90038 5 12 5C16.0995 5 19.1398 8.80347 18.2371 12.8022L17.0751 17.9512C17.0112 18.2329 17.2255 18.5003 17.5135 18.5003H18.5899C19.4314 18.5003 20.1604 17.917 20.3458 17.0962L20.9551 14.3962C21.2089 13.2712 20.353 12.2001 19.1992 12.2001H18.373"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
