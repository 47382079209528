import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DeleteMessageIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="64"
      height="51"
      viewBox="0 0 64 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0851368 3.91459C0.0851368 -0.00287187 0.856699 -0.00169819 2.02214 7.46127e-05L2.09761 0.000150852C4.64934 0.107583 8.63845 0.172559 12.2744 0.231782C15.9985 0.292442 19.3521 0.347067 20.4112 0.435097C22.244 0.58745 35.6333 0.312861 44.3176 0.134763C48.0293 0.0586438 50.8815 0.000150852 51.6046 0.000150852C53.5365 0.000150852 53.7512 3.47965 53.617 5.21941V13.4832C53.5696 14.8682 53.5445 16.6287 53.5339 18.5589C52.4422 18.2226 51.2709 18.0412 50.023 18.0412C41.0952 18.0412 31.9998 19.7135 31.9998 36.2503C31.9998 37.4849 32.1597 38.6409 32.4564 39.7166C30.6285 39.7656 28.813 39.7969 27.0467 39.7969H20.1546C17.1881 45.6491 12.0038 46.8744 9.31161 46.6302C10.9312 44.2444 11.684 41.8829 11.9388 39.7969H1.49387C0.0448872 39.7969 -0.0490284 35.5925 0.0851368 33.4903V3.91459Z"
        fill="#D1DBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3666 50.3411C57.1885 50.3411 62.2271 44.969 63.041 38.2946C63.041 30.4727 58.6536 22.3411 50.8317 22.3411C43.0098 22.3411 35.041 23.8062 35.041 38.2946C35.041 46.1165 42.3666 50.3411 49.3666 50.3411ZM55.4043 35.6545C55.4703 37.0107 55.4959 37.5368 54.6196 37.5638C53.2409 37.6062 51.5032 37.5145 50.647 37.4693C50.5613 37.4648 50.4845 37.4607 50.4177 37.4573C50.1616 37.4442 49.3421 37.4584 48.3232 37.476C46.4217 37.5089 43.8254 37.5538 42.8988 37.456C42.7063 37.5139 42.3198 37.3964 42.3136 36.4621C42.3075 35.5278 42.3067 34.6371 42.3159 34.4906C42.3159 34.4906 42.3123 34.2133 42.7437 34.2509C43.163 34.2874 45.4913 34.1432 46.736 34.0662C47.0925 34.0441 47.3601 34.0276 47.4686 34.0223C47.9557 33.9987 54.055 33.9227 55.0539 34.3855C55.2623 34.4484 55.3487 34.7829 55.3804 35.1318C55.388 35.3183 55.3964 35.4923 55.4043 35.6545Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
