import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SoapDispenserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.5263 3H9.62653C9.12442 3 8.64221 3.19989 8.28695 3.55516L7.89474 3.94737M11.6842 3V5.84211M14.0526 8.68421H9.31579V6.78947C9.31579 6.26653 9.74021 5.84211 10.2632 5.84211H13.1053C13.6282 5.84211 14.0526 6.26653 14.0526 6.78947V8.68421ZM13.8499 8.68421H9.51853C7.57547 8.68421 6 10.2597 6 12.2027V19.1053C6 20.1521 6.84789 21 7.89474 21H15.4737C16.5205 21 17.3684 20.1521 17.3684 19.1053V12.2027C17.3684 10.2597 15.7929 8.68421 13.8499 8.68421Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
