import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function NoticeIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6039 3.13108L2.46875 23.8589C1.81826 24.97 2.61956 26.3676 3.90705 26.3676H28.1021C29.3875 26.3676 30.1891 24.9739 29.5426 23.8628L17.4828 3.13498C16.8411 2.03203 15.2486 2.02987 14.6039 3.13108Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.1191 10.4935L16.1191 16.5408"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="16.1183" cy="21.0762" r="1.51182" fill="currentColor" />
    </SvgIcon>
  );
}
