import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LiverIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M26.2719 10.1309C26.2719 10.1309 24.2219 9.91092 22.6519 10.3609C21.7519 10.6209 21.0819 11.3809 20.8519 12.2909C20.3819 14.2009 19.5919 18.5109 21.5419 19.8709C21.5419 19.8709 23.3519 20.4609 25.1119 18.1609C25.1119 18.1609 26.1019 16.4809 27.1919 14.9409C27.6319 14.3209 28.1719 13.8009 28.7819 13.3509C29.2919 12.9809 29.8519 12.4309 29.7919 11.8409C29.7919 11.8409 29.7419 10.5209 26.2719 10.1409V10.1309Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3401 9.72079C13.9901 9.10079 11.5301 10.0408 10.0501 11.9708C8.45011 14.0508 6.45011 17.7908 7.14011 22.9408C7.14011 22.9408 7.25011 27.7808 9.17011 27.6108C9.17011 27.6108 9.81011 27.7408 11.5301 25.9008C12.2301 25.1508 13.0501 24.5108 13.9401 24.0008C14.9001 23.4508 16.1101 22.8608 17.0301 22.7208C17.0301 22.7208 18.7901 22.6108 19.2801 19.4808C19.2801 19.4808 20.0401 11.8308 16.4901 9.79079C16.4401 9.76079 16.3901 9.74079 16.3401 9.73079C16.3401 9.73079 16.3301 9.73079 16.3201 9.73079L16.3401 9.72079Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4585 9.53019C13.4585 9.53019 12.5185 7.98019 14.9685 8.00019C15.3685 8.00019 15.7585 8.09019 16.1285 8.24019C16.4985 8.39019 17.0485 8.59019 17.6985 8.78019C18.7885 9.09019 19.9485 9.14019 21.0485 8.88019C22.1485 8.62019 23.6685 8.54019 23.9585 10.0802"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2891 19.4805C19.2891 19.4805 19.7291 20.2505 20.0591 22.5005C20.0591 22.5005 20.1691 25.3005 20.8291 24.8605C20.8291 24.8605 21.5991 25.0405 21.6291 23.6005C21.6391 22.9705 21.4891 22.3505 21.2491 21.7705C21.0791 21.3405 20.9591 20.6405 21.5391 19.8605"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
