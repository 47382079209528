import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CholesterolCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M17.682 28.767L24.452 22.647C24.582 22.527 24.712 22.407 24.832 22.277C28.082 18.837 29.212 15.997 29.402 13.827C29.692 10.497 27.252 7.43701 23.932 7.03701C19.652 6.52701 17.682 11.457 17.682 11.457C17.682 11.457 15.772 6.52701 11.492 7.03701C8.17195 7.43701 5.74196 10.487 6.02196 13.827C6.21196 16.007 7.34196 18.847 10.592 22.277C10.712 22.407 10.842 22.527 10.972 22.647L17.682 28.767Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M23.023 17.9873C25.083 17.9873 26.753 16.3174 26.753 14.2573C26.753 12.1973 25.083 10.5273 23.023 10.5273C20.9629 10.5273 19.293 12.1973 19.293 14.2573C19.293 16.3174 20.9629 17.9873 23.023 17.9873Z"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeMiterlimit="10"
      />
      <path
        d="M21.2812 14.1669L22.5513 15.4369L24.7513 13.2969"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
