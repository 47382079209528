import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatBubblePlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.61225 5.57143H5.57143C4.15114 5.57143 3 6.72257 3 8.14286V15C3 16.4203 4.15114 17.5714 5.57143 17.5714H7.28571V21L11.5714 17.5714H15.8571C17.2774 17.5714 18.4286 16.4203 18.4286 15V14.3878M12.1837 8.96144H15.0386V11.8163H18.1451V8.96144H21V5.85489H18.1451V3H15.0386V5.85489H12.1837V8.96144Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
