import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ScopeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 21V17M12 3V7M7 12H3M21 12H17M16.9497 7.05025C19.6834 9.78392 19.6834 14.2161 16.9497 16.9497C14.216 19.6834 9.78388 19.6834 7.05021 16.9497C4.31654 14.2161 4.31654 9.78392 7.05021 7.05025C9.78388 4.31658 14.216 4.31658 16.9497 7.05025Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
