import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Pattern5(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 295 243">
      <path
        d="M173.161 59.9145C194.017 59.797 228.023 57.5902 248.749 55.2117C278.378 51.8112 306.91 50.0883 306.743 37.255C306.54 21.5187 305.105 13.741 303.052 6.02656C302.519 4.03919 301.255 2.32793 299.513 1.23723C297.772 0.146536 295.683 -0.242633 293.666 0.148038C191.495 19.0678 126.059 7.83532 8.64923 18.177C-4.21582 19.312 2.59347 40.9223 1.73608 53.837C1.60522 55.7633 1.56012 57.9068 2.87777 59.3402C4.1954 60.7737 6.37494 60.8415 8.29272 60.8324L173.161 59.9145Z"
        fill="currentColor"
      />
      <path
        d="M173.16 124.578C194.016 124.461 228.022 122.254 248.748 119.875C278.377 116.475 306.909 114.752 306.742 101.919C306.539 86.1823 305.104 78.4046 303.051 70.6901C302.518 68.7028 301.254 66.9915 299.512 65.9008C297.771 64.8101 295.682 64.4209 293.665 64.8116C191.494 83.7314 126.058 72.4989 8.64825 82.8406C-4.2168 83.9756 2.5925 105.586 1.73511 118.501C1.60425 120.427 1.55914 122.57 2.8768 124.004C4.19442 125.437 6.37396 125.505 8.29175 125.496L173.16 124.578Z"
        fill="currentColor"
      />
      <path
        d="M175.416 189.242C196.273 189.124 230.279 186.917 251.005 184.539C280.634 181.138 309.166 179.416 308.999 166.582C308.796 150.846 307.361 143.068 305.308 135.354C304.775 133.366 303.51 131.655 301.769 130.564C300.028 129.474 297.938 129.085 295.922 129.475C193.751 148.395 128.315 137.162 10.9051 147.504C-1.95996 148.639 4.84933 170.25 3.99194 183.164C3.86108 185.091 3.81598 187.234 5.13363 188.667C6.45126 190.101 8.6308 190.169 10.5486 190.16L175.416 189.242Z"
        fill="currentColor"
      />
      <path
        d="M175.416 254.358C196.273 254.24 230.279 252.034 251.005 249.655C280.634 246.255 309.166 244.532 308.999 231.698C308.796 215.962 307.361 208.184 305.308 200.47C304.775 198.483 303.51 196.771 301.769 195.681C300.028 194.59 297.938 194.201 295.922 194.591C193.751 213.511 128.315 202.279 10.9051 212.62C-1.95996 213.755 4.84933 235.366 3.99194 248.28C3.86108 250.207 3.81598 252.35 5.13363 253.784C6.45126 255.217 8.6308 255.285 10.5486 255.276L175.416 254.358Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
