import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MagnifyingGlassPlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21 21L17.3438 17.3438M15.136 11.4003L7.66471 11.4003M11.4004 15.136L11.4004 7.66466M19.8007 11.4004C19.8007 16.0399 16.0399 19.8008 11.4004 19.8008C6.76087 19.8008 3 16.0399 3 11.4004C3 6.76088 6.76087 3 11.4004 3C16.0399 3 19.8007 6.76088 19.8007 11.4004Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
