import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function UserCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M18 17C16.7019 17 15.6377 15.9363 15.6377 14.6378H13.6377C13.6377 17.0412 15.5976 19 18 19V17ZM15.6377 14.6378C15.6377 13.3293 16.7117 12.25 18 12.25V10.25C15.5878 10.25 13.6377 12.2442 13.6377 14.6378H15.6377ZM18 12.25C19.2981 12.25 20.3622 13.3137 20.3622 14.6122H22.3622C22.3622 12.2088 20.4024 10.25 18 10.25V12.25ZM20.3622 14.6122C20.3622 15.9207 19.2883 17 18 17V19C20.4122 19 22.3622 17.0058 22.3622 14.6122H20.3622ZM23.25 23.75H12.75V25.75H23.25V23.75ZM12.75 23.75C12.8898 23.75 13 23.8602 13 24H11C11 24.9648 11.7852 25.75 12.75 25.75V23.75ZM13 24V23.25H11V24H13ZM13 23.25C13 22.1523 13.9023 21.25 15 21.25V19.25C12.7977 19.25 11 21.0477 11 23.25H13ZM15 21.25H21V19.25H15V21.25ZM21 21.25C22.0977 21.25 23 22.1523 23 23.25H25C25 21.0477 23.2023 19.25 21 19.25V21.25ZM23 23.25V24H25V23.25H23ZM23 24C23 23.8602 23.1102 23.75 23.25 23.75V25.75C24.2148 25.75 25 24.9648 25 24H23Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
