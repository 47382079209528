import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ToothIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13.872 6.67995C12.6435 7.02533 11.343 7.02533 10.1145 6.67995M20.1854 5.71583C18.8883 3.5536 16.3042 2.52648 13.8765 3.20935L12.8032 3.51085C12.2734 3.65935 11.7131 3.65935 11.1844 3.51085L10.1111 3.20935C7.68227 2.52648 5.09928 3.5536 3.80104 5.71583C3.27792 6.5877 3.00117 7.58557 3.00002 8.60369V8.70832C2.99554 12.1665 3.69191 15.5899 5.04528 18.7725L5.41428 19.6388C5.76528 20.4634 6.5764 21 7.47414 21C8.39101 21 9.21563 20.4398 9.55426 19.5881L10.6219 16.8994C10.8458 16.3347 11.3891 15.9645 11.9932 15.9645C12.5974 15.9645 13.1407 16.3347 13.3646 16.8994L14.4322 19.5881C14.7709 20.4398 15.5955 21 16.5123 21C17.4101 21 18.2212 20.4634 18.5722 19.6376L18.9412 18.7714C20.2957 15.5888 20.9909 12.1654 20.9865 8.70719V8.60257C20.9853 7.58557 20.7086 6.5877 20.1854 5.71583Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
