import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatWithTick(props: SvgIconProps) {
  return (
    <SvgIcon
      width="134"
      height="151"
      viewBox="0 0 134 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M132.779 64.8453C129.438 42.3881 115.485 21.7515 95.8948 10.2932C76.3046 -1.16517 51.4836 -3.19984 30.288 4.90757C30.288 4.90757 30.3237 4.84955 30.288 4.85847C22.2343 9.04826 22.881 10.3378 17.9845 16.6872C10.8227 25.9725 5.94415 36.9535 3.26851 48.3672C0.954073 58.2817 0.418949 68.5264 0.26287 78.7086C0.11125 88.409 0.338662 98.3726 3.92402 107.39C6.15373 113.035 9.66328 118.099 13.5385 122.784C29.8644 142.479 56.4915 156.084 81.094 149.11C96.256 144.813 108.363 133.56 119.186 122.097C123.753 117.26 128.301 112.173 130.781 105.989C133.01 100.345 133.407 94.1426 133.613 88.0654C133.885 80.3194 133.92 72.5243 132.779 64.8453Z"
        fill="#BEEDE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8065 43.6497C24.8065 37.7359 25.9712 37.7377 27.7306 37.7403L27.8445 37.7405C31.6966 37.9026 37.7185 38.0007 43.2073 38.0901C48.8293 38.1817 53.8919 38.2642 55.4906 38.3971C58.2575 38.627 78.47 38.2125 91.5798 37.9437C97.1829 37.8288 101.489 37.7405 102.58 37.7405C105.497 37.7405 105.821 42.9931 105.618 45.6195V58.0945C105.547 60.1854 105.509 62.8431 105.493 65.7571C103.845 65.2493 102.076 64.9756 100.193 64.9756C86.7153 64.9756 72.9847 67.5 72.9847 92.464C72.9847 94.3276 73.2261 96.0727 73.6739 97.6964C70.9146 97.7703 68.1741 97.8177 65.5076 97.8177H55.1033C50.6251 106.652 42.7988 108.502 38.7347 108.133C41.1797 104.532 42.3161 100.967 42.7008 97.8177H26.9331C24.7457 97.8177 24.6039 91.4707 24.8065 88.2973V43.6497Z"
        fill="#00948D"
      />
      <path
        d="M120.714 94.6857C119.442 105.121 111.564 113.521 99.3342 113.521C88.3896 113.521 76.9359 106.915 76.9359 94.6857C76.9359 72.0329 89.3953 69.7422 101.625 69.7422C113.855 69.7422 120.714 82.4561 120.714 94.6857Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.228 85.1927C107.854 85.5628 108.878 86.5214 107.961 87.3951C107.07 88.2444 105.884 89.6613 105.167 90.5183C104.962 90.7634 104.795 90.9627 104.684 91.0898C104.187 91.6616 98.6227 97.8903 97.6181 98.9632C97.5229 99.1441 97.2083 99.3459 96.6176 99.0829C96.5888 99.0701 96.5607 99.0552 96.5331 99.0384C96.4436 99.013 96.3787 98.9541 96.3051 98.8784C95.5429 98.0941 94.3738 96.7712 93.6161 95.9139C93.3555 95.619 93.1436 95.3793 93.0136 95.2353C92.867 95.073 92.6784 94.8426 92.4561 94.5711C91.9083 93.9018 91.1563 92.9832 90.3269 92.2214C89.3941 91.3649 90.3995 90.3874 91.0188 90.0058C91.2182 89.8679 92.3546 89.3648 92.5256 89.5001C93.4272 90.073 95.4496 92.5306 95.7619 92.9264C95.8978 93.0987 96.4958 93.8159 97.1562 94.6072C97.8472 93.7473 98.4962 92.9393 98.637 92.7539C98.9419 92.3524 104.821 85.3047 105.712 84.7151C105.88 84.5767 107.026 85.0586 107.228 85.1927Z"
        fill="#00948D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6232 66.5936C57.6185 66.5936 60.8363 63.1628 61.3561 58.9003C61.3561 53.905 58.5542 48.7119 53.5588 48.7119C48.5635 48.7119 43.4744 49.6476 43.4744 58.9003C43.4744 63.8957 48.1527 66.5936 52.6232 66.5936ZM58.8319 68.3443C63.5515 70.8936 66.5346 76.1133 66.858 81.4593H66.8573C57.8507 82.2527 49.0246 82.0554 40.092 80.6567C39.7146 80.5971 39.2941 80.5125 39.0677 80.2034C38.8837 79.9501 38.878 79.6159 38.8959 79.3046C39.0095 77.3521 39.793 75.495 40.7713 73.7993C41.6001 72.3619 42.5848 70.9997 43.814 69.8836C47.7832 66.2785 54.1122 65.795 58.8319 68.3443Z"
        fill="white"
      />
    </SvgIcon>
  );
}
