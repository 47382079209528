import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function UserWithCogIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M17.8235 14.6471V15.7059M17.8235 19.9412V21M15.0706 16.2353L16.0235 16.7647M19.6235 18.8824L20.5765 19.4118M15.0706 19.4118L16.0235 18.8824M19.6235 16.7647L20.5765 16.2353M3 19.9412C3 17.2941 5.11765 15.1765 7.76471 15.1765H10.5176M19.3059 16.3412C20.1529 17.1882 20.1529 18.4588 19.3059 19.3059C18.4588 20.1529 17.1882 20.1529 16.3412 19.3059C15.4941 18.4588 15.4941 17.1882 16.3412 16.3412C17.1882 15.4941 18.4588 15.4941 19.3059 16.3412ZM14.6471 4.27059C16.4471 6.07059 16.4471 8.92941 14.6471 10.6235C12.8471 12.3176 9.98823 12.4235 8.29412 10.6235C6.6 8.82353 6.49412 5.96471 8.29412 4.27059C10.0941 2.57647 12.8471 2.57647 14.6471 4.27059Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
