import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.3158 3V6.78947"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.73684 3V6.78947"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 9.6314H20.0526"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1579 4.89453H4.89474C3.8479 4.89453 3 5.74243 3 6.78927V19.1051C3 20.1519 3.8479 20.9998 4.89474 20.9998H18.1579C19.2047 20.9998 20.0526 20.1519 20.0526 19.1051V6.78927C20.0526 5.74243 19.2047 4.89453 18.1579 4.89453Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5387 13.1641C11.4079 13.1641 11.3018 13.2702 11.3028 13.4009C11.3028 13.5317 11.4089 13.6378 11.5396 13.6378C11.6703 13.6378 11.7764 13.5317 11.7764 13.4009C11.7764 13.2702 11.6703 13.1641 11.5387 13.1641"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2755 13.1641C16.1448 13.1641 16.0387 13.2702 16.0396 13.4009C16.0396 13.5317 16.1457 13.6378 16.2765 13.6378C16.4072 13.6378 16.5133 13.5317 16.5133 13.4009C16.5133 13.2702 16.4072 13.1641 16.2755 13.1641"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.80184 16.9536C6.6711 16.9536 6.565 17.0597 6.56595 17.1905C6.56595 17.3212 6.67205 17.4273 6.80279 17.4273C6.93353 17.4273 7.03963 17.3212 7.03963 17.1905C7.03963 17.0597 6.93353 16.9536 6.80184 16.9536"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5387 16.9536C11.4079 16.9536 11.3018 17.0597 11.3028 17.1905C11.3028 17.3212 11.4089 17.4273 11.5396 17.4273C11.6703 17.4273 11.7764 17.3212 11.7764 17.1905C11.7764 17.0597 11.6703 16.9536 11.5387 16.9536"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
