import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function StarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3L14.7805 8.67758L21 9.59368L16.5 14.0103L17.562 20.2497L12 17.3024L6.438 20.2497L7.5 14.0103L3 9.59368L9.21853 8.67758L12 3Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
