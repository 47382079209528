import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartInCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0087 14.0123C18.5201 13.414 20.0018 12 21.349 12C23.7058 12 25.0604 13.9494 24.6892 16.2718C23.9471 20.9148 18.0087 23.8765 18.0087 23.8765C18.0087 23.8765 12.0701 20.5362 11.3282 16.2718C10.893 13.7692 12.3118 12 14.6685 12C16.0157 12 17.4972 13.414 18.0087 14.0123Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
