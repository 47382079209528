import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function InformationSymbolInCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <ellipse
          cx="12"
          cy="12"
          rx="9"
          ry="9"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0001 15.5002H13.3104"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.159 15.5V11.25H11.009"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1003 8.24728C12.1003 8.38541 11.9884 8.49738 11.8502 8.49738C11.7121 8.49738 11.6001 8.38541 11.6001 8.24728C11.6001 8.10915 11.7121 7.99717 11.8502 7.99717"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8501 7.99771C11.9883 7.99771 12.1002 8.10969 12.1002 8.24782"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
