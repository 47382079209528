import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function QuestionMarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.35185 5.75389C2.35185 4.01536 3.76121 2.606 5.49974 2.606C7.23828 2.606 8.64764 4.01536 8.64764 5.75389C8.64764 7.48817 7.24519 8.89488 5.51254 8.90177L5.49983 8.9017C5.17113 8.9017 4.87611 9.04587 4.67455 9.27442C4.50352 9.4683 4.39975 9.72293 4.39975 10.0018L4.39984 10.0157V13.0359C4.39984 13.6434 4.89232 14.1359 5.49983 14.1359C6.10734 14.1359 6.59983 13.6434 6.59983 13.0359V10.9885C9.02565 10.4813 10.8476 8.33037 10.8476 5.75389C10.8476 2.80034 8.4533 0.406006 5.49974 0.406006C2.54619 0.406006 0.151855 2.80034 0.151855 5.75389C0.151855 6.32146 0.240654 6.87041 0.405924 7.38642C0.591227 7.96498 1.21046 8.28378 1.78902 8.09847C2.36758 7.91317 2.68638 7.29394 2.50108 6.71538C2.40445 6.41368 2.35185 6.09099 2.35185 5.75389ZM5.7058 16.3176C5.66325 16.3126 5.61994 16.31 5.57604 16.31C4.80088 16.31 4.17249 16.9384 4.17249 17.7136C4.17249 18.4887 4.80088 19.1171 5.57604 19.1171C6.35119 19.1171 6.97958 18.4887 6.97958 17.7136C6.97958 17.7042 6.97946 17.6949 6.97923 17.6857C6.96441 16.9677 6.41046 16.3822 5.7058 16.3176Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
