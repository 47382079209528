import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LinkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.5677 5.9322C16.8106 4.68927 18.8249 4.68927 20.0678 5.9322C21.3107 7.17513 21.3107 9.18938 20.0678 10.4323L14.7766 15.7235C13.5336 16.9665 11.5194 16.9665 10.2765 15.7235C9.03352 14.4806 9.03352 12.4664 10.2765 11.2234L11.0676 10.4323M8.43231 17.5677C7.18938 18.8106 5.17513 18.8106 3.9322 17.5677C2.68927 16.3248 2.68927 14.3105 3.9322 13.0676L9.22343 7.77635C10.4664 6.53342 12.4806 6.53342 13.7235 7.77635C14.9665 9.01929 14.9665 11.0335 13.7235 12.2765L12.9 13.1"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
