import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PageWithWritingCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M23.25 24.75C24.0788 24.75 24.75 24.0788 24.75 23.25V18.75C24.75 18.336 24.414 18 24 18H21.75M23.25 24.75C22.4212 24.75 21.75 24.0788 21.75 23.25V18M23.25 24.75L12.75 24.75C11.9212 24.75 11.25 24.0787 11.25 23.25V12.75C11.25 12.336 11.586 12 12 12H21C21.414 12 21.75 12.336 21.75 12.75V18M14.25 15H18.75M14.25 17.9025H18.75M14.25 21.045H16.5"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
