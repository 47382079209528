import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function WavyPlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 35 43">
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.73129 10.9497C9.66858 10.5858 9.71647 10.2649 9.85316 9.98234C10.4725 8.15487 13.2359 7.91798 14.8478 8.07894C16.4852 7.9457 18.0632 8.00438 18.8032 8.08493C19.4518 8.12336 19.8842 9.19704 20.0193 9.72908C20.5411 11.1245 20.8984 16.5612 21.1139 21.3681C21.3008 21.3488 21.5104 21.3278 21.7398 21.3048C24.0084 21.0776 28.2137 20.6564 31.6443 19.7697C34.7808 18.959 35.1434 24.5664 34.9326 27.4714C34.8942 28.4443 33.8205 29.0929 33.2884 29.2955C31.8647 30.0941 26.234 30.6358 21.3576 30.9568C21.3567 31.0472 21.3558 31.1402 21.3549 31.2357C21.3237 34.4697 21.2655 40.519 21.3705 41.6005C21.4786 42.7135 20.9651 42.7388 20.9651 42.7388C20.6949 42.7809 19.0465 42.8905 17.317 42.9917C16.7858 43.0228 16.3939 42.9513 16.1086 42.8226C15.4127 42.8722 14.4344 42.9339 13.4286 42.9927C11.6991 43.0939 11.4468 42.1074 11.5369 41.6015C11.3357 39.7808 11.2011 35.4357 11.1 31.2891C7.76346 31.2416 2.42639 31.1752 1.41699 31.3223C0.304024 31.4844 0.278733 30.7143 0.278733 30.7143C0.236573 30.3089 0.126961 27.8363 0.0257798 25.242C-0.0753976 22.6477 0.911098 22.2694 1.41699 22.4045C3.10746 22.1243 6.97413 21.9301 10.8376 21.7824C10.8271 21.6277 10.8159 21.4594 10.8038 21.2787C10.6523 19.0101 10.3715 14.8049 9.78039 11.3743C9.75527 11.2285 9.73939 11.0887 9.73214 10.9546L9.73129 10.9497Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
