import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TranslationSymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="62"
      height="55"
      viewBox="0 0 62 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.3925 35.9425L26.4075 29.04L26.49 28.9575C31.1489 23.7795 34.6297 17.653 36.6925 11H44.75V5.5H25.5V0H20V5.5H0.75V10.9725H31.4675C29.625 16.28 26.71 21.3125 22.75 25.7125C20.1925 22.88 18.075 19.7725 16.3975 16.5H10.8975C12.905 20.9825 15.655 25.2175 19.0925 29.04L5.095 42.845L9 46.75L22.75 33L31.3025 41.5525L33.3925 35.9425ZM48.875 22H43.375L31 55H36.5L39.58 46.75H52.6425L55.75 55H61.25L48.875 22ZM41.67 41.25L46.125 29.3425L50.58 41.25H41.67Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
