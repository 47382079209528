import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ContraceptionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M20.1934 31.0788L9.53593 28.2232C8.54997 27.959 7.96231 26.9411 8.2265 25.9552L13.6297 5.79016C13.8939 4.80421 14.9117 4.21656 15.8977 4.48075L26.5551 7.3364C27.5411 7.60058 28.1287 8.61842 27.8645 9.60438L22.4613 29.7694C22.1972 30.7553 21.1793 31.343 20.1934 31.0788ZM15.702 5.21117C15.1186 5.05486 14.5189 5.40315 14.3632 5.98411L8.95999 26.1491C8.80368 26.7325 9.15197 27.3322 9.73293 27.4879L20.3904 30.3436C20.9737 30.4999 21.5735 30.1516 21.7292 29.5706L27.1317 9.40801C27.288 8.82464 26.9397 8.2249 26.3588 8.06923L15.7013 5.21358L15.702 5.21117Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M20.4224 8.91623C20.2199 8.86197 20.0999 8.65413 20.1542 8.45163L20.764 6.17599C20.8182 5.97349 21.0261 5.85349 21.2286 5.90775C21.4311 5.96201 21.5511 6.16986 21.4968 6.37235L20.887 8.648C20.8328 8.85049 20.6249 8.97049 20.4224 8.91623Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M19.0332 14.1008C18.8308 14.0465 18.7107 13.8387 18.765 13.6362L19.3748 11.3606C19.429 11.1581 19.6369 11.0381 19.8394 11.0923C20.0419 11.1466 20.1619 11.3544 20.1076 11.5569L19.4979 13.8326C19.4436 14.0351 19.2357 14.1551 19.0332 14.1008Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6443 19.2844C17.4418 19.2301 17.3218 19.0223 17.376 18.8198L17.9858 16.5442C18.04 16.3417 18.2479 16.2217 18.4504 16.2759C18.6529 16.3302 18.7729 16.538 18.7186 16.7405L18.1089 19.0162C18.0546 19.2187 17.8468 19.3387 17.6443 19.2844Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M16.2551 24.4687C16.0526 24.4145 15.9326 24.2066 15.9868 24.0041L16.5966 21.7285C16.6509 21.526 16.8587 21.406 17.0612 21.4602C17.2637 21.5145 17.3837 21.7223 17.3294 21.9248L16.7197 24.2005C16.6654 24.403 16.4576 24.523 16.2551 24.4687Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M14.8661 29.6526C14.6636 29.5983 14.5436 29.3905 14.5979 29.188L15.2076 26.9123C15.2619 26.7098 15.4697 26.5898 15.6722 26.6441C15.8747 26.6983 15.9947 26.9062 15.9405 27.1087L15.3307 29.3843C15.2765 29.5868 15.0686 29.7068 14.8661 29.6526Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M23.2017 11.7097C22.5026 11.5224 22.0861 10.8011 22.2735 10.102C22.4608 9.4029 23.1821 8.98642 23.8812 9.17374C24.5803 9.36106 24.9968 10.0824 24.8095 10.7815C24.6221 11.4806 23.9008 11.8971 23.2017 11.7097ZM23.6849 9.90658C23.3883 9.82713 23.0851 10.0042 23.0063 10.2983C22.9275 10.5924 23.104 10.8981 23.3981 10.9769C23.6922 11.0557 23.9978 10.8792 24.0766 10.5851C24.1554 10.291 23.979 9.98538 23.6849 9.90658Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M22.1004 15.8198C21.4013 15.6325 20.9848 14.9112 21.1722 14.2121C21.3595 13.513 22.0808 13.0965 22.7799 13.2839C23.479 13.4712 23.8955 14.1925 23.7082 14.8916C23.5208 15.5907 22.7995 16.0072 22.1004 15.8198ZM22.5842 14.0143C22.2877 13.9348 21.9844 14.1119 21.9056 14.406C21.8268 14.7001 22.0033 15.0058 22.2974 15.0846C22.5915 15.1634 22.8972 14.9869 22.976 14.6928C23.0548 14.3987 22.8783 14.0931 22.5842 14.0143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M20.9997 19.9278C20.3006 19.7404 19.8842 19.0191 20.0715 18.32C20.2588 17.6209 20.9802 17.2044 21.6792 17.3918C22.3783 17.5791 22.7948 18.3004 22.6075 18.9995C22.4202 19.6986 21.6988 20.1151 20.9997 19.9278ZM21.4829 18.1246C21.1864 18.0451 20.8831 18.2223 20.8043 18.5164C20.7255 18.8105 20.902 19.1161 21.1961 19.1949C21.4902 19.2737 21.7958 19.0973 21.8746 18.8032C21.9534 18.5091 21.777 18.2034 21.4829 18.1246Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M19.8984 24.0379C19.1993 23.8505 18.7829 23.1292 18.9702 22.4301C19.1575 21.731 19.8789 21.3145 20.5779 21.5019C21.277 21.6892 21.6935 22.4105 21.5062 23.1096C21.3189 23.8087 20.5975 24.2252 19.8984 24.0379ZM20.3822 22.2323C20.0857 22.1528 19.7824 22.33 19.7036 22.6241C19.6248 22.9182 19.8013 23.2238 20.0954 23.3026C20.3895 23.3814 20.6952 23.2049 20.774 22.9108C20.8528 22.6168 20.6763 22.3111 20.3822 22.2323Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M18.7978 28.1455C18.0987 27.9582 17.6822 27.2369 17.8695 26.5378C18.0569 25.8387 18.7782 25.4222 19.4773 25.6095C20.1764 25.7969 20.5928 26.5182 20.4055 27.2173C20.2182 27.9164 19.4969 28.3328 18.7978 28.1455ZM19.2809 26.3424C18.9844 26.2629 18.6811 26.44 18.6023 26.7341C18.5235 27.0282 18.7 27.3339 18.9941 27.4127C19.2882 27.4915 19.5939 27.315 19.6727 27.0209C19.7515 26.7268 19.575 26.4212 19.2809 26.3424Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M16.617 9.94558C15.9179 9.75826 15.5014 9.03691 15.6887 8.33782C15.876 7.63874 16.5974 7.22226 17.2965 7.40958C17.9956 7.5969 18.412 8.31825 18.2247 9.01734C18.0374 9.71643 17.3161 10.1329 16.617 9.94558ZM17.1001 8.14242C16.8036 8.06297 16.5004 8.24009 16.4216 8.53419C16.3428 8.82828 16.5192 9.13394 16.8133 9.21274C17.1074 9.29155 17.4131 9.11508 17.4919 8.82098C17.5707 8.52689 17.3942 8.22122 17.1001 8.14242Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M15.5157 14.0554C14.8166 13.8681 14.4001 13.1468 14.5874 12.4477C14.7747 11.7486 15.4961 11.3321 16.1952 11.5194C16.8943 11.7068 17.3107 12.4281 17.1234 13.1272C16.9361 13.8263 16.2148 14.2428 15.5157 14.0554ZM15.9995 12.2499C15.703 12.1704 15.3997 12.3475 15.3209 12.6416C15.2421 12.9357 15.4186 13.2414 15.7127 13.3202C16.0068 13.399 16.3125 13.2225 16.3913 12.9284C16.4701 12.6343 16.2936 12.3287 15.9995 12.2499Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M14.415 18.1634C13.7159 17.976 13.2994 17.2547 13.4868 16.5556C13.6741 15.8565 14.3954 15.44 15.0945 15.6274C15.7936 15.8147 16.2101 16.536 16.0228 17.2351C15.8354 17.9342 15.1141 18.3507 14.415 18.1634ZM14.8982 16.3602C14.6017 16.2807 14.2984 16.4579 14.2196 16.752C14.1408 17.0461 14.3173 17.3517 14.6114 17.4305C14.9055 17.5093 15.2111 17.3329 15.2899 17.0388C15.3688 16.7447 15.1923 16.439 14.8982 16.3602Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M13.3137 22.2737C12.6146 22.0864 12.1981 21.365 12.3855 20.6659C12.5728 19.9669 13.2941 19.5504 13.9932 19.7377C14.6923 19.925 15.1088 20.6464 14.9215 21.3455C14.7341 22.0446 14.0128 22.461 13.3137 22.2737ZM13.7975 20.4681C13.501 20.3887 13.1978 20.5658 13.119 20.8599C13.0401 21.154 13.2166 21.4597 13.5107 21.5385C13.8048 21.6173 14.1105 21.4408 14.1893 21.1467C14.2681 20.8526 14.0916 20.5469 13.7975 20.4681Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M12.213 26.3811C11.514 26.1938 11.0975 25.4725 11.2848 24.7734C11.4721 24.0743 12.1935 23.6578 12.8926 23.8451C13.5916 24.0325 14.0081 24.7538 13.8208 25.4529C13.6335 26.152 12.9121 26.5684 12.213 26.3811ZM12.6962 24.578C12.3997 24.4985 12.0964 24.6756 12.0176 24.9697C11.9388 25.2638 12.1153 25.5695 12.4094 25.6483C12.7035 25.7271 13.0092 25.5506 13.088 25.2565C13.1668 24.9624 12.9903 24.6568 12.6962 24.578Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
