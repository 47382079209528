import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PhotoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M2.2981 12.3486C1.90474 12.7363 1.90012 13.3694 2.28779 13.7628C2.67545 14.1562 3.3086 14.1608 3.70196 13.7731L2.2981 12.3486ZM6.6857 9.42857L7.38759 8.71628C6.99827 8.33265 6.37307 8.33267 5.98377 8.71633L6.6857 9.42857ZM12.5918 14.8578C12.1941 15.2411 12.1825 15.8742 12.5657 16.2718C12.949 16.6695 13.582 16.6812 13.9797 16.2979L12.5918 14.8578ZM16.9982 12L17.6922 11.28C17.3049 10.9067 16.6917 10.9067 16.3043 11.28L16.9982 12ZM20.3061 16.5771C20.7037 16.9604 21.3368 16.9488 21.72 16.5511C22.1033 16.1535 22.0917 15.5204 21.694 15.1371L20.3061 16.5771ZM5.57146 4H18.4286V2H5.57146V4ZM20 5.57143V18.4286H22V5.57143H20ZM18.4286 20H5.57146V22H18.4286V20ZM4.00003 18.4286V5.57143H2.00003V18.4286H4.00003ZM5.57146 20C4.70358 20 4.00003 19.2964 4.00003 18.4286H2.00003C2.00003 20.401 3.59901 22 5.57146 22V20ZM20 18.4286C20 19.2964 19.2965 20 18.4286 20V22C20.401 22 22 20.401 22 18.4286H20ZM18.4286 4C19.2965 4 20 4.70355 20 5.57143H22C22 3.59898 20.401 2 18.4286 2V4ZM5.57146 2C3.59901 2 2.00003 3.59898 2.00003 5.57143H4.00003C4.00003 4.70355 4.70358 4 5.57146 4V2ZM3.70196 13.7731L7.38763 10.1408L5.98377 8.71633L2.2981 12.3486L3.70196 13.7731ZM5.98382 10.1409L17.7267 21.7123L19.1305 20.2877L7.38759 8.71628L5.98382 10.1409ZM13.9797 16.2979L17.6922 12.72L16.3043 11.28L12.5918 14.8578L13.9797 16.2979ZM16.3043 12.72L20.3061 16.5771L21.694 15.1371L17.6922 11.28L16.3043 12.72ZM17.4286 7.5C17.4286 8.01283 17.0129 8.42857 16.5 8.42857V10.4286C18.1174 10.4286 19.4286 9.11741 19.4286 7.5H17.4286ZM16.5 8.42857C15.9872 8.42857 15.5715 8.01284 15.5715 7.5H13.5715C13.5715 9.1174 14.8826 10.4286 16.5 10.4286V8.42857ZM15.5715 7.5C15.5715 6.98716 15.9872 6.57143 16.5 6.57143V4.57143C14.8826 4.57143 13.5715 5.8826 13.5715 7.5H15.5715ZM16.5 6.57143C17.0129 6.57143 17.4286 6.98716 17.4286 7.5H19.4286C19.4286 5.88259 18.1174 4.57143 16.5 4.57143V6.57143Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
