import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SunIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 218 220" fill="none">
      <path
        d="M113.228 47.6898C113.599 47.7153 114.018 47.7244 114.29 47.4625C114.562 47.2006 114.58 46.7656 114.577 46.3803C114.517 35.4486 116.149 37.5001 116.089 26.5745C116.066 22.4249 115.638 15.6609 115.187 11.5384C114.531 5.64433 114.199 -0.0329684 111.721 0.00014414C109.331 0.0375851 106.955 0.37485 104.648 1.00406C104.26 1.11622 103.927 1.36991 103.716 1.71527C103.505 2.06063 103.43 2.47267 103.507 2.8704C107.157 23.1895 103.301 23.2241 105.296 46.5805C105.518 49.1422 110.736 47.5182 113.228 47.6898Z"
        fill="currentColor"
      />
      <path
        d="M87.4949 52.8972C87.8614 52.831 88.2701 52.7361 88.4699 52.4156C88.6697 52.095 88.5796 51.663 88.4834 51.2957C85.7492 40.7133 87.8344 42.3072 85.1002 31.7292C84.0621 27.7121 81.9934 21.2582 80.5391 17.375C78.4599 11.8211 76.7488 6.39965 74.3556 7.04083C72.048 7.66397 69.8273 8.57411 67.7454 9.75004C67.3981 9.95582 67.14 10.2841 67.0217 10.6705C66.9033 11.0569 66.9331 11.4736 67.1054 11.8391C75.6205 30.6531 71.8888 31.6224 79.5401 53.7747C80.3724 56.1994 85.0371 53.3427 87.4949 52.8972Z"
        fill="currentColor"
      />
      <path
        d="M67.8357 64.2577C68.1062 63.9988 68.3946 63.6963 68.3856 63.3185C68.3766 62.9407 68.0671 62.6337 67.7847 62.3763C59.6872 55.0494 62.3042 55.23 54.2067 47.9031C51.133 45.1217 45.8598 40.8742 42.5112 38.4329C37.7248 34.9441 33.317 31.3589 31.6719 33.2162C30.0878 35.0101 28.7342 36.9958 27.6427 39.1268C27.4664 39.4898 27.4315 39.9056 27.5448 40.2931C27.6582 40.6805 27.9116 41.0117 28.2556 41.2219C45.6946 52.2273 43.1166 55.0961 61.6747 69.3766C63.7074 70.9404 66.03 65.9856 67.8357 64.2577Z"
        fill="currentColor"
      />
      <path
        d="M53.5238 81.101C53.674 80.7609 53.8318 80.371 53.674 80.0249C53.5163 79.6787 53.1227 79.5101 52.7621 79.3777C42.5148 75.639 45.0071 74.8157 34.7538 71.0815C30.8628 69.6606 24.3878 67.7115 20.3646 66.7166C14.6182 65.2868 9.19035 63.6281 8.36107 65.9641C7.56643 68.2235 7.05719 70.5737 6.84523 72.9599C6.81785 73.3623 6.94123 73.7605 7.19126 74.0767C7.44129 74.3929 7.8 74.6043 8.19732 74.6697C28.4906 78.2985 27.1791 81.9318 49.7334 88.169C52.2062 88.8494 52.4992 83.3828 53.5238 81.101Z"
        fill="currentColor"
      />
      <path
        d="M47.5997 106.376C47.6252 106.005 47.6357 105.585 47.3743 105.312C47.1129 105.04 46.6787 105.022 46.2941 105.025C35.3828 105.085 37.4305 103.45 26.5251 103.511C22.3832 103.533 15.6318 103.962 11.5169 104.414C5.63234 105.072 -0.0329071 105.404 0.00014391 107.886C0.0375153 110.281 0.374152 112.661 1.00219 114.972C1.11628 115.36 1.37062 115.691 1.71544 115.9C2.06026 116.11 2.47075 116.183 2.86657 116.106C23.1479 112.448 23.1824 116.312 46.4955 114.314C49.0509 114.099 47.4299 108.873 47.5997 106.376Z"
        fill="currentColor"
      />
      <path
        d="M163.954 81.101C163.804 80.7609 163.653 80.371 163.804 80.0249C163.954 79.6787 164.355 79.5101 164.716 79.3777C174.969 75.639 172.477 74.8202 182.73 71.0815C186.622 69.6606 193.096 67.7115 197.121 66.7166C202.866 65.2868 208.293 63.6281 209.123 65.9641C209.918 68.2235 210.427 70.5737 210.64 72.9599C210.667 73.3623 210.544 73.7605 210.294 74.0767C210.044 74.3929 209.685 74.6043 209.288 74.6697C188.995 78.2985 190.306 81.9318 167.752 88.169C165.271 88.8494 164.98 83.3828 163.954 81.101Z"
        fill="currentColor"
      />
      <path
        d="M169.88 106.376C169.854 106.005 169.843 105.585 170.106 105.312C170.369 105.04 170.8 105.022 171.185 105.025C182.096 105.085 180.049 103.45 190.954 103.511C195.096 103.533 201.849 103.962 205.964 104.414C211.847 105.072 217.512 105.404 217.479 107.886C217.442 110.281 217.105 112.661 216.477 114.972C216.363 115.36 216.109 115.691 215.765 115.9C215.42 116.11 215.01 116.183 214.614 116.106C194.333 112.448 194.297 116.312 170.984 114.314C168.43 114.099 170.049 108.873 169.88 106.376Z"
        fill="currentColor"
      />
      <path
        d="M147.089 64.2577C146.819 63.9988 146.531 63.6963 146.54 63.3185C146.549 62.9407 146.858 62.6337 147.141 62.3763C155.238 55.0494 152.621 55.23 160.718 47.9031C163.792 45.1217 169.065 40.8742 172.414 38.4329C177.2 34.9441 181.608 31.3589 183.253 33.2162C184.838 35.01 186.192 36.9957 187.284 39.1268C187.46 39.4898 187.495 39.9056 187.382 40.2931C187.268 40.6805 187.015 41.0117 186.671 41.2219C169.228 52.2273 171.807 55.1006 153.249 69.3766C151.218 70.9404 148.895 65.9856 147.089 64.2577Z"
        fill="currentColor"
      />
      <path
        d="M130.333 51.7681C129.966 51.7018 129.559 51.6085 129.359 51.2879C129.159 50.9673 129.248 50.5354 129.344 50.1681C132.078 39.5857 129.993 41.1796 132.729 30.6016C133.765 26.5845 135.834 20.1305 137.288 16.2458C139.369 10.6934 141.08 5.27201 143.472 5.91319C145.779 6.5365 148 7.44664 150.082 8.6224C150.429 8.82791 150.687 9.15597 150.805 9.5422C150.923 9.92843 150.893 10.3449 150.721 10.71C142.205 29.524 145.937 30.4948 138.287 52.6471C137.452 55.0718 132.79 52.2151 130.333 51.7681Z"
        fill="currentColor"
      />
      <path
        d="M86.0331 156.274C67.825 146.395 57.0963 126.1 55.8478 105.386C56.4289 98.08 59.3821 91.1286 63.0384 84.7674C66.2383 79.1985 70.0377 73.92 74.7805 69.5928C90.0966 55.6238 114.533 53.7499 132.741 63.6295C150.95 73.5091 161.678 92.0908 162.927 112.804C162.663 112.828 163.191 114.495 162.927 114.518C162.346 121.824 159.393 128.775 155.736 135.137C152.536 140.705 148.737 145.984 143.994 150.311C128.678 164.28 104.241 166.154 86.0331 156.274Z"
        fill="currentColor"
      />
      <path
        d="M105.546 172.214C105.175 172.189 104.756 172.18 104.484 172.441C104.212 172.703 104.194 173.138 104.197 173.524C104.257 184.455 102.625 182.404 102.686 193.329C102.708 197.479 103.136 204.243 103.587 208.366C104.243 214.26 104.575 219.937 107.053 219.904C109.443 219.866 111.819 219.529 114.126 218.9C114.514 218.788 114.847 218.534 115.058 218.189C115.269 217.843 115.344 217.431 115.267 217.034C111.617 196.714 115.473 196.68 113.478 173.323C113.256 170.762 108.038 172.386 105.546 172.214Z"
        fill="currentColor"
      />
      <path
        d="M131.279 167.007C130.913 167.073 130.504 167.168 130.304 167.488C130.105 167.809 130.195 168.241 130.291 168.608C133.025 179.191 130.94 177.597 133.674 188.175C134.712 192.192 136.781 198.646 138.235 202.529C140.314 208.083 142.025 213.504 144.419 212.863C146.726 212.24 148.947 211.33 151.029 210.154C151.376 209.948 151.634 209.62 151.753 209.233C151.871 208.847 151.841 208.43 151.669 208.065C143.154 189.251 146.885 188.282 139.234 166.129C138.402 163.705 133.737 166.561 131.279 167.007Z"
        fill="currentColor"
      />
      <path
        d="M150.938 155.646C150.668 155.905 150.38 156.208 150.389 156.585C150.398 156.963 150.707 157.27 150.99 157.528C159.087 164.855 156.47 164.674 164.567 172.001C167.641 174.782 172.914 179.03 176.263 181.471C181.049 184.96 185.457 188.545 187.102 186.688C188.686 184.894 190.04 182.908 191.132 180.777C191.308 180.414 191.343 179.998 191.229 179.611C191.116 179.223 190.863 178.892 190.519 178.682C173.08 167.677 175.658 164.808 157.099 150.527C155.067 148.964 152.744 153.918 150.938 155.646Z"
        fill="currentColor"
      />
      <path
        d="M165.25 138.803C165.1 139.143 164.942 139.533 165.1 139.879C165.258 140.225 165.652 140.394 166.012 140.526C176.259 144.265 173.767 145.088 184.02 148.822C187.911 150.243 194.386 152.192 198.41 153.187C204.156 154.617 209.584 156.276 210.413 153.94C211.208 151.68 211.717 149.33 211.929 146.944C211.956 146.542 211.833 146.143 211.583 145.827C211.333 145.511 210.974 145.3 210.577 145.234C190.284 141.605 191.595 137.972 169.041 131.735C166.568 131.055 166.275 136.521 165.25 138.803Z"
        fill="currentColor"
      />
      <path
        d="M54.8203 138.803C54.9705 139.143 55.1208 139.533 54.9705 139.879C54.8203 140.225 54.4192 140.394 54.0586 140.526C43.8053 144.265 46.2976 145.084 36.0443 148.822C32.1518 150.243 25.6783 152.192 21.6536 153.187C15.9087 154.617 10.4808 156.276 9.65151 153.94C8.85654 151.68 8.3468 149.33 8.13417 146.944C8.10679 146.542 8.23017 146.143 8.4802 145.827C8.73023 145.511 9.08894 145.3 9.48626 145.234C29.7796 141.605 28.4681 137.972 51.0224 131.735C53.5028 131.055 53.7942 136.521 54.8203 138.803Z"
        fill="currentColor"
      />
      <path
        d="M71.6848 155.646C71.9552 155.905 72.2436 156.208 72.2346 156.585C72.2256 156.963 71.9161 157.27 71.6337 157.528C63.5362 164.855 66.1532 164.674 58.0558 172.001C54.982 174.782 49.7089 179.03 46.3602 181.471C41.5738 184.96 37.166 188.545 35.521 186.688C33.9363 184.894 32.5822 182.908 31.4903 180.777C31.314 180.414 31.2791 179.998 31.3924 179.611C31.5058 179.223 31.7592 178.892 32.1032 178.682C49.5466 167.677 46.9672 164.803 65.5253 150.527C67.5564 148.964 69.879 153.918 71.6848 155.646Z"
        fill="currentColor"
      />
      <path
        d="M88.4416 168.136C88.8082 168.202 89.2153 168.295 89.4151 168.616C89.615 168.937 89.5263 169.369 89.4302 169.736C86.6959 180.318 88.7812 178.724 86.0454 189.302C85.0088 193.319 82.9401 199.773 81.4859 203.658C79.4052 209.21 77.694 214.632 75.3023 213.991C72.9947 213.367 70.7741 212.457 68.6921 211.282C68.345 211.076 68.087 210.748 67.9689 210.362C67.8508 209.975 67.881 209.559 68.0536 209.194C76.5688 190.38 72.837 189.409 80.4868 167.257C81.3221 164.832 85.9838 167.689 88.4416 168.136Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
