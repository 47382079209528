import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ShareIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8 10H6C4.895 10 4 10.895 4 12V19C4 20.105 4.895 21 6 21H18C19.105 21 20 20.105 20 19V12C20 10.895 19.105 10 18 10H16M12 3V14M12 3L9.001 6M12 3L15 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
