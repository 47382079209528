import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PhoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7558 13.2442C9.4834 11.9718 8.5242 10.5472 7.88691 9.10185C7.75206 8.79626 7.83145 8.43846 8.06744 8.20247L8.95813 7.31287C9.68786 6.58314 9.68786 5.55107 9.05057 4.91378L7.77381 3.63702C6.92445 2.78766 5.54764 2.78766 4.69828 3.63702L3.98921 4.34609C3.18335 5.15195 2.8473 6.31452 3.06481 7.4673C3.60205 10.309 5.25292 13.4204 7.91627 16.0838C10.5796 18.7471 13.6911 20.398 16.5328 20.9353C17.6855 21.1528 18.8481 20.8167 19.654 20.0109L20.362 19.3029C21.2113 18.4535 21.2113 17.0767 20.362 16.2273L19.0863 14.9517C18.449 14.3144 17.4158 14.3144 16.7796 14.9517L15.7976 15.9348C15.5616 16.1708 15.2038 16.2502 14.8982 16.1153C13.4529 15.4769 12.0282 14.5167 10.7558 13.2442Z"
          stroke="currentColor"
          strokeWidth="2.17528"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
