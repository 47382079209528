import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CheckVerifiedFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9004 18.4534C10.1463 18.4209 10.3948 18.4876 10.5907 18.6385L11.4936 19.3313C11.792 19.5606 12.2072 19.5606 12.5048 19.3313L13.4419 18.6118C13.617 18.4776 13.8379 18.4184 14.0563 18.4476L15.2285 18.6018C15.6011 18.651 15.9604 18.4434 16.1047 18.0958L16.5557 17.0053C16.6399 16.8011 16.8016 16.6393 17.0059 16.5551L18.0963 16.1041C18.444 15.9607 18.6515 15.6006 18.6024 15.2279L18.454 14.0983C18.4215 13.8524 18.4881 13.6039 18.639 13.408L19.3318 12.5051C19.5611 12.2067 19.5611 11.7915 19.3318 11.4939L18.6124 10.5568C18.4781 10.3817 18.419 10.1608 18.4481 9.94239L18.6024 8.77024C18.6515 8.39758 18.444 8.03827 18.0963 7.89404L17.0059 7.44302C16.8016 7.35882 16.6399 7.19708 16.5557 6.99283L16.1047 5.90238C15.9613 5.55473 15.6011 5.34714 15.2285 5.39633L14.0563 5.55056C13.8379 5.58058 13.617 5.52138 13.4427 5.388L12.5057 4.66853C12.2072 4.43927 11.792 4.43927 11.4944 4.66853L10.5573 5.388C10.3823 5.52138 10.1613 5.58058 9.94292 5.55223L8.77077 5.398C8.39811 5.34881 8.03879 5.5564 7.89457 5.90404L7.44438 6.9945C7.35934 7.19792 7.19761 7.35965 6.99419 7.44469L5.90374 7.89487C5.55609 8.0391 5.34851 8.39842 5.39769 8.77107L5.55192 9.94323C5.58027 10.1617 5.52108 10.3826 5.38769 10.5568L4.66822 11.4939C4.43896 11.7923 4.43896 12.2075 4.66822 12.5051L5.38769 13.4422C5.52191 13.6173 5.5811 13.8382 5.55192 14.0566L5.39769 15.2288C5.34851 15.6014 5.55609 15.9607 5.90374 16.105L6.99419 16.556C7.19844 16.6402 7.36018 16.8019 7.44438 17.0062L7.8954 18.0966C8.03879 18.4443 8.39894 18.6519 8.7716 18.6027L9.9004 18.4534Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3331 10.8335L11.4144 13.7522L9.66449 12.0015"
          fill="currentColor"
        />
        <path
          d="M14.3331 10.8335L11.4144 13.7522L9.66449 12.0015"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
