import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M15.454 13.151L12 12V6M21 12C21 16.9706 16.9706 21 12 21C7.02947 21 3.00003 16.9706 3.00003 12C3.00003 7.02944 7.02947 3 12 3C16.9706 3 21 7.02944 21 12Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
