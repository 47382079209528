import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TaxiIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.69491 8.60459L9.39049 6.80997C9.43379 6.69874 9.50913 6.60325 9.60673 6.53587C9.70434 6.4685 9.8197 6.43235 9.93786 6.43213H11.5821C11.7026 6.43231 11.8201 6.46984 11.9189 6.53969C12.0177 6.60953 12.0929 6.70832 12.1345 6.82275L12.7791 8.60459L12.779 11.7143L17.9158 11.7143M17.9158 11.7143L14.9263 8.88282C14.7375 8.70373 14.4883 8.60425 14.2295 8.60466H6.74063C6.54489 8.60505 6.35341 8.66251 6.18912 8.77017C6.02483 8.87782 5.8947 9.03111 5.81432 9.21167L4.69726 11.7143M17.9158 11.7143L22 12.6088V14.9866C21.9999 15.2597 21.8926 15.5216 21.7017 15.7148C21.5108 15.9079 21.2519 16.0165 20.9819 16.0166H20.1768M4.69726 11.7143H3.18905C2.91907 11.7144 2.66017 11.8229 2.46927 12.0161C2.27836 12.2092 2.17106 12.4711 2.17095 12.7443L2 14.9866C2.00011 15.2597 2.10741 15.5216 2.29832 15.7148C2.48923 15.9079 2.74812 16.0165 3.01811 16.0166H4.53895M4.69726 11.7143L10.7368 11.7143V8.60466M4.53895 16.0166C4.53895 15.5455 4.72393 15.0937 5.05321 14.7605C5.38248 14.4274 5.82907 14.2403 6.29474 14.2403M4.53895 16.0166C4.53895 16.9976 5.32514 17.7929 6.29484 17.7929C7.26453 17.7929 8.05053 16.9976 8.05053 16.0166M4.53895 16.0166C4.53895 15.0355 5.32504 14.2403 6.29474 14.2403M6.29474 14.2403C6.7604 14.2403 7.20699 14.4274 7.53627 14.7605C7.86554 15.0937 8.05053 15.5455 8.05053 16.0166M6.29474 14.2403C7.26443 14.2403 8.05053 15.0355 8.05053 16.0166M8.05053 16.0166H16.6653M16.6653 16.0166C16.6653 15.5455 16.8502 15.0937 17.1795 14.7605C17.5088 14.4274 17.9554 14.2403 18.4211 14.2403M16.6653 16.0166C16.6653 16.9976 17.4514 17.7929 18.4211 17.7929C19.3908 17.7929 20.1768 16.9976 20.1768 16.0166M16.6653 16.0166C16.6653 15.0355 17.4514 14.2403 18.4211 14.2403M18.4211 14.2403C18.8867 14.2403 19.3333 14.4274 19.6626 14.7605C19.9919 15.0937 20.1768 15.5455 20.1768 16.0166M18.4211 14.2403C19.3907 14.2403 20.1768 15.0355 20.1768 16.0166"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
