import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ArrowInCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={25} viewBox="0 0 24 25" {...props}>
      <g fill="none">
        <path
          d="M12 7.61111L16.2778 12.5M16.2778 12.5L12 17.3889M16.2778 12.5H7.72222M12 23.5C5.92433 23.5 1 18.5757 1 12.5C1 6.42433 5.92433 1.5 12 1.5C18.0757 1.5 23 6.42433 23 12.5C23 18.5757 18.0757 23.5 12 23.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
