import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 11C10.0851 11 8.517 9.43257 8.517 7.517H6.517C6.517 10.5374 8.98085 13 12 13V11ZM8.517 7.517C8.517 5.59159 10.0949 4 12 4V2C8.97106 2 6.517 4.50641 6.517 7.517H8.517ZM12 4C13.9149 4 15.483 5.56743 15.483 7.483H17.483C17.483 4.46257 15.0191 2 12 2V4ZM15.483 7.483C15.483 9.40841 13.9051 11 12 11V13C15.0289 13 17.483 10.4936 17.483 7.483H15.483ZM19 20H5V22H19V20ZM5 20H5H3C3 21.1023 3.89771 22 5 22V20ZM5 20V19H3V20H5ZM5 19C5 17.3523 6.35228 16 8 16V14C5.24771 14 3 16.2477 3 19H5ZM8 16H16V14H8V16ZM16 16C17.6477 16 19 17.3523 19 19H21C21 16.2477 18.7523 14 16 14V16ZM19 19V20H21V19H19ZM19 20V22C20.1023 22 21 21.1023 21 20H19Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
