import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.51536 3.31212L19.5707 10.0935C21.0955 10.95 21.0955 13.05 19.5707 13.9065L7.51536 20.6879C5.96264 21.5621 4 20.4959 4 18.7799V5.22012C4 3.5041 5.96264 2.43787 7.51536 3.31212V3.31212Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
