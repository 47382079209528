import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LinkAlternateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M10.3049 11.2217C9.23561 12.3019 8.92631 13.9218 9.52235 15.32C10.1184 16.7182 11.5012 17.6167 13.021 17.5932L16.7623 17.5369C18.4168 17.5142 19.8598 16.4072 20.3103 14.815C20.7607 13.2228 20.1115 11.5239 18.7141 10.6378"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6957 12.7665C14.765 11.6863 15.0743 10.0665 14.4783 8.66827C13.8822 7.27007 12.4994 6.37158 10.9796 6.39506L7.23834 6.45138C5.58383 6.47404 4.1408 7.58107 3.69036 9.17325C3.23992 10.7654 3.88911 12.4644 5.28653 13.3504"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
