import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TrolleyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.36125 14.4314L5.64868 6.34717H19.7352C20.4616 6.34717 20.9937 7.02885 20.8175 7.73396L19.3135 13.7497C19.0848 14.6634 18.3083 15.3351 17.3711 15.4288L9.76665 16.1897C8.63312 16.3024 7.59666 15.5448 7.36125 14.4314Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.64862 6.34704L4.92343 3H3"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1833 20.0333C17.9579 20.0333 17.7749 20.2163 17.7772 20.4416C17.7772 20.667 17.9601 20.85 18.1855 20.85C18.4109 20.85 18.5938 20.667 18.5938 20.4416C18.5927 20.2163 18.4098 20.0333 18.1833 20.0333"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.79818 20.0332C8.57281 20.0332 8.38984 20.2162 8.39207 20.4416C8.38984 20.668 8.57393 20.851 8.7993 20.851C9.02466 20.851 9.20763 20.668 9.20763 20.4427C9.20763 20.2162 9.02466 20.0332 8.79818 20.0332"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
