import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatBubbleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13 17H18C19.657 17 21 15.657 21 14V6C21 4.343 19.657 3 18 3H6C4.343 3 3 4.343 3 6V14C3 15.657 4.343 17 6 17H8V21L13 17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
