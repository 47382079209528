import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FilledStarIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="15" height="15" rx="7.5" fill="#4E7FFF" />
      <path
        d="M5.36309 5.96332C5.49266 5.92648 5.61941 5.20945 5.65603 5.08333C5.83771 4.47824 5.77574 3.87882 6.03629 3.30349C6.09826 3.16745 6.19121 3.02149 6.33909 3.00307C6.65316 2.96481 6.8278 3.29215 6.95878 3.52314C7.33763 4.19057 7.71366 4.86085 8.07843 5.53679C8.07843 5.53679 10.5811 5.06349 11.7698 5.31998C12.9584 5.57647 9.15725 7.74458 9.15725 7.74458C9.15725 7.74458 11.7979 11.5877 10.9262 11.565C9.37555 11.5253 7.75028 9.91694 7.75028 9.91694C7.75028 9.91694 4.39413 12.5768 4.3392 11.8852C4.27442 11.0747 5.31943 8.30432 5.31943 8.30432C5.31943 8.30432 1.24641 7.26419 2.12383 6.84899C3.10265 6.38703 5.32647 5.97041 5.36309 5.96191V5.96332Z"
        fill="white"
      />
    </SvgIcon>
  );
}
