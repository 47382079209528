import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DotsHorizontalInRoundedSquareIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <rect
          x="3"
          y="3"
          width="18"
          height="18"
          rx="5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2504 12.0002C16.2504 12.1383 16.1384 12.2503 16.0003 12.2503C15.8621 12.2503 15.7502 12.1383 15.7502 12.0002C15.7502 11.8621 15.8621 11.7501 16.0003 11.7501V11.7501C16.1384 11.7501 16.2504 11.8621 16.2504 12.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25025 12.0002C8.25025 12.1383 8.13828 12.2503 8.00015 12.2503C7.86202 12.2503 7.75004 12.1383 7.75004 12.0002C7.75004 11.8621 7.86202 11.7501 8.00015 11.7501V11.7501C8.13828 11.7501 8.25025 11.8621 8.25025 12.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2503 12.0002C12.2503 12.1383 12.1383 12.2503 12.0002 12.2503C11.8621 12.2503 11.7501 12.1383 11.7501 12.0002C11.7501 11.8621 11.8621 11.7501 12.0002 11.7501V11.7501C12.1383 11.7501 12.2503 11.8621 12.2503 12.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
