import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MicrophoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M19.4 11.64C19.4 15.6164 16.1765 18.84 12.2 18.84M12.2 18.84C8.22356 18.84 5.00001 15.6164 5.00001 11.64M12.2 18.84L12.2 21M12.2 14.6465C10.4461 14.646 9.02436 13.2242 9.0238 11.4703V6.17623C9.02436 4.42228 10.4461 3.00057 12.2 3C13.954 3.00057 15.3757 4.42228 15.3763 6.17623V11.4703C15.3765 12.3128 15.042 13.1208 14.4463 13.7165C13.8505 14.3123 13.0425 14.6468 12.2 14.6465Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
