import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CalendarWithTick(props: SvgIconProps) {
  return (
    <SvgIcon
      width="122"
      height="138"
      viewBox="0 0 122 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M120.73 59.3825C117.697 38.9904 105.026 20.2514 87.2377 9.84668C69.4489 -0.558031 46.9102 -2.4056 27.6637 4.9563C27.6637 4.9563 27.6961 4.90361 27.6637 4.91172C20.3506 8.71624 20.9377 9.88718 16.4916 15.6527C9.9883 24.0843 5.55831 34.0555 3.12871 44.4197C1.02709 53.4225 0.541176 62.7251 0.399449 71.9711C0.261771 80.7794 0.468272 89.8269 3.72395 98.0153C5.74862 103.141 8.93546 107.739 12.4543 111.994C27.279 129.878 51.4576 142.231 73.7979 135.898C87.5657 131.997 98.5597 121.778 108.387 111.37C112.534 106.978 116.664 102.359 118.916 96.7431C120.94 91.6177 121.301 85.9859 121.487 80.4675C121.734 73.4338 121.766 66.3554 120.73 59.3825Z"
        fill="#D1DBF2"
      />
      <path
        d="M25.0451 47.7195C25.0462 47.806 25.0471 47.8897 25.0471 47.9701L25.0471 95.5109C25.0471 99.4801 27.4096 99.3543 30.7278 99.1775C31.4645 99.1383 32.2484 99.0965 33.064 99.0965C34.8166 99.0965 37.4135 99.0655 40.2969 98.9831L58.9851 98.6902C66.8242 98.6902 72.4577 98.8417 76.8444 98.9597C79.6414 99.0349 81.9316 99.0965 83.9636 99.0965C90.9415 99.0965 90.8889 98.0985 90.7447 95.3622C90.722 94.93 90.6969 94.4544 90.6969 93.9326C90.6969 92.3394 90.6213 90.0899 90.5331 87.4646C90.4094 83.7856 90.261 79.3686 90.261 74.9846C90.261 73.1618 90.3835 70.1201 90.4987 67.2623C90.6007 64.7306 90.6969 62.3432 90.6969 61.0757L90.6969 47.9701C90.6969 43.833 87.9403 44.0774 84.0666 44.4207L83.9636 44.4298L62.4975 44.4298C57.0513 44.4298 49.0181 44.2234 42.1248 44.0462C36.9447 43.9131 32.4082 43.7965 30.0971 43.7965C24.9997 43.7965 25.0275 46.1943 25.0451 47.7195Z"
        fill="white"
      />
      <path
        d="M97.6043 89.0742C97.7261 91.3867 97.7825 92.4576 93.5853 92.7339C93.5689 92.396 93.555 92.0338 93.555 91.646C93.555 90.0528 93.4794 87.8033 93.3912 85.178C93.2676 81.4991 93.1191 77.082 93.1191 72.698C93.1191 70.8752 93.2417 67.8335 93.3568 64.9758C93.4588 62.4441 93.555 60.0566 93.555 58.7891L93.555 45.6835C93.555 41.5465 90.7984 41.7908 86.9247 42.1341L86.8217 42.1432L65.3557 42.1432C59.9096 42.1432 51.8767 41.9368 44.9835 41.7596C39.8034 41.6265 35.2664 41.5099 32.9552 41.5099C32.5781 41.5099 32.2291 41.523 31.906 41.5479L31.9047 41.4315C31.887 39.9062 31.8592 37.5085 36.9567 37.5085C39.2678 37.5085 43.8042 37.6251 48.9843 37.7582C55.8775 37.9353 63.911 38.1418 69.3571 38.1418L90.8231 38.1418L90.9261 38.1327C94.7999 37.7893 97.5565 37.545 97.5565 41.6821L97.5565 54.7877C97.5565 56.0551 97.4603 58.4424 97.3583 60.974C97.2431 63.8317 97.1205 66.8738 97.1205 68.6966C97.1205 73.0806 97.269 77.4976 97.3926 81.1766C97.4809 83.8019 97.5565 86.0514 97.5565 87.6446C97.5565 88.1664 97.5815 88.642 97.6043 89.0742Z"
        fill="white"
      />
      <path
        d="M34.1105 58.0932C34.1105 56.7646 36.1455 56.8642 36.4445 56.8642C37.7232 56.9139 38.6242 56.8244 39.1423 56.8642C39.79 56.9139 45.2262 56.7646 45.824 56.7646C46.3023 56.7646 47.1711 56.8642 47.1711 58.3752L47.1711 59.8497C47.1213 61.1932 47.1711 67.5448 47.1711 68.3892C47.1711 69.8322 45.5746 69.6537 45.3252 69.6537C43.6857 69.6537 42.7791 69.8322 40.785 69.8322H35.4467C34.1105 69.8322 34.0773 68.4781 34.1105 67.997L34.1105 61.5266V58.0932Z"
        fill="#D1DBF2"
      />
      <path
        d="M34.1105 74.7905C34.1105 73.4619 36.1455 73.5614 36.4445 73.5614C37.7232 73.6112 38.6242 73.5216 39.1423 73.5614C39.79 73.6112 45.2262 73.4619 45.824 73.4619C46.3023 73.4619 47.1711 73.5614 47.1711 75.0725L47.1711 76.547C47.1213 77.8905 47.1711 84.242 47.1711 85.0865C47.1711 86.5295 45.5746 86.351 45.3252 86.351C43.6857 86.351 42.7791 86.5295 40.785 86.5295H35.4467C34.1105 86.5295 34.0773 85.1753 34.1105 84.6943L34.1105 78.2239V74.7905Z"
        fill="#D1DBF2"
      />
      <path
        d="M50.8077 58.0932C50.8077 56.7646 52.8428 56.8642 53.1417 56.8642C54.4204 56.9139 55.3215 56.8244 55.8396 56.8642C56.4873 56.9139 61.9235 56.7646 62.5213 56.7646C62.9996 56.7646 63.8684 56.8642 63.8684 58.3752L63.8684 59.8497C63.8185 61.1932 63.8684 67.5448 63.8683 68.3892C63.8683 69.8322 62.2719 69.6537 62.0224 69.6537C60.383 69.6537 59.4764 69.8322 57.4823 69.8322H52.1439C50.8077 69.8322 50.7745 68.4781 50.8077 67.997L50.8077 61.5266V58.0932Z"
        fill="#D1DBF2"
      />
      <path
        d="M50.8077 74.7905C50.8077 73.4619 52.8428 73.5614 53.1417 73.5614C54.4204 73.6112 55.3215 73.5216 55.8396 73.5614C56.4873 73.6112 61.9235 73.4619 62.5213 73.4619C62.9996 73.4619 63.8684 73.5614 63.8684 75.0725L63.8684 76.547C63.8185 77.8905 63.8684 84.242 63.8683 85.0865C63.8683 86.5295 62.2719 86.351 62.0224 86.351C60.383 86.351 59.4764 86.5295 57.4823 86.5295H52.1439C50.8077 86.5295 50.7745 85.1753 50.8077 84.6943L50.8077 78.2239V74.7905Z"
        fill="#D1DBF2"
      />
      <path
        d="M67.504 58.0932C67.504 56.7646 69.5391 56.8642 69.838 56.8642C71.1167 56.9139 72.0178 56.8244 72.5359 56.8642C73.1836 56.9139 78.6197 56.7646 79.2176 56.7646C79.6959 56.7646 80.5647 56.8642 80.5647 58.3752L80.5647 59.8497C80.5148 61.1932 80.5646 67.5448 80.5646 68.3892C80.5646 69.8322 78.9681 69.6537 78.7187 69.6537C77.0793 69.6537 76.1727 69.8322 74.1785 69.8322H68.8402C67.504 69.8322 67.4708 68.4781 67.504 67.997L67.504 61.5266V58.0932Z"
        fill="#D1DBF2"
      />
      <path
        d="M67.504 74.7905C67.504 73.4619 69.5391 73.5614 69.838 73.5614C71.1167 73.6112 72.0178 73.5216 72.5359 73.5614C73.1836 73.6112 78.6197 73.4619 79.2176 73.4619C79.6959 73.4619 80.5647 73.5614 80.5647 75.0725L80.5647 76.547C80.5148 77.8905 80.5646 84.242 80.5646 85.0865C80.5646 86.5295 78.9681 86.351 78.7187 86.351C77.0793 86.351 76.1727 86.5295 74.1785 86.5295H68.8402C67.504 86.5295 67.4708 85.1753 67.504 84.6943L67.504 78.2239V74.7905Z"
        fill="#D1DBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.8038 60.8735C77.0716 61.0317 77.509 61.4414 77.1172 61.8149C76.7363 62.178 76.2294 62.7837 75.9228 63.15C75.8351 63.2548 75.7638 63.34 75.7165 63.3943C75.5039 63.6387 73.1253 66.3014 72.6959 66.76C72.6552 66.8373 72.5207 66.9236 72.2682 66.8112C72.2564 66.8059 72.2448 66.7998 72.2334 66.793C72.1939 66.7825 72.1656 66.757 72.1334 66.7239C71.8076 66.3886 71.3078 65.8231 70.9839 65.4566C70.8726 65.3306 70.782 65.2281 70.7264 65.1666C70.6637 65.0972 70.5831 64.9987 70.4881 64.8826C70.2539 64.5965 69.9325 64.2038 69.5779 63.8782C69.1792 63.512 69.6089 63.0942 69.8737 62.9311C69.9589 62.8721 70.4447 62.6571 70.5178 62.7149C70.9032 62.9598 71.7677 64.0104 71.9012 64.1795C71.9594 64.2532 72.2153 64.5602 72.4978 64.8987C72.7934 64.5308 73.0712 64.185 73.1314 64.1057C73.2618 63.9341 75.775 60.9213 76.1558 60.6693C76.2278 60.6102 76.7175 60.8161 76.8038 60.8735Z"
        fill="#4E7FFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1984 64.9627C84.1984 59.2842 81.0133 53.3809 75.3348 53.3809C69.6563 53.3809 63.8711 54.4445 63.8711 64.9627C63.8711 70.6412 69.1893 73.7082 74.2711 73.7082C79.9496 73.7082 83.6075 69.8082 84.1984 64.9627ZM74.2711 71.8173C78.8675 71.8173 81.7805 68.7732 82.307 64.8464C82.2855 62.3676 81.5756 59.9098 80.339 58.1153C79.1086 56.3296 77.4273 55.2718 75.3348 55.2718C72.515 55.2718 70.2117 55.5646 68.5915 56.7525C67.1057 57.8417 65.762 60.0333 65.762 64.9627C65.762 67.1364 66.7521 68.7889 68.3014 69.9533C69.8944 71.1504 72.081 71.8173 74.2711 71.8173Z"
        fill="#4E7FFF"
      />
    </SvgIcon>
  );
}
