import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatWithHandIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="114"
      height="89"
      viewBox="0 0 114 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05441 0.000123406C2.13084 -0.00280873 0.857368 -0.00474977 0.857368 6.47451V33.4506V55.3911C0.635927 58.8681 0.790936 65.8219 3.1825 65.8219H45.3577C48.8464 65.8219 52.451 65.7476 56.068 65.638C56.0265 65.058 56.0054 64.4674 56.0054 63.8666C56.0054 34.262 72.2883 31.2683 88.271 31.2683C88.5401 31.2683 88.8072 31.273 89.0723 31.2823C89.0878 27.8603 89.1293 24.7309 89.2125 22.3005V8.63261C89.4339 5.75515 89.0796 0.000249503 85.8908 0.000249503C85.0573 0.000249503 84.2689 0.102076 83.1359 0.248422C78.8513 0.80186 69.6375 1.99199 34.4057 0.719626C33.179 0.675324 30.0999 0.617294 26.2554 0.544842C19.2434 0.412693 9.6855 0.232565 4.17899 0.000249503C4.13717 0.000249553 4.09564 0.000186252 4.05441 0.000123406Z"
        fill="#D1DBF2"
      />
      <path
        d="M45.3576 53.5982C40.6741 77.6544 23.8915 82.2492 16.0857 81.5396C25.0269 68.3406 22.6497 55.461 20.3434 50.671C23.7496 50.0323 38.4388 52.3563 45.3576 53.5982Z"
        fill="#D1DBF2"
      />
      <path
        d="M113.877 66.8699C112.388 79.0808 103.17 88.909 88.8595 88.909C76.053 88.909 62.6509 81.1801 62.6509 66.8699C62.6509 40.3635 77.2298 37.6831 91.5399 37.6831C105.85 37.6831 113.877 52.5598 113.877 66.8699Z"
        fill="#4E7FFF"
      />
      <path
        d="M80.513 72.1179V64.0026C80.513 63.256 79.907 62.6501 79.1604 62.6501H76.4769C75.7303 62.6501 75.1244 63.256 75.1244 64.0026V72.1179C75.1244 72.8645 75.7303 73.4705 76.4769 73.4705H79.1604C79.907 73.4705 80.513 72.8645 80.513 72.1179ZM80.513 72.1179H82.5431C82.9841 72.1179 83.4236 72.172 83.8497 72.2802L87.4191 73.1756C88.1941 73.3704 89.0016 73.3893 89.7833 73.2324L93.7314 72.4398C94.7756 72.2302 95.7332 71.7149 96.4852 70.9602L99.2769 68.1563C100.074 67.357 100.074 66.0599 99.2769 65.2591C98.5587 64.5382 97.4226 64.4571 96.6097 65.0684L93.3554 67.5193C92.8888 67.8709 92.3221 68.0603 91.7391 68.0603H88.5958H90.5962C91.7229 68.0603 92.6372 67.1433 92.6372 66.0112V65.6013C92.6372 64.6613 92.0002 63.8417 91.0913 63.6144L88.0034 62.8394C87.5016 62.7136 86.9863 62.6501 86.4696 62.6501C85.2212 62.6501 83.9998 63.0207 82.9611 63.7172L80.513 65.3552M91.7418 50.0186L91.2698 50.5231L90.7964 50.0186C89.759 48.9122 88.0386 48.8216 86.8916 49.8117C85.7189 50.8234 85.5796 52.5776 86.544 53.7922C87.9236 55.5289 89.5047 57.2439 91.2698 58.5924C93.0795 57.2101 94.6945 55.4437 96.0984 53.6624C97.01 52.5059 96.822 50.8274 95.7102 49.8658L95.6588 49.8211C94.501 48.8216 92.7792 48.9122 91.7418 50.0186Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
