import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartWithIDIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.10924 18.4816C6.68996 17.1946 3 13.9815 3 10.0666C3 7.6233 4.85198 5 7.61217 5C9.19053 5 10.2271 5.7967 10.8261 6.50652C11.4251 5.7967 12.4616 5 14.04 5C16.8002 5 18.6522 7.6233 18.6522 10.0666C18.6522 10.3378 18.6345 10.6056 18.6007 10.8696M12.3913 13.6087V19.087M15.5217 14V18.6957H18.6522C19.9488 18.6957 21 17.6445 21 16.3478C21 15.0512 19.9488 14 18.6522 14H15.5217Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
