import { SvgIconProps } from "@mui/material/SvgIcon";
import { convertPxToRem } from "../../utils";
import FaceIconBrand from "./FaceIconBrand";

export default function FaceIconBrandLarge(props: SvgIconProps) {
  return (
    <FaceIconBrand
      {...props}
      sx={{ ...props.sx, fontSize: convertPxToRem(54) }}
    />
  );
}
