import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DiabetesCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M18.0584 10.1602C18.2384 10.3402 18.4284 10.5202 18.5984 10.7102C18.9784 11.1202 19.3084 11.5802 19.5384 12.1002C19.6684 12.3802 19.7484 12.6802 19.7384 12.9902C19.7184 13.8102 19.0884 14.5202 18.2784 14.6302C17.3384 14.7602 16.4884 14.1402 16.3284 13.2102C16.2784 12.9102 16.3284 12.6102 16.4184 12.3202C16.6284 11.7402 16.9684 11.2402 17.3784 10.7802C17.5684 10.5702 17.7684 10.3702 17.9884 10.2002C17.9984 10.1902 18.0184 10.1802 18.0484 10.1602H18.0584Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M23.2608 8.11035H12.8008V16.7004H23.2608V8.11035Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1989 21.89C15.066 21.89 15.7689 21.1871 15.7689 20.32C15.7689 19.4529 15.066 18.75 14.1989 18.75C13.3318 18.75 12.6289 19.4529 12.6289 20.32C12.6289 21.1871 13.3318 21.89 14.1989 21.89Z"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8708 21.89C22.7379 21.89 23.4408 21.1871 23.4408 20.32C23.4408 19.4529 22.7379 18.75 21.8708 18.75C21.0037 18.75 20.3008 19.4529 20.3008 20.32C20.3008 21.1871 21.0037 21.89 21.8708 21.89Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.99 5H14.08C11.83 5 10 6.83 10 9.08V18.88C10 22.3 12.14 25.21 15.15 26.37V23.74H21V26.34C23.97 25.16 26.07 22.27 26.07 18.88V9.08C26.07 6.83 24.24 5 21.99 5Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3588 25.6396H16.7188V31.9596H19.3588V25.6396Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7188 29.6699H19.3488"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
