import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PenIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M17.8821 9.86401L14.136 6.1179M6.32749 21H3V17.6725C3 17.4013 3.10747 17.1403 3.29989 16.9489L16.9476 3.30015C17.3478 2.89995 17.9957 2.89995 18.3949 3.30015L20.6988 5.60411C21.099 6.00431 21.099 6.6522 20.6988 7.05138L7.05112 20.7001C6.85972 20.8925 6.59872 21 6.32749 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
