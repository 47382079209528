import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SkippingRopeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3 11.5263H7.73684M6.78947 11.5263V17.2105C6.78947 17.7335 6.36505 18.1579 5.84211 18.1579H4.89474C4.37179 18.1579 3.94737 17.7335 3.94737 17.2105V11.5263M17.2105 11.5263V5.71895C17.2105 5.26421 17.58 4.89474 18.0347 4.89474H19.1053C19.6282 4.89474 20.0526 5.31916 20.0526 5.84211V11.5263M21 11.5263H16.2632M18.6316 11.5263V16.7368C18.6316 18.5681 17.1471 20.0526 15.3158 20.0526C13.4845 20.0526 12 18.5681 12 16.7368V6.31579C12 4.48453 10.5155 3 8.68421 3C6.85295 3 5.36842 4.48453 5.36842 6.31579V11.5263"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
