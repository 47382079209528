import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FaceMaskIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.4998 13.2521H14.5002M9.4998 10.2515H14.5002M18.3 9.25156H18.4998C19.8806 9.25156 21 10.3709 21 11.7518C21 13.1326 19.8806 14.252 18.4998 14.252H18.3M5.7 14.252H5.5002C4.11938 14.252 3 13.1326 3 11.7518C3 10.3709 4.11938 9.25156 5.5002 9.25156H5.7M13.089 5.17096L17.0445 6.43096C17.7904 6.66769 18.2979 7.35927 18.3 8.14186V15.3617C18.3 16.1459 17.792 16.8398 17.0445 17.0771L13.089 18.3326C12.3806 18.5584 11.6194 18.5584 10.911 18.3326L6.96 17.0771C6.21071 16.8414 5.70074 16.1471 5.7 15.3617V8.14186C5.70074 7.35639 6.21071 6.6621 6.96 6.42646L10.9155 5.16646C11.623 4.94299 12.3824 4.94456 13.089 5.17096Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
