import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ManuscriptIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M19 21C20.105 21 21 20.105 21 19V13C21 12.448 20.552 12 20 12H17M19 21C17.895 21 17 20.105 17 19V12M19 21L5 21C3.895 21 3 20.105 3 19V5C3 4.448 3.448 4 4 4H16C16.552 4 17 4.448 17 5V12M7 8H13M7 11.87H13M7 16.06H10"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
