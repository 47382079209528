import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FemaleSexIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2.97656C5.73959 2.97656 4.3125 4.40365 4.3125 6.16406C4.3125 7.92447 5.73959 9.35156 7.5 9.35156C9.26041 9.35156 10.6875 7.92447 10.6875 6.16406C10.6875 4.40365 9.26041 2.97656 7.5 2.97656ZM11.8125 6.16406C11.8125 8.35515 10.1784 10.1646 8.0625 10.4402V11.8517H9.68652C9.99718 11.8517 10.249 12.1035 10.249 12.4142C10.249 12.7248 9.99718 12.9767 9.68652 12.9767H8.0625V14.2891C8.0625 14.5997 7.81066 14.8516 7.5 14.8516C7.18934 14.8516 6.9375 14.5997 6.9375 14.2891V12.9767H5.31152C5.00086 12.9767 4.74902 12.7248 4.74902 12.4142C4.74902 12.1035 5.00086 11.8517 5.31152 11.8517H6.9375V10.4402C4.82155 10.1646 3.1875 8.35515 3.1875 6.16406C3.1875 3.78233 5.11827 1.85156 7.5 1.85156C9.88173 1.85156 11.8125 3.78233 11.8125 6.16406Z"
        fill="#494D55"
      />
    </SvgIcon>
  );
}
