import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ClipboardWithHeartLineIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.165 7.58422V3.62653C11.165 3.18937 10.8106 2.83499 10.3735 2.83499H8.79039M2.45808 10.7504L2.45808 12.3335C2.45808 12.7706 2.81246 13.125 3.24962 13.125H10.3735C10.8106 13.125 11.165 12.7706 11.165 12.3335V10.7504M4.8327 2.83499H3.24962C2.81246 2.83499 2.45808 3.18937 2.45808 3.62653L2.45808 7.58422M0.875 9.1673H3.91873C3.99373 9.16733 4.06233 9.12506 4.09604 9.05807L5.02267 7.20429C5.05795 7.13349 5.132 7.09051 5.21097 7.09499C5.28994 7.09947 5.35866 7.15054 5.38572 7.22487L6.64374 10.6855C6.67041 10.763 6.74214 10.816 6.82407 10.8189C6.90601 10.8217 6.9812 10.7737 7.01312 10.6981L8.22682 7.96733C8.25797 7.8974 8.32666 7.85172 8.4032 7.85002C8.47973 7.84833 8.55038 7.89093 8.58459 7.95941L9.13339 9.05807C9.1671 9.12506 9.2357 9.16733 9.3107 9.1673H12.7481M7.97511 2.04345C7.81113 1.54664 7.33385 1.22197 6.81154 1.25191C6.29019 1.22319 5.81434 1.54764 5.65062 2.04345H5.22847C5.00989 2.04345 4.8327 2.22064 4.8327 2.43922V4.0223C4.8327 4.24088 5.00989 4.41807 5.22847 4.41807H8.39462C8.6132 4.41807 8.79039 4.24088 8.79039 4.0223V2.43922C8.79039 2.22064 8.6132 2.04345 8.39462 2.04345H7.97511Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
