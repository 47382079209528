import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PresentationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21 4H2.99998M12 13V8.05001M8.39999 13V11.3503M15.6 13V9.69971M20.1 4V14.6938C20.1 15.7468 19.1937 16.6 18.075 16.6H5.92498C4.80628 16.6 3.89998 15.7468 3.89998 14.6938V4M12.9 16.6L14.7 20.2M11.1 16.6L9.29998 20.2"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
