import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function AddPeopleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="134"
      height="151"
      viewBox="0 0 134 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M132.777 64.8438C129.437 42.3872 115.484 21.751 95.8938 10.2929C76.3041 -1.16515 51.4836 -3.19976 30.2886 4.90746C30.2886 4.90746 30.3242 4.84943 30.2886 4.85836C22.2351 9.04806 22.8817 10.3375 17.9854 16.6868C10.8237 25.9719 5.94524 36.9526 3.26966 48.3661C0.955277 58.2804 0.420165 68.5248 0.26409 78.7068C0.112473 88.4069 0.33988 98.3703 3.92516 107.388C6.15481 113.032 9.66428 118.096 13.5394 122.781C29.8649 142.476 56.4914 156.08 81.0934 149.106C96.255 144.809 108.362 133.557 119.185 122.094C123.751 117.257 128.3 112.171 130.779 105.987C133.009 100.342 133.406 94.1404 133.611 88.0634C133.883 80.3176 133.918 72.5227 132.777 64.8438Z"
        fill="#BEEDE9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8071 43.6483C24.8071 37.7347 25.9718 37.7365 27.7311 37.7391L27.845 37.7392C31.697 37.9014 37.7189 37.9995 43.2075 38.0889C48.8294 38.1805 53.8919 38.2629 55.4905 38.3958C58.2574 38.6258 78.4694 38.2113 91.5789 37.9424C97.1819 37.8275 101.487 37.7392 102.579 37.7392C105.495 37.7392 105.82 42.9918 105.617 45.618V58.0928C105.545 60.1836 105.507 62.8412 105.491 65.755C103.843 65.2473 102.075 64.9736 100.192 64.9736C86.7145 64.9736 72.9843 67.498 72.9843 92.4614C72.9843 94.325 73.2257 96.0701 73.6735 97.6938C70.9142 97.7677 68.1737 97.8151 65.5073 97.8151H55.1032C50.6251 106.649 42.799 108.499 38.735 108.13C41.1799 104.529 42.3163 100.964 42.701 97.8151H26.9337C24.7463 97.8151 24.6046 91.4683 24.8071 88.2949V43.6483Z"
        fill="#00948D"
      />
      <path
        d="M121.353 94.9163C120.08 105.352 112.202 113.751 99.9729 113.751C89.0286 113.751 77.5752 107.146 77.5752 94.9163C77.5752 72.2641 90.0342 69.9734 102.264 69.9734C114.493 69.9734 121.353 82.687 121.353 94.9163Z"
        fill="white"
      />
      <path
        d="M107.476 94.3604C108.799 94.3197 108.597 93.4358 108.502 91.0825C108.459 90.6123 108.342 90.1614 108.062 90.0766C106.715 89.4529 98.4944 89.5553 97.8379 89.5871C97.1814 89.6189 92.1965 89.9584 91.4696 89.8952C90.888 89.8446 90.893 90.2183 90.893 90.2183C90.8806 90.4157 90.8816 91.6163 90.8899 92.8755C90.8982 94.1347 91.4191 94.2932 91.6786 94.2151C93.5967 94.4175 100.823 94.1663 101.813 94.2169C102.802 94.2674 105.432 94.4233 107.476 94.3604Z"
        fill="#00948D"
      />
      <path
        d="M102.026 84.2087C102.049 82.886 101.156 83.0444 98.8012 83.0261C98.3294 83.0461 97.8735 83.1406 97.7752 83.4171C97.0871 84.7317 96.7922 92.9479 96.7922 93.6052C96.7922 94.2625 96.8905 99.2579 96.7922 99.9809C96.7136 100.559 97.0871 100.572 97.0871 100.572C97.2837 100.594 98.4829 100.651 99.741 100.704C100.999 100.757 101.183 100.244 101.117 99.9809C101.412 98.0748 101.51 90.8445 101.609 89.8586C101.707 88.8727 101.99 86.2535 102.026 84.2087Z"
        fill="#00948D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6231 66.592C57.6183 66.592 60.836 63.1613 61.3558 58.8989C61.3558 53.9037 58.5539 48.7107 53.5587 48.7107C48.5635 48.7107 43.4745 49.6463 43.4745 58.8989C43.4745 63.8941 48.1527 66.592 52.6231 66.592ZM58.8317 68.3426C63.5512 70.8918 66.5342 76.1115 66.8576 81.4574H66.8569C57.8505 82.2507 49.0246 82.0534 40.0922 80.6547C39.7149 80.5952 39.2944 80.5106 39.068 80.2014C38.884 79.9482 38.8782 79.614 38.8962 79.3027C39.0097 77.3502 39.7932 75.4932 40.7715 73.7975C41.6002 72.3601 42.585 70.998 43.8141 69.8819C47.7832 66.2768 54.1121 65.7934 58.8317 68.3426Z"
        fill="white"
      />
    </SvgIcon>
  );
}
