import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ECGOnMonitorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M17 21H7M4 6V5C4 3.895 4.895 3 6 3H18C19.105 3 20 3.895 20 5V10H16L13.6 6.72L10.4 13.28L8 10H4C4 10.91 4 14.002 4 15C4 16.105 4.895 17 5.999 17C9.073 17 14.927 17 18.001 17C19.105 17 20 16.105 20 15V14"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
