import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CheckVerifiedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.799 10.601L11.2981 14.1019L9.19911 12.002M9.4821 19.7408C9.7771 19.7018 10.0751 19.7818 10.3101 19.9628L11.3931 20.7938C11.751 21.0687 12.249 21.0687 12.606 20.7938L13.73 19.9308C13.94 19.7698 14.205 19.6988 14.467 19.7338L15.8729 19.9188C16.3199 19.9778 16.7509 19.7288 16.9239 19.3118L17.4649 18.0038C17.5659 17.7588 17.7599 17.5648 18.0049 17.4638L19.3128 16.9229C19.7298 16.7509 19.9788 16.3189 19.9198 15.8719L19.7418 14.5169C19.7028 14.2219 19.7828 13.9239 19.9638 13.689L20.7948 12.606C21.0698 12.248 21.0698 11.75 20.7948 11.393L19.9318 10.269C19.7708 10.0591 19.6998 9.79406 19.7348 9.53207L19.9198 8.12611C19.9788 7.67912 19.7298 7.24813 19.3128 7.07514L18.0049 6.53415C17.7599 6.43315 17.5659 6.23916 17.4649 5.99417L16.9239 4.6862C16.7519 4.26921 16.3199 4.02022 15.8729 4.07922L14.467 4.26421C14.205 4.30021 13.94 4.22922 13.731 4.06922L12.607 3.20624C12.249 2.93125 11.751 2.93125 11.3941 3.20624L10.2701 4.06922C10.0601 4.22922 9.7951 4.30021 9.5331 4.26621L8.12714 4.08122C7.68015 4.02222 7.24917 4.27121 7.07617 4.6882L6.53619 5.99617C6.43419 6.24016 6.24019 6.43415 5.9962 6.53615L4.68824 7.07614C4.27125 7.24913 4.02226 7.68012 4.08125 8.12711L4.26625 9.53307C4.30025 9.79506 4.22925 10.0601 4.06925 10.269L3.20628 11.393C2.93129 11.751 2.93129 12.249 3.20628 12.606L4.06925 13.73C4.23025 13.9399 4.30125 14.2049 4.26625 14.4669L4.08125 15.8729C4.02226 16.3199 4.27125 16.7509 4.68824 16.9239L5.9962 17.4648C6.24119 17.5658 6.43519 17.7598 6.53619 18.0048L7.07717 19.3128C7.24917 19.7298 7.68115 19.9788 8.12814 19.9198L9.4821 19.7408Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
