import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ThumbsUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M7.83893 10.3125V21M7.83893 10.3125L10.4666 3.70932C10.6934 3.13949 11.3348 2.85628 11.9087 3.07257L12.6278 3.34359C13.9423 3.83903 14.8125 5.09692 14.8125 6.50173V10.3125H18.1861C19.0522 10.3125 19.87 10.7115 20.403 11.3941C20.9359 12.0768 21.1247 12.9669 20.9147 13.8071L19.649 18.8696C19.336 20.1217 18.2111 21 16.9205 21H4.6875C3.75552 21 3 20.2445 3 19.3125V12C3 11.068 3.75552 10.3125 4.6875 10.3125L7.83893 10.3125Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
