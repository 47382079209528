import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function GlobeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M4.01 7.85799C8.902 9.36899 15.098 9.36899 19.989 7.85799M19.99 16.142C15.098 14.631 8.902 14.631 4.011 16.142M12 3C16.982 3 21 7.018 21 12C21 16.982 16.982 21 12 21C7.018 21 3 16.982 3 12C3 7.018 7.018 3 12 3ZM10.168 4.06C7.278 8.885 7.278 15.116 10.168 19.941C11.014 21.354 12.987 21.354 13.833 19.941C16.723 15.116 16.723 8.885 13.833 4.06C12.986 2.647 11.014 2.647 10.168 4.06Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
