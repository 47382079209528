import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BloodDropsCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.5 20.5116C35.4535 29.093 28.9753 36 18.9186 36C9.9186 36 0.5 30.5683 0.5 20.5116C0.5 1.88372 10.7456 0 20.8023 0C30.859 0 36.5 10.4549 36.5 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M16.3671 13.8247C17.0852 14.523 17.8236 15.195 18.5078 15.9196C20.0049 17.5072 21.292 19.2398 22.193 21.2227C22.6875 22.3097 23.0194 23.4362 22.9991 24.6418C22.9314 27.7775 20.4452 30.5048 17.2546 30.933C13.549 31.4337 10.2161 29.0753 9.58611 25.5047C9.38289 24.3387 9.55224 23.2122 9.9587 22.1055C10.7784 19.8788 12.1332 17.9684 13.732 16.2095C14.4703 15.3992 15.2697 14.6548 16.1436 13.9828C16.2045 13.9367 16.2655 13.8972 16.3739 13.8181L16.3671 13.8247Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M23.0621 4.00377C23.4538 4.40279 23.8565 4.78676 24.2297 5.20085C25.0464 6.10807 25.7484 7.09811 26.2399 8.2312C26.5096 8.85233 26.6906 9.49605 26.6796 10.1849C26.6426 11.9768 25.2865 13.5353 23.5462 13.78C21.525 14.066 19.707 12.7184 19.3634 10.6781C19.2525 10.0118 19.3449 9.36806 19.5666 8.73563C20.0137 7.46326 20.7527 6.37158 21.6247 5.36648C22.0275 4.90346 22.4635 4.47808 22.9402 4.09411C22.9734 4.06776 23.0067 4.04517 23.0658 4L23.0621 4.00377Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
