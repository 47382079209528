import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SexualTransmittedDiseasesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M30.6805 16.9099H6.18545C5.53158 16.9099 5 16.3783 5 15.7244V13.6854C5 13.0316 5.53158 12.5 6.18545 12.5H30.6805C31.3344 12.5 31.866 13.0316 31.866 13.6854V15.7244C31.866 16.3783 31.3344 16.9099 30.6805 16.9099ZM6.18545 13.2437C5.94337 13.2437 5.7487 13.4409 5.7487 13.6805V15.7194C5.7487 15.9615 5.94586 16.1562 6.18545 16.1562H30.6805C30.9226 16.1562 31.1173 15.959 31.1173 15.7194V13.6805C31.1173 13.4384 30.9201 13.2437 30.6805 13.2437H6.18545Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M21.3776 30.9564H15.4853L5.7522 16.746L6.36862 16.3242L15.8797 30.2077H20.9808L30.3696 16.3267L30.9885 16.746L21.3776 30.9564Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M16.0951 30.6234L15.3514 30.5385C16.4794 20.7979 6.03751 16.9246 5.93269 16.8871L6.18726 16.1833C6.29956 16.2233 17.2905 20.3012 16.0951 30.6258V30.6234Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M20.7639 30.6234C19.5685 20.3013 30.5619 16.2208 30.6717 16.1809L30.9263 16.8847C30.8215 16.9221 20.3821 20.8079 21.5076 30.5361L20.7639 30.6209V30.6234Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M13.0595 21.949C12.5903 18.7995 14.8713 16.3787 14.9687 16.2764L15.5103 16.793C15.4903 16.8154 13.3864 19.059 13.8007 21.8367L13.0595 21.9465V21.949Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M23.8086 21.949L23.0673 21.8392C23.4816 19.059 21.3778 16.8179 21.3578 16.7954L21.8994 16.2788C21.9967 16.3811 24.2803 18.8019 23.8086 21.9515V21.949Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
