import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function GlassesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M10.3636 12.2834C11.2677 11.1927 12.7323 11.1927 13.6364 12.2834M3.81818 12.2727H3M21 12.2727H20.1818M9.40508 9.95856C10.6832 11.2366 10.6832 13.3088 9.40508 14.5869C8.127 15.865 6.05482 15.865 4.77675 14.5869C3.49866 13.3088 3.49866 11.2366 4.77675 9.95856C6.05483 8.68048 8.127 8.68048 9.40508 9.95856ZM19.2232 9.95856C20.5013 11.2366 20.5013 13.3088 19.2232 14.5869C17.9452 15.865 15.873 15.865 14.5949 14.5869C13.3168 13.3088 13.3168 11.2366 14.5949 9.95856C15.873 8.68048 17.9452 8.68048 19.2232 9.95856Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
