import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MessageIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="116"
      height="131"
      viewBox="0 0 116 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M114.891 56.3021C112.002 36.879 99.9342 19.0306 82.9908 9.12029C66.0473 -0.789978 44.5797 -2.54975 26.2478 4.4623C26.2478 4.4623 26.2787 4.41212 26.2478 4.41984C19.2822 8.04357 19.8415 9.15886 15.6066 14.6504C9.41241 22.6813 5.19294 32.1786 2.87879 42.0503C0.877052 50.6253 0.414226 59.4859 0.279234 68.2924C0.148099 76.6822 0.344787 85.2997 3.44575 93.099C5.3742 97.9808 8.4096 102.361 11.7613 106.413C25.8814 123.447 48.9111 135.214 70.1897 129.182C83.3032 125.466 93.7747 115.733 103.135 105.819C107.085 101.635 111.019 97.236 113.163 91.8872C115.092 87.0054 115.435 81.6412 115.613 76.3851C115.848 69.6856 115.879 62.9437 114.891 56.3021Z"
        fill="#E1E8F6"
      />
      <path
        d="M20.6576 46.6491L51.3151 23.3789C52.7854 22.2629 54.8127 22.2401 56.3078 23.3227L88.5245 46.6521C89.6131 47.4404 90.2577 48.7029 90.2577 50.047L90.2577 87.3083C90.2577 89.6233 88.381 91.4999 86.0661 91.4999L23.1918 91.4999C20.8768 91.4999 19.0002 89.6233 19.0002 87.3083L19.0002 49.9879C19.0002 48.677 19.6134 47.4416 20.6576 46.6491Z"
        fill="#A3B7E4"
      />
      <path
        d="M49.1815 19.9308L51.972 17.8113C52.8861 17.117 54.9993 17.0862 56.002 17.7526L59.2794 19.9308L62.9147 23.1137L68.9735 27.8345L75.8401 33.7674L82.976 39.5089L88.5534 44.7942C89.3436 45.543 88.2257 46.4269 86.4574 46.4516L81.1083 46.5262L70.6557 46.6538L61.4337 46.5262L51.8448 46.6538H43.3666L33.5633 46.5262L25.7542 46.6538L21.1429 46.5856C19.322 46.5586 18.2132 45.6262 19.1099 44.876L24.6662 40.2278L33.5633 33.0656L38.8143 28.4086L44.873 23.5603L49.1815 19.9308Z"
        fill="#D1DBF2"
      />
      <path
        d="M20.6609 43.9997C19.0956 43.9975 18.0593 43.9961 18.0593 48.8195V85.2346C17.8791 87.823 18.0053 92.9996 19.9514 92.9996H33.9797C33.9797 92.9996 37.5418 92.9996 39.8242 92.9996C41.851 92.9996 45.0141 92.9996 45.0141 92.9996H54.2707C59.9447 92.9996 65.9956 92.7787 71.7504 92.5685C76.9684 92.378 81.9431 92.1964 86.1727 92.1964C86.2416 92.1964 86.3133 92.1971 86.3874 92.1978C87.7672 92.2111 89.9568 92.2323 89.9568 87.9122C89.9568 86.7144 89.938 84.7526 89.9153 82.3805C89.8519 75.7538 89.7578 65.9246 89.9568 60.6009V50.426C90.137 48.284 89.8487 43.9998 87.2539 43.9998C86.2827 43.9998 82.452 44.0718 77.4669 44.1656C65.8033 44.3848 47.8204 44.7229 45.3587 44.5353C43.9364 44.427 39.4322 44.3597 34.4304 44.285C29.5471 44.2121 24.1894 44.1321 20.7622 43.9998C20.7282 43.9998 20.6944 43.9998 20.6609 43.9997Z"
        fill="#A3B7E4"
      />
      <g clipPath="url(#clip0_4781_11381)">
        <path
          d="M25.5886 55.2691C33.525 59.7006 39.5416 66.739 47.3795 71.3243C47.8615 71.6062 48.2407 70.8483 48.2955 70.5044C48.3818 69.9649 48.2161 69.3985 47.7286 69.114C39.8922 64.53 33.8851 57.4956 25.9377 53.0587C25.4503 52.7862 25.0778 53.5253 25.0217 53.8773C24.9368 54.4073 25.097 54.9939 25.5886 55.2677V55.2691Z"
          fill="#D1DBF2"
        />
        <path
          d="M60.6792 69.8904C67.9995 64.709 75.3197 59.529 82.6413 54.3476C83.4122 53.8027 82.8522 51.6679 81.8238 52.3948C74.5036 57.5762 67.1834 62.7562 59.8618 67.9376C59.0909 68.4824 59.6509 70.6173 60.6792 69.8904Z"
          fill="#D1DBF2"
        />
        <path
          d="M26.7866 85.8378C32.8977 81.1487 39.2321 76.7454 45.7759 72.6605C47.4451 71.618 49.1266 70.5971 50.8217 69.5964C52.3731 68.6794 53.8753 67.6207 55.7717 68.0752C57.5833 68.5094 58.9663 69.8068 60.3506 70.9558C61.735 72.1049 63.1632 73.1258 64.6625 74.0819C68.146 76.3045 71.8691 78.117 75.5908 79.9147C76.5617 80.384 76.8218 78.1291 75.94 77.7043C72.5332 76.0577 69.1073 74.4137 65.8949 72.415C64.2805 71.4117 62.7209 70.322 61.2544 69.119C59.8659 67.9794 58.5035 66.6996 56.7782 66.0684C54.9597 65.4022 53.3248 65.6935 51.6817 66.6322C50.0385 67.5708 48.2968 68.6295 46.6249 69.6585C43.2852 71.7138 39.9975 73.8513 36.7619 76.0617C33.0908 78.5701 29.4937 81.1811 25.9691 83.885C25.2201 84.4595 25.7911 86.6011 26.7866 85.8378Z"
          fill="white"
        />
      </g>
      <path
        d="M105.864 84.7915C104.763 93.8172 97.9495 101.082 87.3721 101.082C77.9062 101.082 68 95.3689 68 84.7915C68 65.1993 78.776 63.218 89.3534 63.218C99.9307 63.218 105.864 74.2142 105.864 84.7915Z"
        fill="white"
      />
      <path
        d="M82.0004 81.3684C81.855 80.1766 80.9553 79.2173 79.5585 79.2173C78.3085 79.2173 77.0004 79.9717 77.0004 81.3684C77.0004 83.9557 78.4234 84.2173 79.8201 84.2173C81.2169 84.2173 82.0004 82.7652 82.0004 81.3684Z"
        fill="#A3B7E4"
      />
      <path
        d="M89.0004 81.3684C88.855 80.1766 87.9553 79.2173 86.5585 79.2173C85.3085 79.2173 84.0004 79.9717 84.0004 81.3684C84.0004 83.9557 85.4234 84.2173 86.8201 84.2173C88.2169 84.2173 89.0004 82.7652 89.0004 81.3684Z"
        fill="#A3B7E4"
      />
      <path
        d="M96.0004 81.3684C95.855 80.1766 94.9553 79.2173 93.5585 79.2173C92.3085 79.2173 91.0004 79.9717 91.0004 81.3684C91.0004 83.9557 92.4234 84.2173 93.8201 84.2173C95.2169 84.2173 96.0004 82.7652 96.0004 81.3684Z"
        fill="#A3B7E4"
      />
      <defs>
        <clipPath id="clip0_4781_11381">
          <rect
            width="59"
            height="34"
            fill="white"
            transform="translate(24.0001 51.9996)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
