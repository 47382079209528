import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BladderInfectionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M11.5879 17.0135C11.5555 17.0135 11.523 17.0085 11.4906 17.0011C11.3284 16.9561 7.51 15.848 7.66473 8.58063C7.67721 7.98416 8.17384 7.5 8.7703 7.5H9.00991C9.31438 7.5 9.59888 7.62229 9.80851 7.84191C10.0182 8.06153 10.128 8.35103 10.113 8.658C10.0506 9.98071 10.1904 13.2575 12.8058 15.1143C12.9755 15.2341 13.013 15.4687 12.8932 15.6359C12.7734 15.8056 12.5388 15.8431 12.3716 15.7233C9.45663 13.6543 9.29689 10.0681 9.36428 8.62306C9.36927 8.52323 9.33435 8.43089 9.26697 8.35851C9.19959 8.28863 9.10724 8.2487 9.00991 8.2487H8.7703C8.57814 8.2487 8.41593 8.40344 8.41344 8.5956C8.27118 15.2815 11.6503 16.2673 11.6853 16.2773C11.8849 16.3322 12.0022 16.5369 11.9498 16.7365C11.9049 16.9037 11.7526 17.0135 11.5879 17.0135Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M19.7143 30.4225C19.5072 30.4225 19.34 30.2553 19.34 30.0482V26.7589C19.34 26.6316 19.4048 26.5118 19.5122 26.4444C19.572 26.407 25.4469 22.616 25.4469 19.5264C25.4469 19.5014 25.4494 19.474 25.4544 19.449C25.4569 19.4315 25.8063 17.6521 24.6133 16.2121C23.5003 14.8694 21.4338 14.2156 18.4764 14.263C15.4966 14.2131 13.4302 14.8719 12.3196 16.2196C11.1267 17.6671 11.481 19.429 11.486 19.449C11.491 19.474 11.4935 19.5014 11.4935 19.5264C11.4935 22.6185 17.3684 26.407 17.4283 26.4444C17.5356 26.5143 17.6005 26.6316 17.6005 26.7589V30.0482C17.6005 30.2553 17.4332 30.4225 17.2261 30.4225C17.019 30.4225 16.8517 30.2553 16.8517 30.0482V26.961C15.826 26.2797 10.7823 22.7658 10.7448 19.5613C10.6874 19.2518 10.4129 17.3551 11.7406 15.7429C13.0034 14.2106 15.267 13.4594 18.4764 13.5143C21.6659 13.4594 23.937 14.2106 25.1998 15.7429C26.5275 17.3551 26.253 19.2518 26.1956 19.5613C26.1582 22.7658 21.1144 26.2797 20.0887 26.961V30.0482C20.0887 30.2553 19.9215 30.4225 19.7143 30.4225Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M25.3527 17.0135C25.188 17.0135 25.0357 16.9037 24.9908 16.7365C24.9384 16.5369 25.0557 16.3322 25.2528 16.2773C25.4001 16.2349 28.6669 15.2241 28.5272 8.5956C28.5222 8.40344 28.3625 8.2487 28.1703 8.2487H27.9307C27.8309 8.2487 27.741 8.28863 27.6712 8.35851C27.6038 8.43089 27.5688 8.52323 27.5738 8.62306C27.6412 10.0681 27.4815 13.6543 24.5665 15.7233C24.3993 15.8431 24.1647 15.8031 24.0449 15.6359C23.9251 15.4662 23.9651 15.2341 24.1323 15.1143C26.7453 13.26 26.885 9.98071 26.8251 8.658C26.8102 8.35352 26.92 8.06402 27.1296 7.84191C27.3392 7.62229 27.6237 7.5 27.9307 7.5H28.1703C28.7668 7.5 29.2634 7.98416 29.2759 8.58063C29.4306 15.848 25.6122 16.9561 25.45 17.0011C25.4176 17.0085 25.3851 17.0135 25.3527 17.0135Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M14.0567 18.4983C14.0342 18.4983 14.0118 18.4983 13.9893 18.4933C13.7847 18.4558 13.6499 18.2637 13.6873 18.059C13.9569 16.5466 15.5541 15.9627 15.6215 15.9402C15.8162 15.8703 16.0308 15.9726 16.1007 16.1673C16.1706 16.362 16.0682 16.5766 15.8736 16.644C15.8511 16.6515 14.6157 17.1132 14.4261 18.1888C14.3936 18.371 14.2364 18.4983 14.0592 18.4983H14.0567Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7449 22.9156C15.685 22.9156 15.6226 22.9006 15.5677 22.8707C13.7883 21.9049 13.4663 20.2827 13.4513 20.2153C13.4139 20.0131 13.5462 19.816 13.7508 19.7786C13.953 19.7411 14.1476 19.8734 14.1876 20.0755C14.1975 20.1329 14.4696 21.4232 15.9245 22.2118C16.1067 22.3117 16.1741 22.5388 16.0743 22.7185C16.0069 22.8432 15.8771 22.9131 15.7449 22.9131V22.9156Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
