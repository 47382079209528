import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function StomachIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M21 13V12C21 8.134 17.866 5 14 5H13C12.116 5 11.367 4.427 11.102 3.632C10.971 3.237 10.564 3 10.149 3H9.132C8.48 3 7.995 3.618 8.158 4.249C8.495 5.556 9.349 6.654 10.487 7.317C10.792 7.495 11 7.795 11 8.148C11 9.647 11 13.586 11 13.586C11 14.367 10.367 15 9.586 15C9.211 15 8.851 14.851 8.586 14.586L7.829 13.829C7.014 13.014 5.752 12.748 4.618 13.269C3.598 13.737 3 14.82 3 15.942V19C3 19.828 3.672 20.5 4.5 20.5C5.328 20.5 6 19.828 6 19V17L8.106 19.106C9.319 20.319 10.963 21 12.678 21V21C17.274 21 21 17.274 21 12.678"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
