import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PregnancyIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M18.1906 10.6781C19.6205 10.6781 20.7797 9.51897 20.7797 8.08906C20.7797 6.65916 19.6205 5.5 18.1906 5.5C16.7607 5.5 15.6016 6.65916 15.6016 8.08906C15.6016 9.51897 16.7607 10.6781 18.1906 10.6781Z"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4397 14.2137C14.7389 13.4454 15.4789 12.9395 16.3034 12.9395H19.3715C20.1682 12.9395 20.8889 13.4123 21.2061 14.1431L25.0478 22.9936C25.3344 23.654 24.8504 24.3918 24.1305 24.3918H21.2705L19.6912 29.3886H16.5412L14.5131 24.2709L11.9863 24.309C11.2764 24.3197 10.7817 23.6078 11.0394 22.9462L14.4397 14.2137Z"
        stroke="currentColor"
        strokeWidth="1.3"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2376 18.4065C16.7888 17.9146 16.0208 17.8887 15.5375 18.3374C15.0542 18.7862 15.0197 19.5543 15.4684 20.0376L17.859 23.3516H18.0489L20.4394 20.0376C20.8882 19.5457 20.8623 18.7862 20.3704 18.3374C19.8785 17.8887 19.119 17.9146 18.6702 18.4065L17.9539 19.2695L17.2376 18.4065Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
