import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DropIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="none"
        d="M9.62652 1.7201L13.9394 7.49121C15.5675 9.67208 15.827 12.5845 14.6101 15.0188C13.3932 17.4531 10.9078 18.9935 8.18632 19V19C5.46432 18.9944 2.9781 17.4545 1.76056 15.02C0.543027 12.5854 0.802289 9.67245 2.43064 7.49121L6.74611 1.7201C7.0861 1.26679 7.61967 1 8.18632 1C8.75296 1 9.28653 1.26679 9.62652 1.7201Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
