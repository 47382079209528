import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MapMarkerWithCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.3342 9.66016L11.2862 12.7032L9.80723 11.2292M17.2642 5.27578C20.2986 8.31015 20.2986 13.2298 17.2642 16.2642L13.0647 20.4637C12.3496 21.1788 11.1903 21.1788 10.4753 20.4637L6.27575 16.2642C3.24138 13.2298 3.24138 8.31015 6.27575 5.27578C9.31012 2.24141 14.2298 2.24141 17.2642 5.27578Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
