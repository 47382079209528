import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ArteryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 21C7.028 21 3 16.969 3 12C3 7.031 7.028 3 12 3C16.969 3 21 7.031 21 12C21 16.969 16.969 21 12 21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 12C17 13.105 16.105 14 15 14V14C13.895 14 13 14.895 13 16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 15.95C9.028 15.95 9.05 15.972 9.05 16C9.05 16.028 9.028 16.05 9 16.05C8.972 16.05 8.95 16.028 8.95 16C8.95 15.972 8.972 15.95 9 15.95"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 7.45C10.028 7.45 10.05 7.472 10.05 7.5C10.05 7.528 10.028 7.55 10 7.55C9.972 7.55 9.95 7.528 9.95 7.5C9.95 7.472 9.972 7.45 10 7.45"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 10C13 8.896 13.896 8 15 8"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 11.0669C7.81 10.7729 8.705 11.1899 9 11.9999"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
