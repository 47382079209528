import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BookmarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 20.4371V6.37491C4 4.511 5.511 3 7.37491 3H16.3747C18.2386 3 19.7496 4.511 19.7496 6.37491V20.4371C19.7498 20.6479 19.632 20.8412 19.4445 20.9377C19.257 21.0342 19.0312 21.0176 18.8597 20.8949L12.2022 16.1385C12.0064 15.9984 11.7432 15.9984 11.5474 16.1385L4.88985 20.8938C4.71853 21.0164 4.49306 21.033 4.30563 20.9368C4.11821 20.8406 4.00025 20.6477 4 20.4371Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
