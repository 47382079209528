import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PatternSemiCircles(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 328 15">
      <g clipPath="url(#clip0_8185_3776)">
        <rect width="339" height="15" fill="currentColor" />
        <path
          opacity="0.24"
          d="M65.7939 39.7473C64.9547 25.3076 57.2151 11.2089 44.9698 4.32331C32.7245 -2.56233 16.3035 -1.25651 6.00519 8.48103C2.81611 11.4957 0.261106 15.1749 -1.88919 19.0575C-4.4274 23.6376 -6.4602 28.6536 -6.75486 33.9273C-6.80148 34.7681 -6.78658 35.671 -6.30914 36.3549C-5.72168 37.1899 -4.63068 37.4185 -3.65157 37.5794C19.5243 41.3574 42.4241 41.8901 65.7921 39.7473"
          fill="#fff"
        />
        <path
          opacity="0.24"
          d="M133.522 31.7473C132.683 17.3076 124.944 3.20895 112.698 -3.67669C100.453 -10.5623 84.032 -9.2565 73.7337 0.481039C70.5446 3.49569 67.9896 7.17488 65.8393 11.0575C63.3011 15.6376 61.2683 20.6536 60.9737 25.9273C60.927 26.7681 60.9419 27.671 61.4194 28.3549C62.0068 29.1899 63.0978 29.4185 64.0769 29.5794C87.2528 33.3574 110.153 33.8901 133.521 31.7473"
          fill="#fff"
        />
        <path
          opacity="0.24"
          d="M200.283 39.7473C199.455 25.3076 191.819 11.2089 179.737 4.32331C167.655 -2.56233 151.453 -1.25651 141.292 8.48103C138.145 11.4957 135.624 15.1749 133.503 19.0575C130.998 23.6376 128.992 28.6536 128.702 33.9273C128.656 34.7681 128.67 35.671 129.141 36.3549C129.721 37.1899 130.798 37.4185 131.764 37.5794C154.63 41.3574 177.225 41.8901 200.281 39.7473"
          fill="#fff"
        />
        <path
          opacity="0.24"
          d="M334.772 39.7473C333.944 25.3076 326.308 11.2089 314.226 4.32331C302.144 -2.56233 285.942 -1.25651 275.781 8.48103C272.634 11.4957 270.113 15.1749 267.992 19.0575C265.487 23.6376 263.482 28.6536 263.191 33.9273C263.145 34.7681 263.16 35.671 263.631 36.3549C264.21 37.1899 265.287 37.4185 266.253 37.5794C289.12 41.3574 311.714 41.8901 334.771 39.7473"
          fill="#fff"
        />
        <path
          opacity="0.24"
          d="M268.012 31.7473C267.172 17.3076 259.433 3.20895 247.188 -3.67669C234.942 -10.5623 218.521 -9.25651 208.223 0.481035C205.034 3.49568 202.479 7.17487 200.329 11.0575C197.79 15.6376 195.758 20.6536 195.463 25.9273C195.416 26.7681 195.431 27.671 195.909 28.3549C196.496 29.1899 197.587 29.4185 198.566 29.5794C221.742 33.3574 244.642 33.8901 268.01 31.7473"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_8185_3776">
          <rect width="328" height="15" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
