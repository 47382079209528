import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TabletsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M9.123 5C10.384 3.768 12.098 3 14 3C17.865 3 21 6.135 21 10C21 11.964 20.18 13.729 18.876 15M14.096 9.904L4.904 19.096M9.5 21C5.909 21 3 18.089 3 14.5C3 10.911 5.909 8 9.5 8C13.089 8 16 10.911 16 14.5C16 18.089 13.089 21 9.5 21Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
