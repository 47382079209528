import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FullscreenIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3 7.84932V3H7.84932M16.1507 3H21V7.84932M21 16.0685V20.9178H16.1507M7.84932 20.9178H3V16.0685"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
