import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function AwardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M8.00001 13.463V21L12 19L16 21V13.463M16.2426 4.75736C18.5858 7.10052 18.5858 10.8995 16.2426 13.2427C13.8995 15.5858 10.1005 15.5858 7.75733 13.2427C5.41418 10.8995 5.41418 7.10051 7.75733 4.75736C10.1005 2.41421 13.8995 2.41421 16.2426 4.75736Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
