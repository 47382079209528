import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HeartInShieldIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 11.1819C20 15.7229 16.587 19.969 12 21C7.413 19.969 4 15.7229 4 11.1819V7.24281C4 6.4308 4.491 5.69879 5.243 5.39178L10.243 3.34575C11.369 2.88475 12.631 2.88475 13.757 3.34575L18.757 5.39178C19.509 5.69979 20 6.4308 20 7.24281V11.1819Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1429 9.535C12.3726 9.28333 12.7694 9 13.3746 9C14.4323 9 15.1429 9.93083 15.1429 10.7983C15.1429 12.6108 12.736 14 12.1429 14C11.5497 14 9.14288 12.6108 9.14288 10.7983C9.14288 9.93083 9.85259 9 10.9112 9C11.5163 9 11.9132 9.28333 12.1429 9.535Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
