import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MapRouteSymbolIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M16.5 11.1V16.05C16.5 17.7897 15.0897 19.2 13.35 19.2C11.6103 19.2 10.2 17.7897 10.2 16.05V6.15C10.2 4.4103 8.7897 3 7.05 3C5.3103 3 3.9 4.4103 3.9 6.15V11.1M7.5 18.75C7.5 19.9926 6.49264 21 5.25 21C4.00736 21 3 19.9926 3 18.75C3 17.5074 4.00736 16.5 5.25 16.5C6.49264 16.5 7.5 17.5074 7.5 18.75ZM20.1 6.15C20.1 7.39264 19.0926 8.4 17.85 8.4C16.6074 8.4 15.6 7.39264 15.6 6.15C15.6 4.90736 16.6074 3.9 17.85 3.9C19.0926 3.9 20.1 4.90736 20.1 6.15Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
