import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatBubbleSpeechIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M10.0002 15.9996H9.4998C5.91006 15.9996 3 13.0895 3 9.4998V9.4998C3 5.91006 5.91006 3 9.4998 3H13.8C17.7764 3 21 6.22355 21 10.2V10.2C21.0029 12.7005 19.7082 15.0234 17.58 16.3362L10.0002 21V15.9996"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
