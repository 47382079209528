import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChatWithTimerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="114"
      height="89"
      viewBox="0 0 114 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05453 0.000123406C2.13096 -0.00280873 0.85749 -0.00474977 0.85749 6.47451V33.4506V55.3911C0.636049 58.8681 0.791058 65.8219 3.18262 65.8219H45.3578C48.8465 65.8219 52.4512 65.7476 56.0681 65.638C56.0267 65.058 56.0055 64.4674 56.0055 63.8666C56.0055 34.262 72.2884 31.2683 88.2712 31.2683C88.5402 31.2683 88.8073 31.273 89.0724 31.2823C89.0879 27.8603 89.1295 24.7309 89.2126 22.3005V8.63261C89.434 5.75515 89.0797 0.000249503 85.891 0.000249503C85.0574 0.000249503 84.2691 0.102076 83.1361 0.248422C78.8514 0.80186 69.6376 1.99199 34.4059 0.719626C33.1791 0.675324 30.1 0.617294 26.2556 0.544842C19.2435 0.412693 9.68562 0.232565 4.17911 0.000249503C4.13729 0.000249553 4.09576 0.000186252 4.05453 0.000123406Z"
        fill="#D1DBF2"
      />
      <path
        d="M45.3579 53.5982C40.6744 77.6544 23.8918 82.2492 16.0859 81.5396C25.0272 68.3406 22.6499 55.461 20.3437 50.671C23.7499 50.0323 38.4391 52.3563 45.3579 53.5982Z"
        fill="#D1DBF2"
      />
      <path
        d="M113.877 66.8699C112.388 79.0808 103.17 88.909 88.8595 88.909C76.053 88.909 62.6509 81.1801 62.6509 66.8699C62.6509 40.3635 77.2298 37.6831 91.5399 37.6831C105.85 37.6831 113.877 52.5598 113.877 66.8699Z"
        fill="#4E7FFF"
      />
      <path
        d="M92.7967 64.5865C92.5776 66.383 91.2215 67.829 89.1161 67.829C87.232 67.829 85.2603 66.6919 85.2603 64.5865C85.2603 60.6868 87.4051 60.2925 89.5105 60.2925C91.6158 60.2925 92.7967 62.4812 92.7967 64.5865Z"
        fill="white"
      />
      <path
        d="M107.899 75.4014C107.675 75.8826 107.42 76.3479 107.133 76.7941C106.596 77.6022 104.712 76.5081 102.679 75.4636C101.257 74.734 101.676 75.2164 100.342 74.3393C100.342 74.3393 96.8217 72.027 93.3009 69.7147C93.2345 69.6818 93.1753 69.6359 93.1268 69.5799C93.0784 69.5238 93.0416 69.4588 93.0188 69.3886C93.0159 69.3156 93.0282 69.243 93.055 69.1753C93.0817 69.1076 93.1224 69.0464 93.1743 68.9954C93.7694 68.2189 94.3734 66.1647 95.2511 66.6437C100.132 68.9902 100.328 70.0456 107.014 73.0731C107.227 73.1682 107.42 73.3045 107.581 73.4739C107.742 73.6433 107.867 73.8424 107.951 74.0598C108.034 74.2771 108.074 74.5084 108.067 74.74C108.06 74.9717 108.007 75.1992 107.911 75.4093L107.899 75.4014Z"
        fill="white"
      />
      <path
        d="M99.8841 44.3276C100.346 44.5792 100.79 44.862 101.214 45.1741C101.979 45.7575 100.776 47.552 99.6128 49.4973C98.8001 50.8581 97.3638 53.0142 96.4106 54.2786C93.8976 57.6156 95.7875 55.2378 93.2745 58.5749C93.2377 58.6385 93.1885 58.6942 93.13 58.7386C93.0714 58.783 93.0047 58.8152 92.9337 58.8333C92.8613 58.8315 92.7902 58.8147 92.725 58.7839C92.6597 58.7531 92.6017 58.7089 92.5546 58.6543C91.824 58.0159 89.8295 57.2871 90.3595 56.449C94.9335 49.2282 94.1008 51.4783 97.5233 45.055C97.6311 44.8499 97.7783 44.6678 97.9562 44.5194C98.134 44.3709 98.3392 44.2591 98.5596 44.1904C98.7801 44.1216 99.0114 44.0974 99.2403 44.119C99.4691 44.1406 99.6909 44.2077 99.8927 44.3163L99.8841 44.3276Z"
        fill="white"
      />
    </SvgIcon>
  );
}
