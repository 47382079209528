import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FaceWithInformationIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="60" height="67" viewBox="0 0 60 67" fill="none" {...props}>
      <path
        d="M49.2568 7.02831C51.0173 6.93433 51.8326 6.89081 52.043 10.1284C51.7857 10.1411 51.51 10.1518 51.2147 10.1518C50.0018 10.1518 48.2893 10.2101 46.2906 10.2782C43.4899 10.3735 40.1272 10.488 36.7897 10.488C35.402 10.488 33.0863 10.3935 30.9107 10.3047C28.9834 10.226 27.1658 10.1518 26.2009 10.1518L16.2236 10.1518C13.074 10.1518 13.26 12.2782 13.5214 15.2662L13.5284 15.3457L13.5284 31.9041C13.5284 36.105 13.3712 42.3014 13.2363 47.6187C13.135 51.6145 13.0462 55.1142 13.0462 56.8969C13.0462 57.1878 13.0562 57.4571 13.0752 57.7063L12.9865 57.7073C11.8254 57.7209 9.99993 57.7424 9.99993 53.8103C9.99993 52.0276 10.0887 48.5283 10.19 44.5325C10.3249 39.2153 10.4821 33.0184 10.4821 28.8175L10.4821 12.2591L10.4752 12.1797C10.2138 9.19157 10.0278 7.06519 13.1773 7.06519L23.1546 7.06519C24.1195 7.06519 25.9369 7.13939 27.8642 7.21808C30.0398 7.3069 32.3557 7.40146 33.7434 7.40146C37.0809 7.40146 40.4436 7.28695 43.2444 7.19157C45.2431 7.12351 46.9556 7.0652 48.1685 7.0652C48.5657 7.0652 48.9278 7.04587 49.2568 7.02831Z"
        fill="#EABAF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9664 62.9975C17.9053 62.9975 17.8415 62.9982 17.7756 62.999C16.6145 63.0127 14.7891 63.0341 14.7891 59.1021C14.7891 57.3193 14.8778 53.8201 14.9792 49.8244L14.9792 49.8242L14.9792 49.8239C15.1141 44.5066 15.2712 38.3102 15.2712 34.1092L15.2712 17.5508L15.2643 17.4714C15.0029 14.4833 14.8169 12.3569 17.9664 12.3569L27.9437 12.3569C28.9087 12.3569 30.7261 12.4311 32.6535 12.5098L32.6536 12.5098C34.8292 12.5986 37.1448 12.6932 38.5325 12.6932C41.87 12.6932 45.2328 12.5787 48.0335 12.4833C50.0322 12.4152 51.7447 12.3569 52.9576 12.3569C53.3549 12.3569 53.7169 12.3376 54.0459 12.32C56.1291 12.2088 56.8889 12.1683 56.8889 17.5508C56.8889 19.1182 56.842 20.8848 56.7847 23.0424C56.6949 26.4262 56.5796 30.7717 56.5796 36.8186L56.6167 39.215C55.8387 39.0744 55.0289 39.0009 54.1881 39.0009C44.1889 39.0009 34.002 40.8739 34.002 59.3951C34.002 60.6714 34.1546 61.8727 34.4394 62.9975H17.9664Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.307 31.3602C33.8639 31.3602 35.511 29.6041 35.7771 27.4222C35.7771 24.8653 34.3429 22.207 31.7859 22.207C29.2289 22.207 26.6239 22.686 26.6239 27.4222C26.6239 29.9792 29.0187 31.3602 31.307 31.3602ZM34.485 32.2564C36.9009 33.5613 38.4279 36.2331 38.5934 38.9696H38.5931C33.9828 39.3757 29.4649 39.2748 24.8926 38.5588C24.6994 38.5283 24.4842 38.485 24.3683 38.3267C24.2741 38.1971 24.2711 38.026 24.2803 37.8667C24.3385 36.8672 24.7395 35.9166 25.2403 35.0487C25.6645 34.3129 26.1686 33.6156 26.7977 33.0443C28.8295 31.1989 32.0692 30.9514 34.485 32.2564Z"
        fill="white"
      />
    </SvgIcon>
  );
}
