import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SyringeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13.2957 8.18272L17.4419 4.03654M19.9535 6.54817L15.8173 10.6944M10.9236 5.81063L18.1993 13.0764M6.63787 17.3621L3 21M7.36545 12.2791L8.8206 13.7342M9.54817 10.0963L11.0033 11.5515M16.4053 3L20.9402 7.53488M16.799 11.6791L10.2837 18.1944C9.50532 18.9728 8.24352 18.9728 7.46512 18.1944L5.86346 16.5947C5.08505 15.8163 5.08505 14.5545 5.86346 13.7761L12.3787 7.2608"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
