import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function VirusIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12.0001 6.31574V3.47363"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.579 3.47368H13.4211"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0001 17.6841V20.5262"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4211 20.5264H10.579"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0197 7.98007L18.029 5.9707"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0239 4.96582L19.0342 6.97614"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.98032 16.0195L5.97095 18.0289"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.97614 19.0342L4.96582 17.0239"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.6842 12.0001H20.5263"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="12"
          cy="12.0001"
          r="5.68421"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5263 10.5791V13.4212"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.31574 12.0001H3.47363"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.47368 13.4212V10.5791"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0197 16.0195L18.029 18.0289"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0342 17.0239L17.0239 19.0342"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.98032 7.98007L5.97095 5.9707"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.96582 6.97614L6.97614 4.96582"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5789 14.3213C10.5524 14.3213 10.5315 14.3422 10.5315 14.3687C10.5315 14.3952 10.5524 14.4161 10.5789 14.4161C10.6054 14.4161 10.6263 14.3952 10.6263 14.3687C10.6263 14.3422 10.6054 14.3213 10.5789 14.3213"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.15789 11.9527C9.13136 11.9527 9.11052 11.9735 9.11052 12.0001C9.11052 12.0266 9.13136 12.0474 9.15789 12.0474C9.18441 12.0474 9.20526 12.0266 9.20526 12.0001C9.20526 11.9735 9.18441 11.9527 9.15789 11.9527"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5789 9.58403C10.5524 9.58403 10.5315 9.60487 10.5315 9.6314C10.5315 9.65793 10.5524 9.67877 10.5789 9.67877C10.6054 9.67877 10.6263 9.65793 10.6263 9.6314C10.6263 9.60487 10.6054 9.58403 10.5789 9.58403"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4211 9.58403C13.3945 9.58403 13.3737 9.60487 13.3737 9.6314C13.3737 9.65793 13.3945 9.67877 13.4211 9.67877C13.4476 9.67877 13.4684 9.65793 13.4684 9.6314C13.4684 9.60487 13.4476 9.58403 13.4211 9.58403"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8421 11.9527C14.8156 11.9527 14.7947 11.9735 14.7947 12.0001C14.7947 12.0266 14.8156 12.0474 14.8421 12.0474C14.8686 12.0474 14.8895 12.0266 14.8895 12.0001C14.8895 11.9735 14.8686 11.9527 14.8421 11.9527"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4211 14.3213C13.3945 14.3213 13.3737 14.3422 13.3737 14.3687C13.3737 14.3952 13.3945 14.4161 13.4211 14.4161C13.4476 14.4161 13.4684 14.3952 13.4684 14.3687C13.4684 14.3422 13.4476 14.3213 13.4211 14.3213"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0001 11.9527C11.9735 11.9527 11.9527 11.9735 11.9527 12.0001C11.9527 12.0266 11.9735 12.0474 12.0001 12.0474C12.0266 12.0474 12.0474 12.0266 12.0474 12.0001C12.0474 11.9735 12.0266 11.9527 12.0001 11.9527"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
