import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CameraPermissionsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        fill="#d1dbf2"
        d="M12.78,.56C10.29,.53,.04,.39,.04,7.52c0,4.4,0,8.06,0,12.17,0,2.23,0,4.58,0,7.26V63.55c-.19,2.72,0,10.38,7.48,10.38h29.9c2.06,0,3.91-.03,5.61-.09-.11-.83-.16-1.68-.16-2.56,0-24.45,13.45-26.92,26.65-26.92,1.24,0,2.43,.12,3.57,.35-.04-11.13-.05-23.21,.1-27.26V9.11c0-8.55-4.87-9.11-7.55-9.11-1.05,0-4.74,.08-9.43,.19-10.33,.23-25.51,.57-28,.38-1.29-.1-3.01-.06-5.15,0-2.67,.07-5.99,.16-9.97,0-.09,0-.2,0-.34,0Z"
      />
      <path
        fillRule="evenodd"
        fill="#4e7fff"
        d="M34.05,23.23c.37-.06,.75-.09,1.13-.1,.69,0,.79,1.65,.97,3.37,.13,1.2,.25,3.18,.25,4.38,.02,3.19,.03,6.38,.05,9.57,0,.06,0,.11-.01,.17-.01,.05-.04,.1-.07,.15-.04,.03-.09,.06-.14,.07-.05,.01-.1,.01-.15,0-.7-.05-2.16,.43-2.22-.32-.53-6.5,0-10.25-.84-15.74-.03-.17-.02-.35,.01-.53,.04-.17,.11-.34,.2-.48,.1-.14,.22-.27,.36-.36,.14-.09,.3-.15,.46-.18h0Zm11.99,19.88c-.07,1.2-.37,2.37-.87,3.45-.79,1.7-1.76,3.37-3.09,4.66-3.2,3.12-8.3,3.54-12.1,1.33-3.39-1.97-5.67-5.78-6.31-9.87-.03-.19-.02-.38,.03-.57,.05-.19,.13-.36,.24-.51,.11-.15,.25-.27,.41-.36,.16-.09,.33-.14,.51-.15,.39-.03,.78-.06,1.17-.09,.16-.01,.33,.04,.46,.13,.14,.1,.24,.24,.3,.4,.27,.8,.61,1.58,1,2.32,1.26,2.37,3.46,4.28,5.98,4.56,2.87,.31,5.7-1.57,7.21-4.21,.32-.56,.7-1.29,1.08-2.07,.09-.19,.23-.34,.4-.43,.17-.1,.37-.13,.56-.11,.68,.09,1.36,.2,2.04,.31,.31,.05,.64,.12,.83,.39,.13,.25,.18,.53,.14,.81h0Zm.1-14.9c-.65,1.14-1.88,1.96-3.12,1.82-1.09-.12-2.04-.95-2.59-1.97-.54-1.03-.82-3.2-.82-3.2,0-.17,.31-.27,.77-.34,.05,0,.1,0,.14,.03,.04,.03,.08,.07,.1,.12,.07,.16,.14,.31,.23,.47,.46,.86,1.26,1.56,2.18,1.66,1.05,.11,2.08-.57,2.63-1.54,.1-.18,.23-.42,.35-.67,.04-.08,.1-.15,.17-.2s.16-.07,.25-.07h.36c1.25,0,0,2.73-.65,3.87h0Z"
      />
      <path
        fillRule="evenodd"
        fill="#4e7fff"
        d="M7.39,22.35c0-3.83,2.32-11.63,11.6-12.11h3.78c.27,0,.5,.22,.5,.5v2.58c0,.3-.25,.52-.55,.51-3.77-.22-10.63,1.23-12.2,8.72-.05,.24-.25,.41-.49,.41h-2.14c-.27,0-.5-.22-.5-.5v-.11Zm57.66-.7c.05-4.06,.14-11.41-11.61-11.41h-3.78c-.27,0-.5,.22-.5,.5v2.58c0,.3,.25,.52,.55,.5,3.94-.33,12.77,0,12.32,8.59-.02,.29,.21,.55,.5,.55h2.01c.27,0,.5-.22,.5-.5v-.11c0-.22,0-.45,0-.7h0ZM19.24,66.31c-11.6-.73-11.6-8.27-11.6-12.11v-.11c0-.27,.22-.5,.5-.5h3.41c.27,0,.49,.23,.5,.5,.15,7.71,3.77,8.55,10.98,8.58,.27,0,.5,.22,.5,.5v2.64c0,.27-.22,.5-.5,.5h-3.78Zm45.81-11.64c.05,4.02,.13,11.08-11.49,11.77l-3.94-.33c-.26-.02-.45-.24-.45-.49v-2.62c0-.3,.26-.52,.55-.5,7.19,.69,10.72-1.04,12.2-8.73,.05-.24,.25-.41,.49-.41h2.14c.27,0,.5,.22,.5,.5v.11c0,.22,0,.46,0,.71Z"
      />
      <path
        fillRule="evenodd"
        fill="#4e7fff"
        d="M69.15,90.52c11.92,0,19.6-8.19,20.85-18.36,0-11.92-6.69-24.32-18.61-24.32s-24.07,2.23-24.07,24.32c0,11.92,11.17,18.36,21.84,18.36Zm9.2-26.04c.96-.92-.11-1.92-.77-2.31-.21-.14-1.41-.65-1.59-.5-.93,.62-7.09,8-7.41,8.42-.15,.19-.83,1.04-1.55,1.94-.69-.83-1.32-1.58-1.46-1.76-.33-.41-2.44-2.99-3.39-3.59-.18-.14-1.37,.39-1.58,.53-.65,.4-1.7,1.42-.72,2.32,.87,.8,1.66,1.76,2.23,2.46h0c.23,.28,.43,.53,.58,.7,.14,.15,.36,.4,.63,.71h0s0,0,0,0c.79,.9,2.02,2.28,2.82,3.1,.08,.08,.14,.14,.24,.17,.03,.02,.06,.03,.09,.05,.62,.28,.95,.06,1.05-.13,1.05-1.12,6.88-7.65,7.4-8.25,.12-.13,.29-.34,.51-.6h0c.75-.9,1.99-2.38,2.93-3.27Z"
      />
    </SvgIcon>
  );
}
