import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Pattern4(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 317 317" {...props}>
      <path
        d="M173.292 64.7567C194.199 64.6297 228.288 62.2446 249.064 59.6738C278.765 55.9936 307.367 54.1364 307.199 40.266C306.996 23.2578 305.557 14.8515 303.499 6.51362C302.965 4.36563 301.697 2.51608 299.952 1.33724C298.206 0.158392 296.112 -0.262245 294.091 0.16C191.671 20.6089 126.076 8.46857 8.38077 19.646C-4.5156 20.8728 2.31027 44.2296 1.4508 58.1881C1.31964 60.2701 1.27441 62.6112 2.59524 64.136C3.9161 65.6609 6.10092 65.7586 8.02337 65.7489L173.292 64.7567Z"
        fill="currentColor"
      />
      <path
        d="M303.698 76.7078C263.328 79.1826 223.912 102.006 204.662 138.116C185.411 174.226 189.062 222.649 216.286 253.018C224.714 262.422 235 269.957 245.855 276.298C258.659 283.782 272.683 289.777 287.427 290.646C289.778 290.783 292.302 290.739 294.214 289.332C296.548 287.599 297.187 284.382 297.637 281.495C308.199 213.152 309.689 145.623 303.698 76.7133"
        fill="currentColor"
      />
      <path
        d="M75.6 71.4982C116.044 71.8838 156.586 92.6398 177.676 127.707C198.767 162.774 197.622 211.322 172.004 243.056C164.073 252.883 154.19 260.939 143.677 267.833C131.276 275.969 117.581 282.68 102.902 284.31C100.561 284.569 98.038 284.655 96.0557 283.348C93.6348 281.739 92.8303 278.559 92.2322 275.698C78.1535 207.993 73.1774 140.631 75.6003 71.5037"
        fill="currentColor"
      />
      <path
        d="M3.69631 148.69C3.82577 171.672 6.2556 209.141 8.87464 231.978C12.6239 264.625 14.516 296.064 28.6469 295.88C45.9744 295.656 54.5386 294.075 63.033 291.813C65.2213 291.225 67.1056 289.832 68.3066 287.913C69.5076 285.994 69.9361 283.692 69.5059 281.471C48.6731 168.892 61.0412 214.856 49.6539 85.4863C48.4041 71.3108 24.6087 78.8137 10.3882 77.869C8.26708 77.7248 5.88205 77.6751 4.32855 79.1269C2.77505 80.5788 2.67547 82.9803 2.68543 85.0935L3.69631 148.69Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
