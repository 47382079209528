import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CardInHandIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M3 13.0999L5.29545 9.27417C5.55964 8.83385 5.94391 8.478 6.40318 8.24836L6.92985 7.98503C7.30475 7.79758 7.71815 7.69999 8.13731 7.69999H20.9997M4.79999 19.3996L9.9856 17.5258C10.7094 17.2643 11.3303 16.7777 11.7573 16.1374L14.4508 12.0983C14.8426 11.5109 14.7652 10.7285 14.2659 10.2292C13.6875 9.6509 12.7499 9.65081 12.1714 10.229L10.1999 12.1997M10.1999 12.1997H8.39994M10.1999 12.1997L10.1998 7.15997C10.1793 5.98812 11.1122 5.02129 12.284 5H18.9155C20.0873 5.02129 21.0202 5.98812 20.9997 7.15997V10.9399C21.0202 12.1118 20.0873 13.0786 18.9155 13.0999H13.7829"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
