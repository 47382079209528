import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function NotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.32 21H10.48M18.4 9C18.4 5.6868 15.7132 3 12.4 3C9.0868 3 6.4 5.6868 6.4 9V12.0048C6.4 12.3936 6.1804 12.7476 5.8336 12.9216L5.23 13.2228C4.4764 13.6008 4 14.3712 4 15.2136C4 16.4424 4.996 17.4384 6.2248 17.4384H18.5752C19.804 17.4384 20.8 16.4424 20.8 15.2136C20.8 14.3712 20.3236 13.6008 19.57 13.224L18.9664 12.9228C18.6196 12.7476 18.4 12.3936 18.4 12.0048V9Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
