import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PieChartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M16.318 8.07533C16.5347 8.07433 16.7097 7.89836 16.7097 7.68263C16.7097 7.4659 16.5337 7.29092 16.317 7.29092C16.1003 7.29092 15.9243 7.4659 15.9243 7.68263C15.9243 7.89936 16.1003 8.07533 16.317 8.07533"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.318 3.8699C16.5238 3.86891 16.6898 3.70189 16.6898 3.49609C16.6898 3.2903 16.5228 3.12427 16.317 3.12427C16.1112 3.12427 15.9442 3.29129 15.9442 3.49709C15.9442 3.70288 16.1112 3.8699 16.318 3.8699"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.8752 7.68164C20.8752 7.47585 20.7082 7.30982 20.5024 7.30982C20.2966 7.30982 20.1306 7.47684 20.1306 7.68263C20.1306 7.88843 20.2976 8.05545 20.5034 8.05545C20.7082 8.05545 20.8752 7.88843 20.8752 7.68164"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9476 20.9996V3.10449C7.00553 3.10449 3 7.11002 3 12.0521C3 16.9941 7.00553 20.9996 11.9476 20.9996C16.8896 20.9996 20.8951 16.9941 20.8951 12.0521H11.9476"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
