import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CheckInHeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.889 10.056L11.278 13.667L9.111 11.5M12 5.711C12.689 4.905 13.881 4 15.696 4C18.87 4 21 6.98 21 9.755C21 15.556 13.778 20 12 20C10.222 20 3 15.556 3 9.755C3 6.98 5.13 4 8.304 4C10.119 4 11.311 4.905 12 5.711Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
