import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function WavyPlusCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36 20.5116C34.9535 29.093 28.4753 36 18.4186 36C9.4186 36 0 30.5683 0 20.5116C0 1.88372 10.2456 0 20.3023 0C30.359 0 36 10.4549 36 20.5116Z"
        fill="currentColor"
        fillOpacity={0.2}
      />
      <path
        d="M12.0072 15.3576H14.7121C14.913 15.3576 15.1055 15.2777 15.2475 15.1355C15.3895 14.9934 15.4693 14.8005 15.4693 14.5994V12.0082C15.4693 11.8071 15.5491 11.6143 15.6911 11.4721C15.8331 11.3299 16.0257 11.25 16.2265 11.25H19.7511C19.9519 11.25 20.1445 11.3299 20.2865 11.4721C20.4285 11.6143 20.5082 11.8071 20.5082 12.0082V14.5994C20.5082 14.8005 20.588 14.9934 20.73 15.1355C20.872 15.2777 21.0646 15.3576 21.2654 15.3576H23.9155C24.1163 15.3576 24.3089 15.4375 24.4509 15.5797C24.5929 15.7219 24.6727 15.9147 24.6727 16.1158V19.8216C24.6727 20.0227 24.5929 20.2156 24.4509 20.3577C24.3089 20.4999 24.1163 20.5798 23.9155 20.5798H21.2654C21.0646 20.5798 20.872 20.6597 20.73 20.8019C20.588 20.9441 20.5082 21.1369 20.5082 21.338V23.9918C20.5082 24.1929 20.4285 24.3857 20.2865 24.5279C20.1445 24.6701 19.9519 24.75 19.7511 24.75H16.2255C16.0247 24.75 15.8321 24.6701 15.6901 24.5279C15.5481 24.3857 15.4684 24.1929 15.4684 23.9918V21.338C15.4684 21.1369 15.3886 20.9441 15.2466 20.8019C15.1046 20.6597 14.912 20.5798 14.7112 20.5798H12.0072C11.8064 20.5798 11.6138 20.4999 11.4718 20.3577C11.3298 20.2156 11.25 20.0227 11.25 19.8216V16.1158C11.25 15.9147 11.3298 15.7219 11.4718 15.5797C11.6138 15.4375 11.8064 15.3576 12.0072 15.3576Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
