import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function WeightScaleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M12 11.43L13 9.43M3 8V16C3 18.761 5.239 21 8 21H16C18.761 21 21 18.761 21 16V8C21 5.239 18.761 3 16 3H8C5.239 3 3 5.239 3 8ZM15 11.434H9C8.448 11.434 8 10.986 8 10.434C8 8.225 9.791 6.434 12 6.434C14.209 6.434 16 8.225 16 10.434C16 10.987 15.552 11.434 15 11.434Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
