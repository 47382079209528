import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TagWithCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="78"
      height="82"
      viewBox="0 0 78 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.2328 42.4444L49.2281 26.4305C48.9184 25.9348 48.4119 25.5948 47.8358 25.4959L25.5189 21.6673C24.4812 21.4893 23.4774 22.126 23.1961 23.1406L17.2442 44.6102C17.0906 45.1641 17.1766 45.757 17.4812 46.2445L38.7328 80.261C39.3328 81.2215 40.5979 81.5137 41.5583 80.9136L50.1574 75.5414C49.598 75.0133 49.0544 74.4413 48.5291 73.8243C42.4605 66.6956 40.4119 55.5729 48.3776 48.7917C52.446 45.3283 56.0313 43.3403 59.2328 42.4444ZM26.352 32.3852C25.3019 30.7044 25.8132 28.4906 27.4941 27.4405C29.1749 26.3905 31.3887 26.9018 32.4388 28.5826C33.4889 30.2634 32.9775 32.4772 31.2967 33.5273C29.6159 34.5774 27.4021 34.0661 26.352 32.3852Z"
        fill="currentColor"
      />
      <path
        d="M29.2321 31.1904C32.5937 29.0902 32.4003 22.716 28.8 16.9532C25.1997 11.1905 19.556 8.2213 16.1944 10.3215C12.8327 12.4216 13.0262 18.7958 16.6265 24.5586C17.3738 25.7548 18.2091 26.8306 19.0938 27.7657"
        stroke="#D1DBF2"
        fill="none"
        strokeWidth="2.05057"
        strokeLinecap="round"
      />
      <path
        d="M76.5879 63.0182C75.7236 70.1057 70.3731 75.8103 62.0671 75.8103C54.6338 75.8103 46.8547 71.3242 46.8547 63.0182C46.8547 47.633 55.3168 46.0771 63.6229 46.0771C71.9289 46.0771 76.5879 54.7121 76.5879 63.0182Z"
        fill="#D1DBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.4283 56.5706C67.8537 56.8219 68.5487 57.473 67.9261 58.0664C67.321 58.6432 66.5156 59.6055 66.0285 60.1876C65.8892 60.354 65.7759 60.4894 65.7008 60.5757C65.3629 60.9641 61.5838 65.1945 60.9015 65.9231C60.8369 66.046 60.6232 66.183 60.222 66.0045C60.2026 65.9958 60.1836 65.9857 60.165 65.9744C60.1039 65.9573 60.0597 65.9172 60.0096 65.8656C59.4919 65.333 58.6979 64.4345 58.1833 63.8522C58.0063 63.652 57.8624 63.4891 57.7741 63.3913C57.6745 63.2811 57.5464 63.1246 57.3955 62.9402C57.0234 62.4856 56.5127 61.8617 55.9493 61.3444C55.3158 60.7626 55.9986 60.0988 56.4192 59.8396C56.5547 59.7459 57.3265 59.4042 57.4426 59.4961C58.055 59.8852 59.4285 61.5544 59.6406 61.8232C59.733 61.9402 60.1391 62.4274 60.5877 62.9648C61.057 62.3807 61.4979 61.8319 61.5935 61.7059C61.8006 61.4333 65.7937 56.6466 66.3987 56.2462C66.5131 56.1522 67.2911 56.4795 67.4283 56.5706Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
