import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HIVBloodDropIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="37" height="37" viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M36.5 21.0116C35.4535 29.593 28.9753 36.5 18.9186 36.5C9.9186 36.5 0.5 31.0683 0.5 21.0116C0.5 2.38372 10.7456 0.5 20.8023 0.5C30.859 0.5 36.5 10.9549 36.5 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M18.6371 4.51C19.6971 5.57 20.7871 6.59 21.7971 7.69C24.0071 10.1 25.9071 12.73 27.2371 15.74C27.9671 17.39 28.4571 19.1 28.4271 20.93C28.3271 25.69 24.6571 29.83 19.9471 30.48C14.4771 31.24 9.55712 27.66 8.62712 22.24C8.32712 20.47 8.57712 18.76 9.17712 17.08C10.3871 13.7 12.3871 10.8 14.7471 8.13C15.8371 6.9 17.0171 5.77 18.3071 4.75C18.3971 4.68 18.4871 4.62 18.6471 4.5L18.6371 4.51Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.3"
        strokeMiterlimit="10"
      />
      <path
        d="M12.1359 20.5697V21.0697H12.6359H15.4559H15.9559V20.5697V18.7197C15.9559 18.5242 16.0026 18.4697 16.0033 18.4688C16.0249 18.4455 16.0498 18.4297 16.1059 18.4297C16.1538 18.4297 16.1863 18.4412 16.2215 18.4729C16.2319 18.4886 16.2659 18.5528 16.2659 18.7197V23.7997C16.2659 23.8995 16.2546 23.9665 16.242 24.0085C16.2359 24.0289 16.2299 24.042 16.2257 24.0496C16.2217 24.0571 16.219 24.06 16.2185 24.0605C16.197 24.0839 16.1721 24.0997 16.1159 24.0997C16.0598 24.0997 16.0349 24.0839 16.0133 24.0605C16.0129 24.06 16.0102 24.0571 16.0061 24.0496C16.002 24.042 15.996 24.0289 15.9899 24.0085C15.9772 23.9665 15.9659 23.8995 15.9659 23.7997V21.6297V21.1297H15.4659H12.6459H12.1459V21.6297V23.7997C12.1459 23.8999 12.1345 23.9644 12.1224 24.003C12.1167 24.0216 12.1112 24.0326 12.1079 24.0383C12.1047 24.0438 12.1028 24.0458 12.1024 24.0461L12.1024 24.0461C12.0742 24.0743 12.0549 24.0852 12.0438 24.09C12.0337 24.0944 12.0173 24.0997 11.9859 24.0997C11.9298 24.0997 11.9049 24.0839 11.8833 24.0605C11.8829 24.06 11.8802 24.0571 11.8761 24.0496C11.872 24.042 11.866 24.0289 11.8599 24.0085C11.8472 23.9665 11.8359 23.8995 11.8359 23.7997V18.7197C11.8359 18.5509 11.8709 18.4786 11.8836 18.4584C11.8965 18.4462 11.9053 18.4417 11.9106 18.4394C11.9174 18.4364 11.9357 18.4297 11.9759 18.4297C12.0463 18.4297 12.0697 18.4485 12.0885 18.4688C12.0893 18.4697 12.1359 18.5242 12.1359 18.7197V20.5697Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M18.7248 23.7899V23.2899H18.7148V18.7099C18.7148 18.5145 18.7615 18.4599 18.7622 18.4591C18.7838 18.4357 18.8087 18.4199 18.8648 18.4199C18.9127 18.4199 18.9452 18.4314 18.9804 18.4631C18.9908 18.4788 19.0248 18.543 19.0248 18.7099V23.7899C19.0248 23.8897 19.0135 23.9567 19.0009 23.9987C18.9948 24.0191 18.9888 24.0322 18.9846 24.0399C18.9806 24.0473 18.9779 24.0503 18.9774 24.0508C18.9586 24.0712 18.9352 24.0899 18.8648 24.0899C18.8259 24.0899 18.8122 24.0837 18.8101 24.0828L18.81 24.0827L18.81 24.0827C18.8077 24.0817 18.7958 24.0763 18.7722 24.0508C18.7718 24.0503 18.7691 24.0473 18.765 24.0399C18.7609 24.0322 18.7549 24.0191 18.7488 23.9987C18.7361 23.9567 18.7248 23.8897 18.7248 23.7899Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M21.9373 18.7799L23.3973 23.0999L24.8573 18.7499C24.9373 18.5199 24.9873 18.3599 25.0273 18.2699C25.0673 18.1799 25.1273 18.0999 25.2173 18.0299C25.3073 17.9599 25.4273 17.9199 25.5773 17.9199C25.6873 17.9199 25.7873 17.9499 25.8873 17.9999C25.9873 18.0599 26.0573 18.1299 26.1073 18.2199C26.1573 18.3099 26.1873 18.4099 26.1873 18.4999C26.1873 18.5599 26.1873 18.6299 26.1573 18.7099C26.1373 18.7799 26.1173 18.8599 26.0873 18.9299C26.0573 18.9999 26.0373 19.0799 26.0073 19.1499L24.4473 23.3599C24.3873 23.5199 24.3373 23.6699 24.2773 23.8199C24.2173 23.9699 24.1573 24.0899 24.0873 24.1999C24.0173 24.3099 23.9173 24.3999 23.7973 24.4699C23.6773 24.5399 23.5273 24.5799 23.3473 24.5799C23.1673 24.5799 23.0273 24.5499 22.8973 24.4799C22.7773 24.4099 22.6773 24.3199 22.6073 24.2099C22.5273 24.0999 22.4673 23.9699 22.4073 23.8299C22.3473 23.6899 22.2973 23.5299 22.2373 23.3699L20.7073 19.1999C20.6773 19.1199 20.6573 19.0499 20.6273 18.9799C20.5973 18.9099 20.5773 18.8299 20.5573 18.7399C20.5373 18.6599 20.5273 18.5799 20.5273 18.5299C20.5273 18.3799 20.5873 18.2399 20.7073 18.1199C20.8273 17.9999 20.9773 17.9299 21.1573 17.9299C21.3773 17.9299 21.5373 17.9999 21.6273 18.1399C21.7173 18.2799 21.8173 18.4899 21.9073 18.7899L21.9373 18.7799Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
