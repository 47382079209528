import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HealthyLivingIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="58" height="59" viewBox="0 0 58 59" fill="none" {...props}>
      <path
        d="M56.8587 10.9208C56.6087 7.79081 55.7287 4.80081 54.7187 1.86081C54.4987 1.20081 53.7987 0.820808 53.1287 1.00081C35.0387 5.94081 25.7187 24.9908 27.8687 42.8008C27.8287 45.3308 24.9387 55.9608 26.3987 57.9008C27.2887 57.9608 28.0387 58.3208 28.2587 57.6008C28.0187 44.9508 33.9787 28.3208 45.7087 20.7608C43.8187 23.1308 41.5087 25.1508 39.8887 27.7508C38.1087 30.3308 36.3687 33.4308 35.2687 36.3008C34.0787 39.2508 33.2287 42.2908 32.6687 45.4008C32.4087 46.8808 33.9187 48.0608 35.3087 47.4808C49.3487 41.6208 58.7187 26.1408 56.8787 10.9208H56.8587Z"
        fill="#00948D"
      />
      <path
        d="M0.948739 18.6808C0.568739 5.60083 28.2087 24.0608 26.8187 41.9408C26.7487 42.8608 25.5087 43.0908 25.0587 42.2808C21.8287 36.4408 17.0287 31.4808 11.0287 28.5008C16.8287 32.6708 20.9787 37.9908 23.4887 44.5408C24.0487 46.0008 22.7387 47.5108 21.2287 47.1108C8.94874 43.8608 0.0187331 31.4808 0.958733 18.6908L0.948739 18.6808Z"
        fill="#BEEDE9"
      />
      <path
        d="M30.7209 14.4062C30.2909 17.9462 27.6209 20.7862 23.4809 20.7862C19.7709 20.7862 15.8909 18.5462 15.8909 14.4062C15.8909 6.73618 20.1109 5.95618 24.2509 5.95618C28.3909 5.95618 30.7209 10.2662 30.7209 14.4062Z"
        fill="#00948D"
      />
      <path
        d="M21.4008 14.7761C21.4208 15.2061 21.4708 15.6161 21.6508 15.9861C22.0108 16.7161 22.6408 17.0561 23.4508 16.9661C23.8408 16.9161 24.2208 16.8461 24.5108 16.5461C24.8708 16.1861 24.9008 15.5561 24.5608 15.1661C24.2708 14.8261 23.8708 14.6561 23.4708 14.4861C22.9208 14.2661 22.3708 14.0661 21.8308 13.8361C21.3108 13.6161 20.8408 13.3161 20.5008 12.8461C19.5608 11.5561 20.0808 9.76611 21.5608 9.15611C22.2908 8.85611 23.0508 8.79611 23.8308 8.81611C24.4108 8.83611 24.9908 8.91611 25.5208 9.15611C25.9308 9.34611 26.2808 9.61611 26.4008 10.0861C26.4808 10.4061 26.4408 10.7161 26.2308 10.9761C26.0808 11.1661 25.8808 11.2961 25.6508 11.3461C25.3708 11.4161 25.0708 11.4361 24.8108 11.4761C24.7308 11.1461 24.6808 10.8561 24.5908 10.5661C24.4508 10.0961 24.1008 9.84611 23.6308 9.75611C23.3008 9.69611 22.9708 9.72611 22.6608 9.84611C21.8008 10.1561 21.8408 11.1461 22.3708 11.5561C22.7608 11.8561 23.2208 12.0361 23.6708 12.2161C24.1708 12.4161 24.6808 12.6061 25.1708 12.8261C25.6008 13.0161 25.9708 13.3061 26.2708 13.6861C26.6908 14.2261 26.8008 14.8461 26.6908 15.4961C26.6208 15.9361 26.5108 16.3661 26.2208 16.7361C25.8108 17.2561 25.2708 17.5661 24.6508 17.7361C23.8508 17.9661 23.0308 17.9661 22.2108 17.8661C21.6508 17.8061 21.1008 17.6761 20.6108 17.3661C20.0508 17.0061 19.7708 16.3661 19.9008 15.7161C19.9708 15.3661 20.1808 15.1161 20.5008 14.9561C20.7808 14.8161 21.0808 14.7861 21.3908 14.7761H21.4008Z"
        fill="#FBFDFC"
      />
    </SvgIcon>
  );
}
