import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PaperclipIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M13.633 8.37625L8.14703 13.8623C7.32103 14.6883 7.32103 16.0282 8.14703 16.8542V16.8542C8.97303 17.6802 10.313 17.6802 11.139 16.8542L18.371 9.62225C19.886 8.10725 19.886 5.65125 18.371 4.13625V4.13625C16.856 2.62125 14.4 2.62125 12.885 4.13625L5.65303 11.3682C3.44903 13.5722 3.44903 17.1442 5.65303 19.3482V19.3482C7.85703 21.5522 11.429 21.5522 13.633 19.3482L18.022 14.9593"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
