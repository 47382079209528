import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LightbulbIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          d="M14.5257 14.4228H9.47421M18.7352 5.84378L19.7371 4.84191M5.26467 14.2629L4.2628 15.2647M6.10658 5.00187L5.10471 4M18.7352 14.2629L19.7371 15.2647M19.5771 10.0533H20.9999M2.99994 10.0533H4.42276M9.47421 16.7886H14.4162M9.08778 14.1753C7.59255 13.1187 6.69592 11.2741 7.01163 9.24339C7.35345 7.04854 9.18292 5.29653 11.3896 5.03806C14.4524 4.67856 17.0514 7.06285 17.0514 10.0533C17.0514 11.7582 16.2044 13.2627 14.9104 14.177C14.6781 14.3412 14.5257 14.5954 14.5257 14.88V17.2095C14.5257 18.3722 13.5836 19.3143 12.4209 19.3143H11.579C10.4163 19.3143 9.47422 18.3722 9.47422 17.2095V14.8833C9.47422 14.5962 9.32099 14.3403 9.08778 14.1753Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
