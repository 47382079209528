import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HealthPin(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 36">
      <g filter="url(#filter0_d_1962_10815)">
        <path
          d="M16.1543 3C9.88378 3 4.21045 7.58188 4.21045 14.6681C4.21045 19.1931 7.86826 24.5149 15.1689 30.6478C15.7363 31.1174 16.5873 31.1174 17.1546 30.6478C24.4404 24.5149 28.0982 19.1931 28.0982 14.6681C28.0982 7.58188 22.4248 3 16.1543 3Z"
          fill="currentColor"
        />
        <path
          d="M14.2041 31.7964L14.2041 31.7964L14.2125 31.8034C15.3348 32.7322 16.9888 32.7322 18.111 31.8034L18.1111 31.8034L18.1206 31.7954C21.8224 28.6793 24.667 25.717 26.5958 22.9031C28.5215 20.0937 29.5982 17.338 29.5982 14.6681C29.5982 6.64167 23.1369 1.5 16.1543 1.5C9.17174 1.5 2.71045 6.64167 2.71045 14.6681C2.71045 17.3381 3.78722 20.094 5.7151 22.9037C7.64603 25.7178 10.4946 28.6802 14.2041 31.7964Z"
          stroke="white"
          strokeWidth="3"
        />
      </g>
      <rect
        x="11.4805"
        y="10.3514"
        width="2.05423"
        height="9.32432"
        rx="1.02711"
        fill="white"
      />
      <rect
        x="18.4648"
        y="10.3514"
        width="2.05423"
        height="9.32432"
        rx="1.02711"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5079 14.9909C12.5164 14.476 12.9831 14.0648 13.5503 14.0726C15.3697 14.0973 16.4422 14.0937 18.4532 14.0725C19.0204 14.0665 19.4855 14.4791 19.4921 14.9941C19.4987 15.509 19.0442 15.9313 18.477 15.9373C16.4548 15.9585 15.363 15.9623 13.5196 15.9372C12.9524 15.9295 12.4994 15.5058 12.5079 14.9909Z"
        fill="white"
      />
      <rect
        x="6.2876"
        y="4"
        width="19.7333"
        height="19"
        rx="9.5"
        fill="currentColor"
      />
      <path
        d="M15.7368 8.53288C15.8659 8.51308 15.9963 8.50209 16.1269 8.5C16.366 8.5 16.3986 9.03448 16.4616 9.59276C16.506 9.98313 16.5469 10.624 16.549 11.0164C16.555 12.0518 16.561 13.0872 16.567 14.1226C16.5693 14.1407 16.568 14.1591 16.5631 14.1766C16.5582 14.1942 16.5499 14.2105 16.5386 14.2248C16.524 14.2353 16.5074 14.2427 16.4899 14.2464C16.4723 14.2501 16.4542 14.2501 16.4367 14.2465C16.1962 14.2304 15.6924 14.3864 15.6709 14.1415C15.4873 12.0308 15.6709 10.8115 15.3799 9.03099C15.3703 8.97435 15.3719 8.91635 15.3847 8.86036C15.3975 8.80438 15.4212 8.75151 15.4544 8.70484C15.4876 8.65817 15.5297 8.61863 15.5782 8.58851C15.6266 8.55839 15.6805 8.53829 15.7368 8.52938V8.53288ZM19.8705 14.9852C19.8447 15.3749 19.7422 15.7554 19.569 16.1046C19.2967 16.6559 18.9613 17.198 18.5053 17.6178C17.4034 18.6308 15.6453 18.7665 14.3348 18.0501C13.1644 17.4107 12.3785 16.1731 12.1595 14.846C12.1501 14.7843 12.1534 14.7212 12.1694 14.6608C12.1854 14.6005 12.2137 14.5441 12.2524 14.4954C12.2911 14.4466 12.3394 14.4065 12.3943 14.3776C12.4492 14.3488 12.5094 14.3317 12.5712 14.3276C12.7056 14.3173 12.8401 14.308 12.9745 14.2996C13.0308 14.2961 13.0866 14.3111 13.1338 14.3422C13.181 14.3733 13.217 14.419 13.2364 14.4724C13.331 14.7328 13.4469 14.9849 13.5829 15.2259C14.0161 15.9954 14.7742 16.6167 15.646 16.7062C16.6363 16.8077 17.6127 16.1948 18.1325 15.3378C18.2419 15.1566 18.3736 14.9181 18.5032 14.6641C18.5336 14.6039 18.5817 14.5548 18.6409 14.5233C18.7001 14.4919 18.7674 14.4797 18.8338 14.4885C19.0675 14.5193 19.3015 14.5533 19.5358 14.5907C19.6411 14.6074 19.7575 14.6312 19.8206 14.718C19.8666 14.7976 19.8842 14.8906 19.8705 14.9817V14.9852Z"
        fill="#212121"
      />
      <path
        d="M20.1377 10.1778C20.0816 10.5441 19.8472 10.8806 19.5182 11.0674C19.1891 11.2543 18.7722 11.2875 18.4162 11.1553C18.4162 11.1553 18.4168 11.1562 18.4162 11.1561C18.2809 11.0877 18.2918 11.0667 18.2095 10.9632C18.0892 10.8117 18.0073 10.6327 17.9624 10.4466C17.9235 10.2849 17.9145 10.1178 17.9119 9.95176C17.9093 9.79357 17.9131 9.63109 17.9734 9.48404C18.0108 9.39199 18.0698 9.30941 18.1349 9.23301C18.4091 8.91183 18.8563 8.68997 19.2696 8.8037C19.5243 8.87377 19.7276 9.05728 19.9094 9.24421C19.9861 9.32309 20.0625 9.40611 20.1042 9.50689C20.142 9.59893 20.1483 9.70007 20.1517 9.79918C20.1562 9.92557 20.1568 10.0527 20.1377 10.1778Z"
        fill="#212121"
      />
      <defs>
        <filter
          id="filter0_d_1962_10815"
          x="0.210449"
          y="0"
          width="31.8877"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1962_10815"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1962_10815"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}
