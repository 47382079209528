import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ColdAndFluIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="36" height="37" viewBox="0 0 36 37" fill="none">
      <path
        d="M36 21.0116C34.9535 29.593 28.4753 36.5 18.4186 36.5C9.4186 36.5 0 31.0683 0 21.0116C0 2.38372 10.2456 0.5 20.3023 0.5C30.359 0.5 36 10.9549 36 21.0116Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M24.5587 27.3749C23.2185 27.3749 22.0705 27.0605 21.9882 27.038C21.786 26.9806 21.6687 26.7735 21.7261 26.5713C21.781 26.3692 21.9907 26.2519 22.1928 26.3068C22.2253 26.3167 25.4147 27.1828 27.0918 25.9674C27.7332 25.5032 28.0651 24.8019 28.1101 23.8261C28.2124 21.5525 27.1767 20.6915 26.0786 19.7806C25.1178 18.9844 24.1245 18.1584 23.9947 16.4364C23.6777 12.2885 21.1696 7.09753 21.1446 7.04512C21.0523 6.85794 21.1322 6.63083 21.3193 6.53849C21.5065 6.44615 21.7336 6.52601 21.8259 6.71319C21.9308 6.93031 24.424 12.0864 24.7509 16.379C24.8582 17.7815 25.6493 18.4379 26.5628 19.1966C27.6983 20.1399 28.9861 21.2081 28.8663 23.8585C28.8114 25.0639 28.3621 25.9798 27.5336 26.5788C26.685 27.1927 25.5695 27.3699 24.5587 27.3699V27.3749Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M11.9325 27.3748C10.9242 27.3748 9.80618 27.1976 8.95765 26.5837C8.12908 25.9847 7.67986 25.0688 7.62496 23.8634C7.50517 21.213 8.79294 20.1448 9.92847 19.2015C10.8444 18.4428 11.6355 17.7864 11.7403 16.3838C12.0673 12.0913 14.5604 6.9352 14.6653 6.71807C14.7576 6.5309 14.9847 6.45104 15.1719 6.54338C15.3591 6.63572 15.4389 6.86282 15.3466 7.05C15.3216 7.10241 12.811 12.2959 12.4965 16.4412C12.3643 18.1633 11.3735 18.9868 10.4126 19.7854C9.31453 20.6964 8.27883 21.5574 8.38115 23.8309C8.42607 24.8068 8.758 25.508 9.39939 25.9722C11.0765 27.1876 14.266 26.3216 14.2984 26.3117C14.5006 26.2567 14.7077 26.374 14.7651 26.5762C14.82 26.7783 14.7027 26.9855 14.503 27.0429C14.4207 27.0653 13.2702 27.3798 11.9325 27.3798V27.3748Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
      <path
        d="M18.2478 27.5C16.9126 27.5 16.1589 26.7837 15.4951 26.1523C14.9086 25.5958 14.4044 25.1166 13.5584 25.1166C12.6225 25.1166 12.2681 25.3437 12.1409 25.4785C12.0261 25.6008 12.0286 25.7131 12.031 25.7255C12.061 25.9327 11.9162 26.1174 11.7091 26.1473C11.502 26.1773 11.3098 26.0275 11.2798 25.8204C11.2724 25.7755 11.2274 25.3786 11.5544 24.9993C11.9212 24.575 12.5951 24.3579 13.5584 24.3579C14.7064 24.3579 15.4027 25.0193 16.0166 25.6033C16.6331 26.1897 17.2146 26.7413 18.2478 26.7413C19.281 26.7413 19.9823 26.1598 20.6811 25.5434C21.3424 24.9594 22.0287 24.3579 23.037 24.3579C25.4728 24.3579 25.5002 25.7081 25.5002 25.7655C25.5002 25.9751 25.3305 26.1448 25.1209 26.1448C24.9113 26.1448 24.7415 25.9751 24.7415 25.7655C24.7216 25.5508 24.3397 25.1166 23.037 25.1166C22.3132 25.1166 21.7892 25.5783 21.1827 26.1124C20.444 26.7637 19.6054 27.5 18.2478 27.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.748704"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
}
